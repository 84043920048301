import { useEffect } from "react";
import GlobalStyleOverrides from "./GlobalStyleOverrides";
import React from 'react';
//* import -> css
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

//* mui imports
import { ThemeProvider } from "@mui/material";

//* imports -> Components
import Login from "./View Modules/Auth/Login/Login";
import SignUp from "./View Modules/Auth/SignUp/SignUp";
import AdminPageWrapper from "./View Modules/admin/AdminPageWrapper/AdminPageWrapper";
import Dashboard from "./View Modules/admin/Dashboard/Dashboard";

import Loading from "./Modals/Loader/Loading"

//* imports -> NPM
import { useSelector } from 'react-redux';
import Restaurants from './View Modules/admin/Restaurants/Restaurants';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Components from "./View Modules/Components";
import AddRestaurants from "./View Modules/admin/Restaurants/AddRestaurants/AddRestaurants";
import Users from "./View Modules/admin/Users/Users";
import Campus from "./View Modules/admin/Configuration/Campus";
import MyAccount from "./View Modules/admin/MyAccount/MyAccount";
// import Payment from "./View Modules/admin/Payments/Payment";
// import SettelmentDetails from "./View Modules/admin/Payments/SettelmentDetails";
import MyComponent from "./View Modules/admin/Practice";
import CheckboxList from "./View Modules/admin/Practice";
import TimeInput from "./View Modules/admin/Practice";
import { QueryClient, QueryClientProvider } from "react-query";
import AddOutlet from "./View Modules/admin/Restaurants/AddOutlet/AddOutlet";
// import OutletOrderDetails from "./View Modules/admin/Payments/OutletOrderDetails";
import { Finance } from "./View Modules/admin/Finance/Finance";
import Info from "./View Modules/admin/Info/Info";
import OutletOrders from "./View Modules/admin/Restaurants/OutletOrders";

function App() {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const rememberMe = localStorage.getItem('checked')
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  // const isAuthenticated = true;

  useEffect(() => {
  }, [isAuthenticated, rememberMe]);

  let routes = createBrowserRouter([
    { path: "/", element: isAuthenticated && rememberMe ? <Navigate to="/home/dashboard" /> : <Login /> },
    { path: "/signup", element: <SignUp /> },
    {
      path: "components",
      element: <Components />,
    },
    {
      path: "/home",
      element: isAuthenticated ? <AdminPageWrapper /> : <Navigate to={"/"} />,
      children: [
        {
          path: "",
          element: <Navigate to="/home/dashboard" />,
        },
        {
          path: "dashboard",
          element: <Dashboard />
        },
        {
          path: "restaurants",
          element: <Restaurants />,
        },
        {
          path: "restaurants/addRestaurants",
          element: <AddRestaurants />
        },
        {
          path: "restaurants/editRestaurants/:outletId",
          element: <AddRestaurants />
        },
        {
          path: "users",
          element: <Users />
        },
        {
          path: "configuration",
          element: <Campus />
        },
        {
          path: "my-account",
          element: <MyAccount />
        },
        {
          path: "finance",
          element: <Finance/>
        },
        // {
        //   path: "payments",
        //   element: <Payment />
        // },
        {
          path: "practice",
          element: <TimeInput />
        },
        // {
        //   path: "payments/:outletId/:startDate/:endDate",
        //   element: <SettelmentDetails />
        // },
        // {
        //   path: "payments/:outletId/:startDate/:endDate/settlementDetails/:targetDate",
        //   element: <OutletOrderDetails />
        // },
        {
          path: "practice",
          element: <MyComponent />
        },
        {
          path: "day",
          element: <CheckboxList />
        },
        {
          path: "restaurants/add-outlet",
          element: <AddOutlet />
        },
        {
          path: "information",
          element: <Info />
        },
        {
          path: "/home/restaurants/outlet/orders/:oid",
          element: <OutletOrders />
        },
      ]
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={GlobalStyleOverrides()}>
        {/* <Router> */}
        {/* <Components/> */}
        <RouterProvider router={routes} />
        {/* </Router> */}
        <ToastContainer style={{ fontSize: "1rem" }} />
        <Loading />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
