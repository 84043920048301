import axios from "axios";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;



async function getAllDays() {
    return await axios.get(BACKEND_BASE_URL + "days/getDaysList")
}


export { 
    getAllDays 
}