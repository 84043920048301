import axios from "axios";


const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;


async function getAllCampus(pageNo=1,pageSize=10) {
    return await axios.get(BACKEND_BASE_URL + "campus/getCampusList?page=" + pageNo+ "&perPage=" +pageSize)
}

async function createCampus(campusName, cityId, address,tag,latitude, longitude) {
    return await axios.post(BACKEND_BASE_URL + "campus/createCampus", { campusName, cityId, address,tag,latitude,longitude })
}

async function getAllCities() {
    return await axios.get(BACKEND_BASE_URL + "city/getCities")
}

async function updateCampus(id,data) {
    
    return await axios.post(BACKEND_BASE_URL+"campus/updateCampus/"+id,data)
}

// async function getOutletsByCampusId(campusId){
//     return await axios.get(BACKEND_BASE_URL+"outlet/getOutletsbyCampusId/"+campusId)
// }

async function updateSequence(outletData){
    return await axios.post(BACKEND_BASE_URL+"outlet/addSequence",outletData);
}


export {
    getAllCampus,
    createCampus,
    getAllCities,
    updateCampus,
    // getOutletsByCampusId,
    updateSequence
}