import { Box } from "@mui/material";
import React from "react";
// import StackRow from "../layoutUI/StackRow";
// import LeftArrowSvg from "../svgComponents/LeftArrowSvg";
// import LargeText from "../typographyUI/LargeText";
import { useNavigate } from "react-router-dom";
// import Headline1 from "../typographyUI/Headline1";
import StackRow from "../formUI/StackRow";
import LargeText from "../formUI/LargeText";
import LeftArrowSvg from "../../svgComponents/LeftArrowSvg";
import Headline1 from "../formUI/Headline1";



const PageHeader = ({
  showBack,
  subTitle,
  title,
  rightUi,
  bottomBorder,
  mb,
  secondary,
  sx,
  ...otherProps
}) => {
  const navigate = useNavigate();
  return (
    <StackRow
      mb={mb || "20px"}
      {...otherProps}
      alignItems="center"
      sx={{
        cursor: showBack ? "pointer" : "default",
        width: "100%",
        "& .back-wrapper svg": {
          transition: "0.3s",
          position: "relative",
          left: "-0",
        },
        "&:hover .back-wrapper svg": {
          left: "-4px",
        },
        pb: "16px",
        borderBottom: bottomBorder && "1px solid var(--gray-200, #E2E8F0)",
        ...sx,
      }}
    >
      {showBack && (
        <StackRow
          onClick={() => showBack && navigate(-1)}
          className="back-wrapper"
          gap={1}
          sx={{
            alignSelf: "flex-start",
            paddingTop: "4px",
          }}
        >
          <LeftArrowSvg />
          {!title && <LargeText>Back</LargeText>}
        </StackRow>
      )}
      <Box onClick={() => showBack && navigate(-1)}>
        <Headline1
          fontWeight={secondary ? 600 : 700}
          fontSize={secondary ? 20 : 30}
        >
          {title}
        </Headline1>
        <LargeText fontSize={secondary ? 14 : 16}>{subTitle}</LargeText>
      </Box>
      <Box ml="auto">{rightUi}</Box>
    </StackRow>
  );
};

export default PageHeader;
