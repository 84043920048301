import { Badge, Box, Divider, Tab, Tabs } from "@mui/material";
import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import PendingOrders from "./PendingOrders";
import CurrentOrders from "./CurrentOrders";
import LiveOrders from "./LiveOrders";
import ReadyOrders from "./ReadyOrders";
import History from "./History";
import Canceled from "./Canceled";
import axios from "axios";
import PageHeader from "../../../subComponents/pageUI/PageHeader";
import { BackHand } from "@mui/icons-material";
import LeftArrowSvg from "../../../svgComponents/LeftArrowSvg";

const NotificationTab = ({ count, label, ...props }) => {
  return (
    <Tab
      label={
        <Badge
          badgeContent={count}
          color="secondary"
          style={{ padding: "4px 8px", marginTop: 2 }}
        >
          {label}
        </Badge>
      }
      {...props}
    />
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OutletOrders = () => {
  const { oid } = useParams();
  const [value, setValue] = React.useState(0);
  const [redyCount, setRedyCount] = React.useState(0);
  const [pendingCount, setPendingCount] = React.useState(0);
  const [currentCount, setCurrentCount] = React.useState(0);
  const [liveCount, setLiveCount] = React.useState(0);
  const [restaurantName, setResName] = React.useState("");

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    getResNameById();
  }, []);

  const getResNameById = async () => {
    try {
      const resName = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}outlet/getOutletName/${oid}`
      );
      console.log("res name ", resName);

      if (resName?.data?.success) {
        setResName(resName?.data?.data);
      }
    } catch (error) {
      console.log("Error while fetching res name");
    }
  };

  React.useEffect(() => {
    getAllOrderDetails();
  }, [oid, redyCount, pendingCount, currentCount, liveCount, handleChange]);

  const getAllOrderDetails = () => {
    getRedyOrderDetails();
    getPendingOrderDetails();
    getCurrentOrderDetails();
    getLiveOrderDetails();
  };

  const getRedyOrderDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/getReadyOrders/${oid}`
      );
      const data = await response.json();
      if (data.success === true) {
        setRedyCount(data.data.length);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const getPendingOrderDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/getPendingOrder/${oid}`
      );
      const data = await response.json();
      if (data.success === true) {
        setPendingCount(data.data.length);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const getCurrentOrderDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/getCurrentOrder/${oid}`
      );
      const data = await response.json();
      if (data.success === true) {
        setCurrentCount(data.data.length);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const getLiveOrderDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/getLiveOrders/${oid}`
      );
      const data = await response.json();
      if (data.success === true) {
        setLiveCount(data.data.length);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const tabs = [
    { label: "pending", component: PendingOrders },
    { label: "current", component: CurrentOrders },
    { label: "Live", component: LiveOrders },
    { label: "Ready", component: ReadyOrders },
    { label: "history", component: History },
    { label: "cancelled", component: Canceled },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1.5rem",
          marginTop: "1rem",
          gap: "1.5rem",
        }}
      >
        <Box
          style={{ cursor: "pointer", fontSize: "1rem", fontWeight: "bold" }}
          onClick={() => navigate("/home/restaurants")}
        >
          <LeftArrowSvg style={{ fontSize: "2rem" }} />
        </Box>

        <Box style={{ fontSize: "1.3rem" }}>
          {restaurantName?.outletName}'s Orders
        </Box>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map((tab, index) => (
            <NotificationTab
              key={tab.label}
              count={
                tab.label === "pending"
                  ? pendingCount
                  : tab.label === "current"
                  ? currentCount
                  : tab.label === "Live"
                  ? liveCount
                  : tab.label === "Ready"
                  ? redyCount
                  : 0
              }
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <CustomTabPanel key={tab.label} value={value} index={index}>
          <tab.component />
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default OutletOrders;
