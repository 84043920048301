import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export default function GlobalStyleOverrides() {
  const theme = createTheme({
    typography: {
      // fontSize : 16,
      fontFamily: "Inter",
    },
    palette: {
      text: {
        primary: "#0F172A",
      },
      primary: {
        main: "#4F46E5",
      },
    },
    // typography: {
    //   inherit : {
    //   }
    // }
    //   fontFamily: [
    //     '"Inter"',
    //     '"Inter-SemiBold"',
    //     '"Inter-Regular"',
    //     "Roboto",
    //     '"Helvetica Neue"',
    //     "Arial",
    //     "sans-serif",
    //   ].join(","),
    //   body1: {
    //     fontFamily: "'Inter', Arial, sans-serif",
    //   },
    // },
    // palette: {
    // primary: {
    //   light: '#F9F5FF',
    //   main: '#3B82F6',
    //   dark: '#42307D',
    //   contrastText: '#fff',
    // },
    // },
  });

  return responsiveFontSizes(theme);
}
