const headerLink = [
    { id: 1, name: 'Dashboard', link: "/home/dashboard" },
    { id: 2, name: 'Restaurant', link: "/home/restaurants" },
    // { id: 3, name: 'Payment', link: "/home/payments" },
    { id: 4, name: 'User', link: "/home/users" },
    { id: 5, name: 'Configuration', link: "/home/configuration" },
    { id: 6, name: 'Finance', link: "/home/finance" },
    { id: 7, name: 'Info', link: "/home/information" },
]

export {
    headerLink
}