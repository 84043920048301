import { configureStore } from "@reduxjs/toolkit";

//* import reducer ( Slices files )
import authReducer from "../Redux Store/Slices/auth";
import loaderReducer from "../Redux Store/Slices/loaderSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    loader: loaderReducer,
  },
  devTools: true,
});
