import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, IconButton, Stack } from "@mui/material";
import UserModal from "../../View Modules/admin/Users/UserModal";

export default function CustomMenu({ EditWrapper, height, ButtonComp, children, menuList = [], menuOnClick = () => { } }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [o, setO] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const sxStyles = {
    menu: {
      "& .MuiPaper-root": {
        borderRadius: "0.75rem",
        p: "0.75rem",
        boxShadow: "0px 1px 1rem #E2E8F0",
        border: "1px solid #E2E8F0",
        width: '15.25rem'
      },
    },
    menuItem: {
      borderRadius: "0.875rem",
      mt: 1,
    },
    menuItemSpan: {
      fontSize: "0.875rem",
      fontWeight: "500",
    },
  };

  const buttonCompProps = {
    onClick: handleClick
  }

  return (
    <Box sx={{}}>
      {ButtonComp ? (
        <div style={{ cursor: 'pointer' }} >
          {ButtonComp(buttonCompProps)}
        </div>
      ) : (
        <IconButton
          {...buttonCompProps}
        >
          <MoreHorizIcon />
        </IconButton>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          ...sxStyles.menu,
          " .MuiPaper-root": {
            maxHeight: height ? height : "auto"
          }
        }}
      >
        {children}
        {menuList.map(({ icon, label, id }) =>
          label === 'Edit' ?
            <span onClick={() => setO(!o)}>
              <UserModal
                key={id}
                // open={o}
                onSubmit={data => {
                  // console.log(888)
                  // console.log(data)
                  // console.log(888)
                }}
              >
                <MenuItem key={id} onClick={() => { handleClose(); menuOnClick({ label, id }) }} sx={sxStyles.menuItem}>
                  <Stack direction="row" gap={1}>
                    {icon}
                    <span style={sxStyles.menuItemSpan}>{label}</span>
                  </Stack>
                </MenuItem>
              </UserModal>
            </span>
            :
            <MenuItem key={id} onClick={() => { handleClose(); menuOnClick({ label, id }) }} sx={sxStyles.menuItem}>
              <Stack direction="row" gap={1}>
                {icon}
                <span style={sxStyles.menuItemSpan}>{label}</span>
              </Stack>
            </MenuItem>)}
      </Menu>
    </Box>
  );
}
