import React from 'react'

const NewBatchSvg = ({color="#F0FDF4"}) => {
  return (
    <svg style = {{ position : 'relative', top :'4px'}}  width="42" height="23" viewBox="0 0 42 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="42" height="22" rx="11" fill={color}/>
    <path d="M16.0028 6.77273V15.5H14.7926L10.3565 9.09943H10.2756V15.5H8.95881V6.77273H10.1776L14.6179 13.1818H14.6989V6.77273H16.0028ZM20.5224 15.6321C19.8775 15.6321 19.3221 15.4943 18.8562 15.2188C18.3931 14.9403 18.0352 14.5497 17.7823 14.0469C17.5323 13.5412 17.4073 12.9489 17.4073 12.2699C17.4073 11.5994 17.5323 11.0085 17.7823 10.4972C18.0352 9.9858 18.3875 9.58665 18.8392 9.29972C19.2937 9.01278 19.825 8.86932 20.4329 8.86932C20.8022 8.86932 21.1602 8.9304 21.5068 9.05256C21.8534 9.17472 22.1644 9.36648 22.44 9.62784C22.7156 9.8892 22.9329 10.2287 23.092 10.6463C23.2511 11.0611 23.3306 11.5653 23.3306 12.1591V12.6108H18.1275V11.6562H22.0821C22.0821 11.321 22.0139 11.0241 21.8775 10.7656C21.7412 10.5043 21.5494 10.2983 21.3022 10.1477C21.0579 9.99716 20.771 9.92188 20.4414 9.92188C20.0835 9.92188 19.771 10.0099 19.5039 10.1861C19.2397 10.3594 19.0352 10.5866 18.8903 10.8679C18.7483 11.1463 18.6772 11.4489 18.6772 11.7756V12.5213C18.6772 12.9588 18.7539 13.331 18.9073 13.6378C19.0636 13.9446 19.2809 14.179 19.5593 14.3409C19.8377 14.5 20.163 14.5795 20.5352 14.5795C20.7767 14.5795 20.9968 14.5455 21.1957 14.4773C21.3946 14.4062 21.5664 14.3011 21.7113 14.1619C21.8562 14.0227 21.967 13.8509 22.0437 13.6463L23.2497 13.8636C23.1531 14.2187 22.9798 14.5298 22.7298 14.7969C22.4826 15.0611 22.1715 15.267 21.7965 15.4148C21.4244 15.5597 20.9997 15.6321 20.5224 15.6321ZM26.0317 15.5L24.1055 8.95455H25.4223L26.705 13.7614H26.7689L28.0558 8.95455H29.3726L30.651 13.7401H30.7149L31.989 8.95455H33.3058L31.3839 15.5H30.0842L28.7547 10.7741H28.6567L27.3271 15.5H26.0317Z" fill="#15803D"/>
    </svg>
    
  )
}

export default NewBatchSvg