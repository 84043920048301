import axios from "axios";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

async function mobileOTP(postBody) {
  return await axios.post(BACKEND_BASE_URL + 'customer/sendMobileOTP', postBody);
}
async function emailOTP(type, postBody) {
  return await axios.post(BACKEND_BASE_URL + `message/sendOTP/${type}`, postBody);
}
async function verifyMobileOtp(postBody) {
  return await axios.post(BACKEND_BASE_URL + "customer/verifyMobileOTP", postBody);
}
async function verifyEmailOtp(postBody) {
  return await axios.post(BACKEND_BASE_URL + "message/verifyOTP", postBody);
}
async function getOutletInfo(id) {
  return await axios.get(BACKEND_BASE_URL + `outlet/getOutletData/${id}`);
}

export { mobileOTP, emailOTP, verifyMobileOtp, verifyEmailOtp,getOutletInfo };
