import React from 'react';
import { Tooltip, Card, CardContent, CardActions, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const DashboardCards = ({ title, value, tooltip, cssClasses }) => (
  <Card className={cssClasses.dashboardCard} sx={boxStyle}>
    {value === 0 || value === null || value ? (
      <CardContent className={cssClasses.cardContent} >
        <Typography className={cssClasses.cardHeading} gutterBottom>
          {title}
        </Typography>
        <Tooltip title={tooltip} arrow>
          <svg xmlns="http://www.w3.org/2000/svg" style={{ paddingTop: '3px' }} width="18" height="18" viewBox="0 0 18 18" fill="none">
            <circle cx="9" cy="9" r="6.75" stroke="#64748B" strokeWidth="1.35" />
            <path d="M9.375 5.625C9.375 5.83211 9.20711 6 9 6C8.79289 6 8.625 5.83211 8.625 5.625C8.625 5.41789 8.79289 5.25 9 5.25C9.20711 5.25 9.375 5.41789 9.375 5.625Z" fill="#64748B" stroke="#64748B" strokeWidth="0.75" />
            <path d="M9 12.75V7.5" stroke="#64748B" strokeWidth="1.35" />
          </svg>
        </Tooltip>
      </CardContent>
    ) : (
      <Skeleton width={175} height={40} sx={{ margin: '13px', marginLeft: '22px', mb: 'auto' }} animation="wave" />
    )}
    <CardActions>
      {value === 0 || value === null || value ? (
        <Typography className={cssClasses.cardActions}>
          {title === "Total Refund" ? "₹"+(value || 0) : (value || 0)}
        </Typography>
      ) : (
        <Skeleton width={210} height={50} animation="wave" sx={{ margin: 'auto' }} />
      )}
    </CardActions>
  </Card>
);

export {DashboardCards};

const boxStyle = {
  flex: 'calc(20% - 15px)',
  minWidth: 'unset !important',
  display: 'flex',
  flexDirection: 'column'
}