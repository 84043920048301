import axios from "axios";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

async function getAllRestaurant(pageNo, pageSize, searchText,sortBy,cityId) {
    return await axios.get(BACKEND_BASE_URL + "outlet/getPrimaryOutletList?page=" + pageNo + "&perPage=" + pageSize + "&searchText=" + searchText + "&sortBy=" + sortBy + "&searchCity=" +cityId)
}
async function createRestaurant(data) {
    return await axios.post(BACKEND_BASE_URL + "outlet/createOutlet", data)
}
async function getCampusByCity(cityId){
    return await axios.get(BACKEND_BASE_URL + "campus/getCampus/" + cityId)
}
async function updateRestaurant(data,outletId){
    return await axios.post(BACKEND_BASE_URL + "outlet/updateOutlet/" + outletId,data)
}
async function uploadFSSAILicense(id, data) {
    const fd = convertObjectToFormData({ restaurantId: id, file: data })
    return await axios.post(BACKEND_BASE_URL + "restaurent/upsertFssaiLicensePhoto", fd, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
}
async function getBankNames(bankName){
    return await axios.get(BACKEND_BASE_URL + "common/getBankNames")
}

function convertObjectToFormData(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
        const value = data[key];
        formData.append(key, value);
    });

    return formData;
}

async function updateOutlet(outletId,data){
    return await axios.post(BACKEND_BASE_URL + "outlet/updateOutlet/" + outletId,data)
}

export {
    getAllRestaurant,
    createRestaurant,
    uploadFSSAILicense,
    getCampusByCity,
    getBankNames,
    updateRestaurant,
    updateOutlet
}