import axios from "axios";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

async function getAdminList(pageNo,pageSize,text) {
    return await axios.get(BACKEND_BASE_URL+"admin/getAdminList?page=" + pageNo+ "&perPage=" +pageSize + "&searchText=" +text)
}
async function getOutletAdminList(pageNo,pageSize,text) {
  return await axios.get(BACKEND_BASE_URL+"admin/getOutletAdminList?page=" + pageNo+ "&perPage=" +pageSize + "&searchText=" +text)
}
async function createAdmin(email,password,name,mobile,role,tabAccess,isActive) {
  return await axios.post(BACKEND_BASE_URL+"admin/createAdmin",{email,password,name,mobile,role,tabAccess,isActive})
}
async function updateAdmin(id,data) {
  return await axios.post(BACKEND_BASE_URL+"admin/updateAdmin/"+id,data)
}
async function updateAdminPassword(password,adminId) {
  return await axios.post(BACKEND_BASE_URL+"admin/updateAdminPassword",{password,adminId})
}
async function resetAdminPassword(email) {
  return await axios.post(BACKEND_BASE_URL+"admin/resetPassword",{email})
}
async function getBankLabel() {
    return await axios.get(BACKEND_BASE_URL+"admin/mealpeBankLabel/getMealpeBankLabel")
}
async function updateBankLabel(params) {
  return await axios.post(BACKEND_BASE_URL+"admin/mealpeBankLabel/addMealpeBankLabel",params)
}

export {
    getAdminList,
    getOutletAdminList,
    createAdmin,
    updateAdmin,
    updateAdminPassword,
    resetAdminPassword,
    getBankLabel,
    updateBankLabel
}