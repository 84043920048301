import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Text from "../../../subComponents/formUI/Text";
import CustomLink from "../../../subComponents/formUI/CustomLink";
import { Box, Stack, Chip } from "@mui/material";
import PageHeader from "../../../subComponents/pageUI/PageHeader";
import axios from "axios";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomSearchTextField from "./CustomSearchTextField";

let chipSxObj = {
  paid: {
    color: "#15803D",
    iconColor: "#22C55E",
    bgColor: "#F0FDF4",
  },
  pending: {
    color: "#B45309",
    iconColor: "#F59E0B",
    bgColor: "#FFFBEB",
  },
  failed: {
    color: "#B91C1C",
    iconColor: "#F59E0B",
    bgColor: "#FEF2F2",
  },
  gray: {
    color: "#334155",
    bgColor: "#F1F5F9",
  },
  blue: {
    color: "#1347CC",
    bgColor: "#EEF2FF",
  },
  orange: {
    color: "red",
    bgColor: "#EEF2FF",
  },
};

const LiveOrders = () => {
  const { oid } = useParams();

  const [totalData, setTotalData] = useState(0);
  const [row, setRow] = useState([]);
  const [pageForServer, setPageForServer] = useState(0);
  const [pageForDataGrid, setPageForDataGrid] = useState(0);
  const [search, setSearch] = useState();
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const tasksPerPage = 10;
  const handleChangePage = (page, details) => {
    if (page.page > pageForDataGrid) {
      if (totalData === row?.length) {
        setPageForDataGrid(pageForDataGrid + 1);
      } else {
        setPageForServer(page.page);
      }
    } else {
      setPageForDataGrid(pageForDataGrid - 1);
    }
  };

  useEffect(() => {
    if (search == "" || search == null) {
      setFilteredRows(row);
    } else {
      setFilteredRows(
        row?.filter((order) =>
          order?.order_sequence_id?.toString()?.includes(search?.toString())
        )
      );
    }
  }, [search, row]);

  const [chipColor] = useState({
    accepted: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    pending: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    pickUp: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    ord: {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    foodready: {
      backgroundColor: "#FDF5FF",
      textColor: "#7E22CE",
    },
    delivered: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    dispatch: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    dineIn: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    restaurants: {
      backgroundColor: "#F1F5F9",
      textColor: "#1347CC",
    },
  });

  useEffect(() => {
    getLiveOrders();
  }, []);

  const getLiveOrders = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/getLiveOrders/${oid}`
      );
      console.log("res ", res);

      if (res?.data?.success) {
        setRow(res?.data?.data);
      }
    } catch (error) {
      console.log("Error while fetch pending orders");
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => [
    // {
    //   field: "change_status",
    //   headerName: "",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) =>
    //     (row?.order_status_text == "Accepted" ||
    //       row?.order_status_text == "Food Ready" ||
    //       row?.order_status_text == "Dispatch") && (
    //       <CustomMenu
    //         width={"140px"}
    //         menuList={[
    //           { id: 1, label: "Food Ready" },
    //           { id: 2, label: "Dispatch" },
    //           // { id: 3, label: "Delivered" },
    //         ]}
    //         menuOnClick={({ label, id: _id }) => {
    //           if (_id == 1) {
    //             axios
    //               .post(
    //                 process.env.REACT_APP_BACKEND_BASE_URL +
    //                   "order/order/foodReadyOrder/" +
    //                   `${row.order_id}`
    //               )
    //               .then((res) => {
    //

    //                 setDep(Math.random());
    //               })
    //               .catch((error) =>
    //           } else if (_id == 2) {
    //             axios
    //               .post(
    //                 process.env.REACT_APP_BACKEND_BASE_URL +
    //                   "order/order/dispatchOrder/" +
    //                   `${row.order_id}`
    //               )
    //               .then((res) => {
    //

    //                 setDep(Math.random());
    //               })
    //               .catch((error) =>
    //           } else {
    //             axios
    //               .post(
    //                 process.env.REACT_APP_BACKEND_BASE_URL +
    //                   "order/order/deliveredOrder/" +
    //                   `${row.order_id}`
    //               )
    //               .then((res) => {
    //

    //                 setDep(Math.random());
    //               })
    //               .catch((error) =>
    //           }
    //         }}
    //       />
    //     ),
    // },
    {
      field: "order_id",
      headerName: "Order ID",
      flex: 1,
      minWidth: 90,
      maxWidth: 130,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.order_sequence_id}</Text>,
    },
    // {
    //   field: "customer",
    //   headerName: "Customer",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <ItemCard
    //       imgSx={{
    //         borderRadius: "50%",
    //       }}
    //       img={row?.photo}
    //       title={row?.customer_name}
    //       subTitle={`+ ${row?.mobile}`}
    //       center
    //       sx={{
    //         height: 36,
    //       }}
    //     />
    //   ),
    // },
    {
      field: "item",
      headerName: "Order Details",
      flex: 1,
      minWidth: 240,
      sortable: false,
      renderCell: ({ row }) => <ItemDetails menuItem={row?.menu_item} />,
    },

    // {
    //   field: "view",
    //   headerName: "Action",
    //   flex: 1,
    //   minWidth: 120,
    //   sortable: false,
    //   renderCell: ({ row }) => {
    //     return (
    //       <CustomLink
    //         onClick={() => orderDetails(row.order_id)}
    //         sx={{
    //           cursor: "pointer",
    //         }}
    //       >
    //         View Details
    //       </CustomLink>
    //     );
    //   },
    // },
    {
      field: "order_type",
      headerName: "Order Type",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomChip
          style={{ fontSize: "0.9rem" }}
          label={
            row?.is_dine_in
              ? "Dine In"
              : row?.is_pick_up
              ? "Pick Up"
              : row?.is_delivery
              ? "Delivery"
              : "Unknown"
          }
          textColor={
            chipColor[
              row?.is_dine_in
                ? "dineIn"
                : row?.is_pick_up
                ? "pickUp"
                : row?.is_delivery
                ? "foodready"
                : "Unknown"
            ]?.textColor
          }
          backgroundColor={
            chipColor[
              row?.is_dine_in
                ? "dineIn"
                : row?.is_pick_up
                ? "pickUp"
                : row?.is_delivery
                ? "foodready"
                : "Unknown"
            ]?.backgroundColor
          }
        />
      ),
    },
    {
      field: "preparation_time",
      headerName: "Preparation Time",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.preparation_time} min</Text>,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <CustomChip
    //       label={row.orderId.orderStatusId.text}
    //       textColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]?.textColor
    //       }
    //       backgroundColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]
    //           ?.backgroundColor
    //       }
    //     />
    //   ),
    // },
    {
      field: "order_value",
      headerName: "Order Value",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          {" "}
          <Text bold>₹ {(row?.total_price).toFixed(2)}</Text>
        </>
      ),
    },
    {
      field: "Delivery Time",
      headerName: "Delivery Time",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => {
        const parsedTime = moment(row?.order_schedule_time, "HH:mm:ss");
        const formattedTime = parsedTime.format("h:mm A");

        const parsedDate2 = moment(row?.order_schedule_date, "YYYY-MM-DD");
        let formattedAdjustedTime;

        if (row?.schedule_now) {
          const preparation_time = row?.preparation_time || 0;
          const adjustedTime = parsedTime.add(preparation_time + 15, "minutes");

          // Check if the adjusted time is past 12 AM
          // if (adjustedTime.isAfter(moment('12:00 AM', "h:mm A"))) {
          //   parsedDate2.add(1, 'day'); // Add 1 day to the date
          // }

          formattedAdjustedTime = adjustedTime.format("h:mm A");
        }

        const formattedDate2 = parsedDate2.format("ddd, DD MMM YYYY");

        return (
          <Stack>
            <Text bold>{formattedDate2}</Text>
            <Text bold>
              {row?.schedule_now ? formattedAdjustedTime : formattedTime}
            </Text>
          </Stack>
        );
      },
    },
  ]);
  return (
    <Box p={0} sx={{ height: "500px" }}>
      <PageHeader
        secondary
        title="Live Orders"
        subTitle={"Your live orders are here."}
      />

      <CustomSearchTextField
        style={{ marginBottom: "1rem" }}
        placeholder="Search by Order ID"
        value={search}
        onChange={(e) => setSearch(e.target.value.replace(/[^0-9]/g, ""))}
      />

      <DataGrid
        getRowId={(row) => row.order_id}
        onPaginationModelChange={handleChangePage}
        pageSizeOptions={[tasksPerPage]}
        loading={loading}
        sx={[dataGridStyles]}
        // rows={row}
        rows={filteredRows}
        columns={columns}
        disableColumnFilter={true}
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        disableSelectionOnClick={true}
        // rowHeight={72}
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default LiveOrders;

const ItemDetails = ({ menuItem }) => {
  const order = menuItem.slice(0, 4).map((item, index) => (
    <React.Fragment key={item?.item_price * index}>
      <Text
        bold
        sx={{
          overflow: "hidden",
          width: "230px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {item?.quantity} x {item?.itemname}
      </Text>
    </React.Fragment>
  ));
  return (
    <span>
      {order}{" "}
      {menuItem.length > 4 && (
        <Chip
          label={"+" + (menuItem?.length - 4) + " more"}
          sx={{ height: "28px", py: "8px" }}
        />
      )}
    </span>
  );
};

const getChipThemeSx = (themeName = "gray") => {
  const theme = chipSxObj[themeName] || {
    color: "#334155",
    bgColor: "#F1F5F9",
  };
  const { color = "black", iconColor = "#334155", bgColor = "red" } = theme;
  return {
    mr: 1,
    minWidth: "4.5rem",
    backgroundColor: bgColor,
    color: color,
    fontSize: "1.2rem",
    fontWeight: "500",
    "& .MuiChip-icon": {
      color: iconColor,
      ml: "10px",
    },
  };
};

function CustomChip({
  sx = {},
  dotted = false,
  theme = "gray",
  label = "",
  backgroundColor,
  textColor,
  ...otherProps
}) {
  return (
    <Chip
      label={label[0]?.toUpperCase() + label?.slice(1, label.length) || label}
      sx={{
        transition: "0.3s",

        ...getChipThemeSx(label?.toLowerCase() || theme),
        height: "28px",
        py: "8px",
        backgroundColor: backgroundColor,
        color: textColor,
        ...sx,
      }}
      icon={
        dotted ? <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} /> : null
      }
      {...otherProps}
    />
  );
}

const dataGridStyles = {
  // height: height || "600px",
  // border: "none",
  // ...sx,
  // "&  .MuiDataGrid-menuIcon,.MuiDataGrid-columnSeparator": {
  //     display: "none",
  //     cursor: "none",
  // },
  // "& .MuiDataGrid-columnHeaders": {
  //     borderRadius: 1,
  //     border: "1px #E2E8F0 solid",
  //     borderRadius: "9px",
  //     background: "#F8FAFC",
  //     color: "#64748B",
  //     fontSize: "14px",
  // },
  // "& .MuiDataGrid-virtualScrollerRenderZone": {
  //     "& .MuiDataGrid-row": {
  //         borderBottom: "1px solid #EAECF0",
  //         background: "#FFFFFF",
  //         borderRadius: "1rem",
  //     },
  //     "& .MuiDataGrid-row.Mui-selected:hover": {
  //         borderBottom: "1px solid #EAECF0",
  //         backgroundColor: "#FFFFFF",
  //     },
  // },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#E2E8F0",
  },
  // "&.MuiDataGrid-columnHeadersInner":
  // {
  //     backgroundColor:"blue"

  // }
  "&.MuiDataGrid-iconButtonContainer": {
    visiblity: "hidden",
  },
  "& .MuiDataGrid-menuIcon, .MuiDataGrid-iconButtonContainer": {
    display: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    // border : 'red 1px solid',
    outline: "none",
  },
  "& .MuiDataGrid-row.Mui-selected,.MuiDataGrid-row.Mui-selected:hover,.MuiDataGrid-row:hover":
    {
      backgroundColor: "transparent",
    },
  "& .MuiDataGrid-main": {
    // paddingBottom: "10rem"
  },

  "& .MuiDataGrid-footerContainer": {
    "& .MuiTablePagination-displayedRows ": {
      display: "none !important",
    },
    "& .MuiDataGrid-selectedRowCount": {
      visibility: "hidden ",
    },
    " .MuiTablePagination-selectLabel": {
      display: "none !important",
    },
    " .MuiInputBase-root": {
      display: "none !important",
    },
  },
  "&  [title='Sort']": {
    //visibility:"hidden",
    display: "none",
    cursor: "none",
  },
  "& [title='Go to previous page']": {
    border: "1px solid #EAECF0",
    borderRadius: "0.8rem",
    marginRight: "0.5rem",
  },
  "& [title='Go to next page']": {
    border: "1px solid #EAECF0",
    borderRadius: "0.8rem",
    marginRight: "0.5rem",
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "1rem",
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "1rem",
  },
  "& .MuiDataGrid-columnHeader": {
    //   padding: "0 4rem",

    "&:focus": {
      outline: "none",
    },
  },
  "&  .MuiDataGrid-row ": {
    //   maxHeight: "72px !important",
    //   minHeight: "72px !important",
  },
  "& .MuiDataGrid-cell": {
    //   padding: "0 4rem",
    //   minHeight: "72px !important",
    //   maxHeight: "72px !important",
    "&:focus": {
      outline: "none",
    },
  },
  "& .MuiButtonBase-root": {
    fontSize: "1rem",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
};
