import { Typography } from "@mui/material";
import React from "react";

const Title = ({ children, sx, ...otherProps }) => {
  return (
    <Typography
      fontSize={14}
      fontWeight={600}
      color={"#000"}
      sx={sx}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

export default Title;
