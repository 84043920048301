import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import React from "react";

const CustomTextField = ({
  label = "",
  sx = {},
  hasError,
  width = "292.67px",
  // height="40px",
  errorMassage,
  startAdornment = null,
  endAdornment = null,
  noLabel = false,
  required = false,
  height = "3rem",
  inputBaseSx,
  type,
  autoComplete ,
  ...otherProps
}) => {

  // const {register}=useForm()
  return (
    <Stack spacing={0.5}>
      {!noLabel && <Typography fontWeight={"500"} sx={{
        fontSize: '1rem'
      }} >{label}  {required && <span style={{ color: 'red' }} >*</span>} </Typography>}
      <TextField
        {...otherProps}
        sx={{
          '& .MuiInputBase-input': {
            height: 0
          },
          "& .MuiInputBase-root": {
            mb: "0",
            borderRadius: "0.625rem",
            boxShadow: "0px 1px 2px rgba(15, 23, 42, 0.06)",
            // height: "3rem",
            minHeight: height,
            // height: "2.25rem",
            color: "#94A3B8",
            borderColor: "#94A3B8",
            ...inputBaseSx
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #94A3B8",
          },
          // width:{width},
          // height:{height},
          '& .MuiFormHelperText-root': {
            position: 'absolute',
            bottom: '-22px',
            color: 'red !important'
          },
          ...sx,

        }}
        InputProps={{
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
        }}
        type={label === "Mobile Number" || label === "Owner's Mobile Number" ? "number" : ''}
        helperText={hasError && errorMassage}
        autoComplete={"off"}
      />

    </Stack>
  );
};

export default CustomTextField;
