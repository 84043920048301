import { Typography } from "@mui/material";
import React from "react";

const Lead2 = ({ children,sx= {}, ...otherProps }) => {
  return (
    <Typography
      {...otherProps}
      sx={{
        color: "#64748B",
        letterSpacing: "-0.01em",
        fontSize : '0.875rem',
        mt : '-0.1rem',
        ...sx
      }}
    >
      {children}
    </Typography>
  );
};

export default Lead2;
