import { Box, Stack } from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import CustomDialog from "../formUI/CustomDialog";
import PageHeader from "../pageUI/PageHeader";
import Text from "../formUI/Text";
import StackRow from "../formUI/StackRow";
import CustomButton from "../formUI/CustomButton";
import SmallText from "../formUI/SmallText";
import AddPng from "../../assets/Images/Add.png";
import LargeText from "../formUI/LargeText"
import axios from "axios";
import { useSelector } from "react-redux";
import "../../App.css";
import PdfIcon from "../../assets/Images/pdfIcon.png"
import ImageIcon from "../../assets/Images/imageIcon.jpg"

const NewFileUploadComponent = ({
  setFile: _setFile = () => { },
  label = "Add Label Please",
  // file1='',
  defaultImg,
  defaultfile,
  url = "",
  title = "Add title please",
  text = "Add text here",
  warningMessage = null,
  isAcceptFile,
}) => {
  // const { convertObjectToFormData } = useGetApis();

  function convertObjectToFormData(data) {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];
      formData.append(key, value);
    });

    return formData;
  }
  const restaurantData = useSelector((state) => state.auth.user);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(defaultImg || '');

  useEffect(() => {
    setImg(defaultImg)
  }, [defaultImg])

  const onDrop = useCallback((acceptedFiles) => {
    const imageFiles = acceptedFiles.filter(
      (file) => file.type.startsWith("image/") || isAcceptFile?.(file)
    );
    let file = imageFiles[0];
    if (imageFiles.length === 0) {
      toast.warning(warningMessage || "Please select image files only.");
      return;
    }

    setFile(imageFiles[0]);
    _setFile(imageFiles[0]);

    if (url) {
      axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL + url,
        convertObjectToFormData({
          file: imageFiles[0],
          outletId: restaurantData?.outletId,
        }),
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      const imageUrl = `data:${file.type};base64,${base64String}`;
      setImg(imageUrl);
    };

    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  return (
    <>
      <LargeText
        color="black"
        fontWeight={500}
        sx={{
          mb: 1,
        }}
      >
        {label}
      </LargeText>
      <Box
        sx={{
          cursor: "pointer",
          padding: "32px 16px",
          border: "1px dashed var(--gray-500, #64748B)",
          borderRadius: "8px",
          background: isDragActive ? `whitesmoke` : "transparent",
          transform: isDragActive && "scale(0.95)",
          transition: "0.4s",
          position: "relative",
        }}
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Box
            sx={{
              position: "absolute",
              transform: "scale(0.8)",
              right: 0,
              top: 10,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {img && (
              <CustomDialog
                buttonComp={(props) => (
                  <CustomButton
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onClick();
                    }}
                  >
                    View File
                  </CustomButton>
                )}
              >
                {" "}
                <PageHeader secondary title={title} />
                <Box
                  sx={{
                    width: 550,
                  }}
                >
                  {isAcceptFile && (file?.type === "application/pdf") ? (
                    <>
                      <iframe
                        src={URL?.createObjectURL(file) + "#toolbar=0"}
                        link="css-stylesheet:/App.css"
                        title="PDF Viewer"
                        width="100%"
                        height="700px"
                        onload="iframe.document.head.appendChild(ifstyle)"
                      ></iframe>
                    </>
                  ) : (
                    <>
                      {defaultfile ?
                        <iframe
                          src={img + "#toolbar=0"}
                          link="css-stylesheet:/App.css"
                          title="PDF Viewer"
                          width="100%"
                          height="700px"
                          onload="iframe.document.head.appendChild(ifstyle)"
                        ></iframe> :
                        <img
                          src={img}
                          alt=""
                          style={{
                            width: "-webkit-fill-available",
                            height: "100%",
                            display: 'flex',
                            alignItems: "center",
                            objectFit: "contain"
                          }}
                        />
                      }
                    </>
                  )}
                  {/* <PDFViewerFromFile file={file} /> */}
                </Box>
              </CustomDialog>
            )}
          </Box>
          <Stack justifyContent={"center"} gap={"4px"} alignItems={"center"}>
            <img
              src={!(img || file) ? AddPng : isAcceptFile ? PdfIcon : ImageIcon}
              alt=""
              style={{
                width: 100,
                height: 100,
                marginBottom: "14px",
              }}
            />
            <SmallText>
              {file?.name || isAcceptFile ? "Upload PNG, JPG, PDF max size 5MB each." : "Upload PNG, JPG, max size 5MB each."}
            </SmallText>
            <StackRow>
              <Text color="#0F172A" bold>
                {text}, or
              </Text>
              <Text
                color="#4F46E5"
                bold
                sx={{
                  cursor: "pointer",
                }}
              >
                click to browse
              </Text>
            </StackRow>
          </Stack>
        </div>
      </Box>
    </>
  );
};

export default NewFileUploadComponent;
