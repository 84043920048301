import React from 'react';
import cssClasses from "./addOutlet.module.css";
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import Verification from './Verification';
import { useState } from 'react';

function AddOutlet() {

    const [outletData, setOutletData] = useState(JSON.parse(JSON.stringify(outletEmptyObject)));

    return (
        <div className={cssClasses.pageWrapper}>
            <div className={cssClasses.stepperWrapper}>
                <Box sx={{ width: '100%', maxWidth: "50rem" }}>
                    <Stepper activeStep={1} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </div>

            <div className={cssClasses.mainWrapper}>
                <div className={cssClasses.mainHeaderWrapper}>
                    <p>Restaurant Details</p>
                </div>
                <div className={cssClasses.contentWrapper}>
                    <Verification outletData={outletData}/>
                </div>
            </div>
        </div>
    )
}

export default AddOutlet;

const steps = [
    'Verification',
    'Restaurant Info',
    'Owner Info',
    'Establishment Details',
    'More Information'
];

const outletEmptyObject = {
    outletName: null,
    email: null,
    password: null,
    mobile: null,
    GSTIN: null,
    campusId: null,
    cityId: null,
    isPrimaryOutlet: true,
    address: null,
    isActive: true,
    openTime: null,
    closeTime: null,
    isDineIn: true,
    isPickUp: false,
    isDelivery: false,
    isBoth: false,
    isVeg: true,
    isNonVeg: false,
    isBothFood: false,
    FSSAI_number: null,
    convenienceFee: null,
    commissionFee: null,
    bankLabel: null,
    outletAdminId: {
        name: null,
        mobile: null,
        email: null,
        address: null,
        pancard: null
    },
    Restaurant_category: [],
    bankDetailsId: {
        accountNumber: null,
        IFSCCode: null,
        bankId: null
    },
    Timing: []
}