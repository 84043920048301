import { Typography } from "@mui/material";
import React from "react";

const Text = ({ pointer, children, bold, sx, ...otherProps }) => {
  return (
    <Typography
      fontSize={14}
      color={bold ? "#0F172A" : "#64748B"}
      sx={{ cursor: pointer ? "pointer" : "default", ...sx }}
      fontWeight={bold ? 500 : 400}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

export default Text;
