// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/pie
import { ResponsivePie } from '@nivo/pie'
import { getAdminOrderType } from '../../services/dashboard';
import React, { useEffect, useState } from "react";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.


const PieChart2 = (props) => {
    const [adminOrderType, setAdminOrderType] = useState(null)
    let data = [
        {
            "id": "Dine In",
            "label": "Dine In",
            "value": `${adminOrderType?.dineincount}`,
            "color": "hsl(304, 70%, 50%)"
        },
        {
            "id": "Pick up",
            "label": "Pick up",
            "value": `${adminOrderType?.pickupcount}`,
            "color": "hsl(329, 70%, 50%)"
        },
        {
            "id": "Delivery",
            "label": "Delivery",
            "value": `${adminOrderType?.deliverycount}`,
            "color": "hsl(84, 70%, 50%)"
        },
    ]

    useEffect(() => {
        if (props) {
            getAdminOrderType(props.payload).then((res) => {
                if (res.data.success) {
                    setAdminOrderType(res?.data?.data)
                }
            }).catch((err) => console.log(err))
        }
    }, [props?.payload])

    return (
        <>
            <ResponsivePie
                data={data}
                margin={{ top: 0, right: 100, bottom: 0, left: 10 }}
                innerRadius={0.5}
                padAngle={0}
                // colors={{ scheme: 'set1' }}
                colors={["#0BA5EC", '#0086C9', '#F4EBFF', '#F0F9FF']}
                colorBy="index"
                cornerRadius={0}
                activeOuterRadiusOffset={8}
                borderWidth={0}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                arcLinkLabelsSkipAngle={1}
                arcLinkLabelsTextColor="#0000"
                arcLinkLabelsThickness={1}
                arcLinkLabelsColor={{ from: '.................' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: '............',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}

                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: 135,
                        translateY: -50,
                        itemsSpacing: 0,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
        </>)

}
export default PieChart2;