import React from 'react'
import classes from "./MyAccount.module.css";
import Box from '@mui/material/Box';
import CustomTextField from "../../../subComponents/formUI/CustomTextField";
import CustomButton from "../../../subComponents/formUI/CustomButton";
import { Divider } from "@mui/material";
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { getBankLabel, updateBankLabel } from "../../../services/user";
import { useEffect } from 'react';
import {  useSelector } from "react-redux";
import { dismissLoader, presentLoader } from "../../../services/loaderService";
import { successToast } from "../../../services/toast";

export const BankLabelSetting = () => {
  const [bankLabel, setBankLabel] = useState('')
  const [edit, setEdit] = useState(false)
  const userdata = useSelector(state => state.auth.user)

  useEffect(()=>{
    getBankLabel().then((res)=>{
      setBankLabel(res?.data?.data?.bankLabel)
    }).catch((err)=>console.log(err))
  },[])
  const handleEditClick = () => {
    setEdit(!edit)
  }

  const handleBankLabelCancel = () => {
    setEdit(!edit)
  }
  const handleBankLabelSubmit =(data)=>{
    presentLoader()
   const params = {
      "adminId": userdata[0]?.adminId || userdata?.adminId,
      "bankLabel": data
    } 
    updateBankLabel(params).then((res)=>{
      dismissLoader()
      setEdit(!edit)
      successToast("Bank Label Updated Successfully...")
      setBankLabel(res?.data?.data?.bankLabel)
    }).catch((err)=>console.log(err))
  }
  return (
    <div>
      <Box
            sx={{
              width: 811,
              height: 245,
              background: "var(--generic-white, #FFF)",
              border: "1px solid #E2E8F0",
              borderRadius: "1rem",
              boxShadow: "  0px 1px 8px 0px #E2E8F0"
            }}
          >
            <div className={classes.myAccountMain}>
              <div className={classes.restaurantsHeaderMain}>
                <div className={classes.restaurantsHeaderLeft}>
                  <div className={classes.helloWord}>Bank Label</div>
                  <div className={classes.welcomeWord}>Confirm your bankLabel before setting a new one.</div>
                </div>
                <div className={classes.restaurantsHeaderRight}>

                {!edit ?
                    <CustomButton
                      type='submit'
                      onClick={() => handleEditClick()}
                      sx={{ fontSize: { xs: '0.8rem', sm: '1rem', }, backgroundColor: "white", border: "1px solid #CBD5E1" }}
                      textcontent={<EditIcon />}
                    />
                    :
                    <div>
                      <CustomButton
                        type='submit'
                        onClick={() => handleBankLabelCancel()}
                        sx={{ fontSize: { xs: '0.8rem', sm: '1rem', marginRight: '0.6rem' }, backgroundColor: "white", border: "1px solid #CBD5E1" }}
                        textcontent={"Cancel"}
                      />
                      <CustomButton
                        onClick={() => handleBankLabelSubmit(bankLabel)}
                        variant="contained"
                        sx={{ fontSize: { xs: '0.8rem', sm: '0.8rem', }, borderRadius: "0.7rem" }}
                        textcontent="Save Changes"
                      />
                    </div>

                  }

                </div>

              </div>
              <Divider />
              <div className={classes.formPasswordFields}>
                <CustomTextField
                  required
                  label="Bank Label"
                  name="bankLabel"
                  value={bankLabel}
                  onChange={(e)=> setBankLabel(e.target.value)}
                  placeholder="Enter your Bank Label"
                  disabled={!edit}
                />
              </div>
            </div>
          </Box>
    </div>
  )
}
