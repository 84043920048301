// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/line
import { ResponsiveLine } from "@nivo/line";
import { linearGradientDef } from "@nivo/core";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
let data = [
  {
    id: "japan",
    color: "rgba(99, 102, 241, 1)",
    data: [
      {
        x: "kebab",
        y: 20,
      },
      {
        x: "fries",
        y: 40,
      },
      {
        x: "kebab",
        y: 15,
      },
      {
        x: "kebab",
        y: 30,
      },
      {
        x: "fries",
        y: 35,
      },
      {
        x: 'donute',
        y: 45,
      },
      {
        x: "kebab",
        y: 20,
      },
      {
        x: "kebab",
        y: 25,
      },
      {
        x: "donute",
        y: 50,
      },
      {
        x: "kebab",
        y: 20,
      },
      {
        x: "kebab",
        y: 30,
      },
      {
        x: "fries",
        y: 40,
      },
    ].map((item, index) => {
      const months = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];
      return {
        ...item,
        key: index,
        x: months[index],
      };
    }),
  },
];

const LineChart = (
  {
    data: _data = null
  }
) => {

  return <>
    <ResponsiveLine
      //   layers={[
      //     'grid',
      //     'markers',
      //     'axes',
      //     'areas',
      //     'crosshair',
      //     'lines',
      //     'slices',
      //     'dots',
      //     'legends',
      //   ]}
      // gridYValues={[10, 20, 30, 40, 50]} // Specify the y-values where you want the dotted line
      //   enableGridY={true}
      gridYLineStyle={{
        stroke: "rgba(0, 0, 0, 0.3)",
        strokeDasharray: "10 4", // Specify the pattern for the dotted line
      }}
      colors={["#6366F1"]}
      colorBy="index"
      data={[
        {
          id: "japan",
          color: "rgba(99, 102, 241, 1)",
          data: _data || data,
        },
      ]}
      // margin={{ top: 30, right: 40, bottom: 60, left: 10 }}
      margin={{ top: 20, right: 40, bottom: 40, left: 70 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "0",
        // max: "50",
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="basis"
      axisTop={null}
      axisLeft={{
        orient: "right",
        tickSize: 0,
        tickPadding: 10,
        // tickValues: [0, 10, 20, 30, 40, 50],
        format: (value) => `${value / 1000}k`,
        tickRotation: 0,
        legend: "",
        legendOffset: -3,
      }}

      axisBottom={{
        tickSize: 0,
        tickPadding: 8,
        tickRotation: 0,
        legend: "",
        legendOffset: 38,
        legendPosition: "middle",
      }}
      axisRight={null}
      enableGridX={false}
      // colors={{ scheme: 'paired' }}
      lineWidth={3}
      enablePoints={false}
      pointSize={5}
      pointColor={{ from: "color", modifiers: [] }}
      pointBorderColor={{ from: "serieColor", modifiers: [] }}
      pointLabelYOffset={-12}
      enableArea={true}
      useMesh={true}
      legends={[]}
      defs={[
        // using helpers
        // will inherit colors from current element
        linearGradientDef("gradientA", [
          { offset: 0, color: "inherit" },
          { offset: 100, color: "inherit", opacity: 0 },
        ]),
        linearGradientDef(
          "gradientB",
          [
            { offset: 0, color: "#000" },
            { offset: 100, color: "inherit" },
          ],
          // you may specify transforms for your gradients, e.g. rotations and skews,
          // following the transform attribute format.
          // For instance here we rotate 90 degrees relative to the center of the object.
          {
            gradientTransform: "rotate(90 0.5 0.5)",
          }
        ),
        // using plain object
        {
          id: "gradientC",
          type: "linearGradient",
          colors: [
            { offset: 0, color: "#6366F1" },
            { offset: 80, color: "#E0E7FF" },
            { offset: 100, color: "white" },
          ],
        },
      ]}
      // 2. defining rules to apply those gradients
      fill={[
        // match using object query
        { match: { id: "react" }, id: "gradientA" },
        // match using function
        { match: (d) => d?.id === "vue", id: "gradientB" },
        // match all, will only affect 'elm', because once a rule match,
        // others are skipped, so now it acts as a fallback
        { match: "*", id: "gradientC" },
      ]}
    />
  </>
};

export default LineChart;
