import { InputAdornment, TextField } from "@mui/material";

const CustomSearchTextField = ({ label, sx, ...otherProps }) => {
  return (
    <TextField
      autoComplete="off"
      sx={{
        maxWidth: "233px",

        "& .MuiInputBase-root": {
          fontSize: "14px",

          "&:hover": {
            borderColor: "#CBD5E1",
          },
          boxShadow: "0px 1px 2px 0px rgba(15, 23, 42, 0.06)",
          borderRadius: "5px",
          "& > input": {
            padding: "6px 9px",
            paddingLeft: 0,
            fontSize: "14px",
            "&::placeholder": {
              color: " #94A3B8",
              opacity: "1",
            },
          },
        },
        "& fieldset": {
          borderColor: "#CBD5E1",
        },
        ...sx,
      }}
      {...otherProps}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchSvg />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CustomSearchTextField;

const SearchSvg = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.18586"
        cy="6.92463"
        r="4.49983"
        stroke="#64748B"
        strokeWidth="1.12496"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.2793 10.2758L12.0917 13.0883"
        stroke="#64748B"
        strokeWidth="1.04996"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
