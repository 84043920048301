import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';
import { Stack, Typography } from '@mui/material';

export default function CustomSelect({ noLabel = false, required=false, optionList=[],label = 'Add Label', sx = {}, disabled=false,...otherProps }) {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Stack spacing={0}>
      {!noLabel && <Typography fontWeight={"500"} sx={{
        fontSize: '1rem',
        mb: '10px'
      }} >{label} {required && <span style = {{color :'red'}} >*</span> }</Typography>}
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={age}
        label="Age"
        onChange={handleChange}
        {...otherProps}
        sx={{
          mb: "1.25rem",
          borderRadius: "0.625rem",
          boxShadow: "0px 1px 2px rgba(15, 23, 42, 0.06)",
          height: "2.5rem",
          color: "#94A3B8",
          borderColor: "#94A3B8",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #94A3B8",
          },
          '& legend': {
            display: 'none'
          },
          ...sx,
        }}
        disabled={disabled}
      >
        {optionList?.map((e)=><MenuItem key={e?.bankId} value={e.value || e?.bankId}>{e.name}</MenuItem>)}
      </Select>
    </Stack>
  );
}
