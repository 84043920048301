import axios from "axios";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

var today = new Date()
const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

async function getDashboardCards(params) {

    return await axios.post(BACKEND_BASE_URL + "order/order/adminCardDashboard",params)
}

async function getAdminOrderType(params) {
    return await axios.post(BACKEND_BASE_URL + "order/order/adminOrderType",params)
}

async function getTop5Customers() {
    return await axios.get(BACKEND_BASE_URL + "order/order/topFiveCustomer")
}

async function getTop5Outlets() {
    return await axios.get(BACKEND_BASE_URL + "order/order/topFiveOutlets")
}

async function getTopMostOrders() {
    return await axios.get(BACKEND_BASE_URL + "order/order/topFiveMenuItem")
}
async function getMonthlyChurn() {
    return await axios.get(BACKEND_BASE_URL + "order/order/adminMonthlyChurn")
}
// {{base_url}}order/order/adminMonthlyChurn

export {getDashboardCards,getAdminOrderType,getTop5Customers,getTop5Outlets,getTopMostOrders,getMonthlyChurn }