import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Headline2 from "../../../subComponents/pageUI/Headline2";
import CustomButton from "../../../subComponents/formUI/CustomButton";
import EditIcon from '@mui/icons-material/Edit';
import CustomTextField from "../../../subComponents/formUI/CustomTextField";
import classes from "./MyAccount.module.css";
import { Divider } from "@mui/material";
import { usePasswordValidations } from "../../../CustomHooks/usePasswordValidations";
import { useDispatch, useSelector } from "react-redux";
import { updateAdmin, updateAdminPassword } from "../../../services/user";
import { dismissLoader, presentLoader } from "../../../services/loaderService";
import { successToast } from "../../../services/toast";
import { authLogin } from "../../../Redux Store/Slices/auth";
import { BankLabelSetting } from "./BankLabelSetting";

const MyAccount = () => {

  const userdata = useSelector(state => state.auth.user)

  const dispatch = useDispatch()

  const [value, setValue] = React.useState('1');
  // const [email, setEmail] = useState("yogesh.raut@gmail.com")
  // const [isEmailTouch, setIsEmailTouched] = useState(false)
  const [isConfirmPasswordTouch, setIsConfirmPasswordTouch] = useState(false)

  // const [mobileNo, setMobileNo] = useState("9834287512");
  // const [isMobileNoTouch, setMobileNoTouched] = useState(false)

  // const [name, setName] = useState("Yogesh Raut")
  // const [isNameTouched, setIsNameTouched] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [passwordInitialState] = useState({
    iD: "",
    password: "",
    cPassword: ""
  })

  const [newPassword, setNewPassowrd] = useState(passwordInitialState);

  const {
    password,
    cPassword
  } = newPassword

  const [
    validLength,
    hasNumber,
    upperCase,
    lowerCase,
    match,
    specialChar,
  ] = usePasswordValidations({
    firstPassword: password,
    secondPassword: cPassword,
  });


  const [edit, setEdit] = useState(false)

  const onCopy = (e) => {
    e.preventDefault();
  };

  const handleEditClick = () => {
    setEdit(!edit)
  }

  const handleMyAccountCancel = () => {
    setEdit(!edit)
  }

  const handlePasswordSave = (data) => {
    presentLoader()
    updateAdminPassword(data.cPassword, formData.adminId).then((res) => {
      if (res.data.success) {
        dismissLoader();
        // dispatch(authLogin(res.data.data))
        successToast("Password Updated Successfully...")
      }
    }).catch((err) => console.log(err))
  }

  const [initialState] = useState({
    email: userdata?.email || "",
    name: userdata?.name || "",
    mobile: userdata?.mobile || "",
  })
  const [formData, setFormData] = useState(initialState);


  useEffect(() => {
    // let tempArr = [];
    // userdata?.user.map((e) => tempArr.push({
    //     adminId: e.adminId,
    //     name: e.name,
    //     email: e.email,
    //     mobile: e.mobile
    // }))
    // tempArr && setFormData(tempArr.reduce((acc, obj) => ({ ...acc, ...obj }), {}))
    if (userdata) {
      setFormData({
        adminId: userdata?.adminId,
        name: userdata?.name,
        email: userdata?.email,
        mobile: userdata?.mobile
      })
    }
  }, [])


  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

  const {
    email,
    name,
    mobile,
  } = formData;

  const isNameValid = (name?.trim() !== "" && name?.length >= 3)
  const isMobileValid = (/^[0-9]{10}$/.test(mobile))

  function isValid() {
    return isNameValid && isMobileValid
  }

  const handleMyAccountSubmit = (data) => {
    setEdit(!edit)
    presentLoader()
    updateAdmin(formData.adminId, data).then((res) => {
      if (res.data.success) {
        dismissLoader();
        dispatch(authLogin(res.data.data))
        successToast("Details Updated Successfully...")
      }
    }).catch((err) => console.log(err))
  }

  const onPasswordChange = e => setNewPassowrd({ ...newPassword, [e.target.name]: e.target.value })

  console.log((password.length && match))

  return <div>
    <div className={classes.restaurantsHeaderMain}>
      <div className={classes.restaurantsHeaderLeft}>
        <div className={classes.helloWord}>My Account</div>
        <div className={classes.welcomeWord}>View and Update your store details</div>
      </div>

    </div>
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="My Account" value="1" />
            {/* <Tab label="Details Setting" value="2" /> */}
            <Tab label="Password" value="3" />
            <Tab label="Bank Details Setting" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box
            sx={{
              width: 811,
              // height: 272,
              background: "var(--generic-white, #FFF)",
              border: "1px solid #E2E8F0",
              borderRadius: "1rem",
              boxShadow: "  0px 1px 8px 0px #E2E8F0"
            }}
          >

            <div className={classes.myAccountMain}>
              <div className={classes.accountHeadline}>
                <Headline2
                  children={"Profile Settings"}
                  sx={{
                    fontSize: "1.25rem",
                    fontWeight: "100",
                    color: 'black',

                  }}
                />

                <div>

                  {!edit ?
                    <CustomButton
                      type='submit'
                      onClick={() => handleEditClick()}
                      sx={{ fontSize: { xs: '0.8rem', sm: '1rem', }, backgroundColor: "white", border: "1px solid #CBD5E1" }}
                      textcontent={<EditIcon />}
                    />
                    :
                    <div>
                      <CustomButton
                        type='submit'
                        onClick={() => handleMyAccountCancel()}
                        sx={{ fontSize: { xs: '0.8rem', sm: '1rem', marginRight: '0.6rem' }, backgroundColor: "white", border: "1px solid #CBD5E1" }}
                        textcontent={"Cancel"}
                      />
                      <CustomButton
                        onClick={() => handleMyAccountSubmit(formData)}
                        disabled={!isValid()}
                        variant="contained"
                        sx={{ fontSize: { xs: '0.8rem', sm: '0.8rem', }, borderRadius: "0.7rem" }}
                        textcontent="Save Changes"
                      />
                    </div>

                  }

                </div>

              </div>

              <div className={classes.formFields}>

                <CustomTextField
                  value={name}
                  hasError={!isNameValid}
                  // hasError={!(name?.trim() !== "" && name?.length >= 3)}
                  errorMassage={"Minimum of 3 characters"}
                  label="Name"
                  required
                  name="name"
                  placeholder="Enter your name"
                  onChange={onChange}
                  // onChange={(e) => { setName(e.target.value) }}
                  // onBlur={(e) => setIsNameTouched(true)}
                  disabled={!edit}
                />



                <CustomTextField
                  value={email}
                  required
                  name="email"
                  label="Email"
                  placeholder="Enter your email"
                  // onBlur={(e) => setIsEmailTouched(true)}
                  onChange={onChange}
                  // onChange={(e) => setEmail(e.target.value)}
                  disabled
                />

                <CustomTextField
                  disabled={!edit}
                  value={mobile}
                  required
                  name="mobile"
                  hasError={!isMobileValid}

                  // hasError={!( mobile.match('[0-9]{10}') && mobile?.length === 10)}
                  errorMassage={"Please enter a valid mobile number"}
                  label="Mobile Number"
                  placeholder="Enter your mobile number"
                  // onBlur={(e) => setMobileNoTouched(true)}
                  onChange={onChange}
                // onChange={(e) => setMobileNo(e.target.value)}
                />
              </div>
            </div>

          </Box>

        </TabPanel>
        {/* <TabPanel value="2">
                    <Box
                        sx={{
                            width: 990,
                            height: 456,
                            background: "var(--generic-white, #FFF)",
                            border: "1px solid #E2E8F0",
                            borderRadius: "1rem",
                            boxShadow: "  0px 1px 8px 0px #E2E8F0"
                        }}
                    >
                        <div className={classes.myAccountMain}>
                            <div className={classes.restaurantHeadlineDetail}>
                                <Headline2 children={"Restaurant Details Settings"} sx={{
                                    fontSize: "1.25rem",
                                    fontWeight: "100",

                                    color: 'black',

                                }} />
                                <CustomButton onClick={() => handleRestaurantDetailsSave()} variant="contained" sx={{ fontSize: { xs: '0.8rem', sm: '0.8rem', }, marginBottom: "10px", borderRadius: "0.7rem" }} textcontent="Save Changes" />

                            </div>
                            <Divider />
                            <CustomButton sx={{ fontSize: { xs: '0.8rem', sm: '1rem' }, marginTop: "0.8rem" }} variant="contained" textcontent="Add Details" startIcon={<AddIcon />} />

                            <div className={classes.restaurantMain}>
                                <Box
                                    sx={{
                                        width: 445,
                                        height: 268,
                                        background: "var(--generic-white, #FFF)",
                                        border: "1px solid #E2E8F0",
                                        borderRadius: "1rem",
                                        boxShadow: "  0px 1px 8px 0px #E2E8F0"
                                    }}
                                >


                                    <div className={classes.restaurantDetailsMain}>
                                        <div className={classes.restaurantSwitch}> <CustomSwitch
                                            checked={true}
                                            // sx={{ marginTop: "0.8rem", }}
                                            onChange={(e) => {
                                                const _value = e?.target?.checked;
                                                console.log("CustomSwitch => ", _value);
                                            }}
                                        /></div>
                                        <div className={classes.restaurantSwitchTitleMain}>
                                            <div className={classes.restaurantTitle}>           <p style={{ border: "1px solid #E2E8F0", borderRadius: "0.8rem", width: "16rem", padding: "9px" }}>Establishment Details</p></div>
                                            <div className={classes.restaurantSwitchTitle}>
                                                <div className={classes.random}>

                                                    <div className={classes.switchRestaurant}> <CustomSwitch
                                                        checked={true}
                                                        sx={{ marginTop: "0.6rem", }}
                                                        onChange={(e) => {
                                                            const _value = e?.target?.checked;
                                                            console.log("CustomSwitch => ", _value);
                                                        }}
                                                    />
                                                        <p style={{ border: "1px solid #E2E8F0", borderRadius: "0.8rem", width: "13.25rem", padding: "6px" }}>Dine In</p></div>

                                                    <div className={classes.switchRestaurant}><CustomSwitch
                                                        checked={true}
                                                        sx={{ marginTop: "0.6rem", }}
                                                        onChange={(e) => {
                                                            const _value = e?.target?.checked;
                                                            console.log("CustomSwitch => ", _value);
                                                        }}
                                                    />
                                                        <p style={{ border: "1px solid #E2E8F0", borderRadius: "0.8rem", width: "13.25rem", padding: "6px" }}>Pick Up</p>
                                                    </div>
                                                    <div className={classes.switchRestaurant}>  <CustomSwitch
                                                        checked={true}
                                                        sx={{ marginTop: "0.6rem", }}
                                                        onChange={(e) => {
                                                            const _value = e?.target?.checked;
                                                            console.log("CustomSwitch => ", _value);
                                                        }}
                                                    />
                                                        <p style={{ border: "1px solid #E2E8F0", borderRadius: "0.8rem", width: "13.25rem", padding: "6px" }}>Both</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.restaurantAddIcon}>  <CustomButton sx={{ fontSize: { xs: '0.8rem', sm: '0.8rem' }, width: "0.8rem" }} textcontent={<AddCircleOutlineIcon />} variant="contained" /></div>
                                    </div>
                                </Box>
                                <Box
                                    sx={{
                                        width: 445,
                                        height: 268,
                                        background: "var(--generic-white, #FFF)",
                                        border: "1px solid #E2E8F0",
                                        borderRadius: "1rem",
                                        boxShadow: "  0px 1px 8px 0px #E2E8F0"
                                    }}
                                >


                                    <div className={classes.restaurantDetailsMain}>
                                        <div className={classes.restaurantSwitch}> <CustomSwitch
                                            checked={true}
                                            // sx={{ marginTop: "0.8rem", }}
                                            onChange={(e) => {
                                                const _value = e?.target?.checked;
                                                console.log("CustomSwitch => ", _value);
                                            }}
                                        /></div>
                                        <div className={classes.restaurantSwitchTitleMain}>
                                            <div className={classes.restaurantTitle}>           <p style={{ border: "1px solid #E2E8F0", borderRadius: "0.8rem", width: "16rem", padding: "9px" }}>Type Of Restaurant</p></div>
                                            <div className={classes.restaurantSwitchTitle}>
                                                <div className={classes.random}>

                                                    <div className={classes.switchRestaurant}> <CustomSwitch
                                                        checked={true}
                                                        sx={{ marginTop: "0.6rem", }}
                                                        onChange={(e) => {
                                                            const _value = e?.target?.checked;
                                                            console.log("CustomSwitch => ", _value);
                                                        }}
                                                    />
                                                        <p style={{ border: "1px solid #E2E8F0", borderRadius: "0.8rem", width: "13.25rem", padding: "6px" }}>Veg</p></div>

                                                    <div className={classes.switchRestaurant}><CustomSwitch
                                                        checked={true}
                                                        sx={{ marginTop: "0.6rem", }}
                                                        onChange={(e) => {
                                                            const _value = e?.target?.checked;
                                                            console.log("CustomSwitch => ", _value);
                                                        }}
                                                    />
                                                        <p style={{ border: "1px solid #E2E8F0", borderRadius: "0.8rem", width: "13.25rem", padding: "6px" }}>Non Veg</p>
                                                    </div>
                                                    <div className={classes.switchRestaurant}>  <CustomSwitch
                                                        checked={true}
                                                        sx={{ marginTop: "0.6rem", }}
                                                        onChange={(e) => {
                                                            const _value = e?.target?.checked;
                                                            console.log("CustomSwitch => ", _value);
                                                        }}
                                                    />
                                                        <p style={{ border: "1px solid #E2E8F0", borderRadius: "0.8rem", width: "13.25rem", padding: "6px" }}>Both</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.restaurantAddIcon}>  <CustomButton sx={{ fontSize: { xs: '0.8rem', sm: '0.8rem' }, width: "0.8rem" }} textcontent={<AddCircleOutlineIcon />} variant="contained" /></div>
                                    </div>
                                </Box>
                            </div>

                        </div>
                    </Box>

                </TabPanel> */}
        <TabPanel value="3">
          <Box
            sx={{
              width: 811,
              height: 350,
              background: "var(--generic-white, #FFF)",
              border: "1px solid #E2E8F0",
              borderRadius: "1rem",
              boxShadow: "  0px 1px 8px 0px #E2E8F0"
            }}
          >
            <div className={classes.myAccountMain}>
              <div className={classes.restaurantsHeaderMain}>
                <div className={classes.restaurantsHeaderLeft}>
                  <div className={classes.helloWord}>Password</div>
                  <div className={classes.welcomeWord}>Confirm your password before setting a new one. minimum 8 characters</div>
                </div>
                <div className={classes.restaurantsHeaderRight}>

                  <CustomButton
                    disabled={!(password.length && upperCase && match)}

                    onClick={() => handlePasswordSave(newPassword)}
                    sx={{ marginTop: 12 + "px", fontSize: { xs: '0.8rem', sm: '1rem' } }}
                    variant="contained"
                    textcontent="Save Changes"
                  />
                </div>

              </div>
              <Divider />
              <div className={classes.formPasswordFields}>
                <CustomTextField
                  required
                  label="Enter Password"
                  name="password"
                  value={password}
                  onPaste={onCopy}
                  onCopy={onCopy}
                  onChange={onPasswordChange}
                  hasError={validLength || upperCase || lowerCase || specialChar || hasNumber}
                  errorMassage={!validLength ? "Please include at least 8 character" : !upperCase ? "Please include at least 1 upper case" : !lowerCase ? "Please include at least 1 lower case" : !specialChar ? "Please include at least 1 special character" : !hasNumber ? "Please include at least 1 number" : ""}
                  placeholder="Enter your Password"
                // onBlur={(e) => setIsPasswordTouch(true)}

                />
                <CustomTextField
                  required
                  hasError={(password !== cPassword) && isConfirmPasswordTouch && cPassword.length > 0}
                  errorMassage={"Password Doesnt'match"}
                  name="cPassword"
                  value={cPassword}
                  onChange={onPasswordChange}
                  onPaste={onCopy}
                  label="Re-Type  Password"
                  placeholder="Re-Type Password"
                  onBlur={(e) => setIsConfirmPasswordTouch(true)}

                />
              </div>
            </div>
          </Box>
        </TabPanel>
        <TabPanel value="4">
          <BankLabelSetting />
        </TabPanel>
      </TabContext>
    </Box>
  </div>
}
export default MyAccount