import React, { useEffect, useState } from "react";
import classes from "./AddRestaurants.module.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { getAllCampus, getAllCities } from "../../../../services/campus";
import { getBankNames } from "../../../../services/restaurant";
import OwnerInfo from "./OwnerInfo";
import EstablishmentDetails from "./EstablishmentDetails";
import MoreInformation from "./MoreInformation";
import AddPrimaryOutlet from "./AddPrimaryOutlet";
import OutletInfo from "./OutletInfo";
import OnboardingSuccess from "./OnboardingSuccess";

const AddRestaurants = () => {
  const [campusList, setCampusList] = useState([]);
  const [data, setData] = useState('')
  const [eData, setEData] = useState({
    isDineIn: data?.isDineIn || false ,
    isPickUp: false,
    isVeg: false,
    isNonVeg: false,
  });
  // const [moreInfoData, setMoreInfoData] = useState({});

  const [cities, setCities] =  useState([])

  const [ownerInfoDatas, setOwnerInfoDatas] = useState({});

  const [contactInfo, setContactInfo] = useState({});
  const [documents, setDocuments] = useState(null);
  const [bankList, setBankList] = useState()

  const [activeStep, setActiveStep] = useState(0);
  // const [msg, setMsg] = useState("");
  const [successMessage, setSuccessMessage] = useState('')
  const steps = [
    "Verification",
    "Restaurant Info",
    "Owner Info",
    "Establishment Details",
    "More Information",
  ];

  const nextStep = (data) => {
    if (activeStep === 4) {
      setActiveStep(4);
      // setMsg("You've completed.");
    } else {
      setActiveStep((currentStep) => currentStep + 1);
    }
  };
  const prevStep = () => {
    if (activeStep === 0) {
      setActiveStep(0);
      // setMsg("You've reached the beginning of the process");
    } else setActiveStep((currentStep) => currentStep - 1);
  };

  const handleBack = () => {
    prevStep();
  };
 useEffect(()=>{
   data && setContactInfo({email : data?.email,mobile : data?.mobile})
  setEData({
    isDineIn: data?.isDineIn,
    isPickUp: data?.isPickUp,
    isVeg: data?.isVeg,
    isDelivery: data?.isDelivery,
    isNonVeg: data?.isNonVeg,
  })
 },[data])
  const [restaurantInfoData, setRestaurantInfoData] = useState({});

  const getDocuments = (data) => {
    setDocuments(data);
  }

  const handleRestaurantInfoSubmit = (data) => {
    let _data = data;
    _data.bankDetailsId = {
      accountNumber: data?.accountNumber,
      BankName: data?.bankId,
      IFSCCode: data?.IFSCCode,
      bankId: data?.bankId,
      bankDetailsId: data?.bankDetailsId
    };
    nextStep(data);
    setRestaurantInfoData(data);
  };

  const { accountNumber, BankName, IFSCCode,bankId, ...newData } = restaurantInfoData;

  const handleEstablishmentInfoSubmit = (data) => {
    nextStep(data);
  };

  useEffect(() => {
    getAllCampus()
      .then((res) => {
        let tempArr = [];
        res.data.data.map((e) =>
          tempArr.push({
            name: e.campusName,
            value: e.campusId,
          })
        );
        setCampusList(tempArr);
      })
      .catch((err) => console.log(err));
      
      getBankNames().then((res)=>{
        setBankList(res.data.data)
      }).catch((err) => console.log(err));
      
    getAllCities().then((res) => {let tempArr = [];
      res.data.data.map((e) =>
        tempArr.push({
          name: e.city,
          value: e.cityId,
        })
      );
      setCities(tempArr);}).catch((error) => console.log(error))
  }, []);

  return (
    <>
      <div style={{ margin: "2.5rem" }}>
        {" "}
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <div className={classes.addRestaurantPageWrapper}>
        <div className={classes.restaurantMainLayout} style={{
          display : activeStep === 5 ? 'none' : 'block'
        }} >
          <div className={classes.restaurantsHeaderMain}>
            <div className={classes.restaurantsHeaderLeft}>
              <div className={classes.helloWord}>Restaurant Details</div>
            </div>
            {/* {!(activeStep === 0 || activeStep === 3) && (
              <div className={classes.restaurantsHeaderRight}>
                <CustomButton
                  sx={{
                    fontSize: { xs: "0.8rem", sm: "0.9rem" },
                    backgroundColor: "white",
                    borderRadius: "0.7rem",
                    width: "112px",
                    height: "40px",
                  }}
                  textcontent="Skip"
                />
              </div>
            )} */}
          </div>
        </div>
        {/* <RestaurantInfo
                onSubmit={data => {
                    handleRestaurantInfoSubmit(data)
                }}
                onSet={setRestaurantInfoData}
                bankList={bankList}
                campusList={campusList}
                sx={{
                    display: activeStep == 0 ? 'block' : 'none',
                }}
            /> */}
        <AddPrimaryOutlet
          onSubmit={(data) => {
            nextStep();
          }}
          onSet={setContactInfo}
          sx={{
            display: activeStep === 0 ? "flex" : "none",
          }}
          setData={setData}
        />
        <OutletInfo
          onSubmit={(data) => {
            handleRestaurantInfoSubmit(data);
          }}
          previousPage={handleBack}
          onSet={setRestaurantInfoData}
          bankList={bankList}
          campusList={campusList}
          cityList={cities}
          getDocuments={getDocuments}
          sx={{
            display: activeStep === 1 ? "flex" : "none",
            overflow: "auto",
            flex: 1
          }}
          data={data}
        />
        <OwnerInfo
          onSet={setOwnerInfoDatas}
          onSubmit={() => nextStep()}
          previousPage={handleBack}
          sx={{
            display: activeStep === 2 ? "flex" : "none",
          }}
          data={data}
        />
        <EstablishmentDetails
          data={data}
          onSet={setEData}
          onSubmit={(data) => {
            handleEstablishmentInfoSubmit(data);
          }}
          previousPage={handleBack}
          sx={{
            display: activeStep === 3 ? "flex" : "none",
          }}
        />

        <MoreInformation
          data={data}
          setActiveStep={setActiveStep}
          restaurantInfoData={newData}
          ownerInfoData={ownerInfoDatas}
          eData={eData}
          contactInfo={contactInfo}
          // onSet={setMoreInfoData}
          previousPage={handleBack}
          documents={documents}
          sx={{
            display: activeStep === 4 ? "flex" : "none",
            overflow: "auto"
          }}
          setSuccessMessage={setSuccessMessage}
        />
      </div>
      <OnboardingSuccess
      successMessage={successMessage}
      activeStep={activeStep}
      restaurantInfoData={newData}

        sx={{
          display: activeStep === 5 ? "flex" : "none",
        }}
      />
    </>
  );
};

export default AddRestaurants;
