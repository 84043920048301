import React from "react";

//* imports -> MUI
import Header from "../../../components/Header/Header";

//* imports -> MUI
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

function AdminPageWrapper() {
  return (
    <Box flex={1} display={"flex"} flexDirection={"column"}>
      <Header />
      <Box 
        width={'90%'}
        maxWidth={'74rem'} 
        mx = {'auto'}
        py = {2}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        // overflow={"hidden"}
       >
      <Outlet />
      </Box>
    </Box>
  );
}

export default AdminPageWrapper;
