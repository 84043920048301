import { TextField } from '@mui/material';
import React, { useState } from 'react';


const TimeInput = () => {
  const [timeValue, setTimeValue] = useState('');

  const handleTimeChange = (event) => {
    const selectedTime = event.target.value;
    setTimeValue(selectedTime);
  };

  const handleAmPmChange = (amPm) => {
    if (timeValue) {
      const hours = Number(timeValue.split(':')[0]);
      const minutes = timeValue.split(':')[1];
      const meridiem = amPm === 'AM' ? 'am' : 'pm';
      setTimeValue(`${hours % 12}:${minutes} ${meridiem}`);
    }
  };

  return (
    <div>
      <TextField
        type="time"
        value={timeValue}
        onChange={handleTimeChange}
        step="300" // Step value of 300 for 5-minute intervals
        InputProps={{
          endAdornment: (
            <>
              <button onClick={() => handleAmPmChange('AM')}>AM</button>
              <button onClick={() => handleAmPmChange('PM')}>PM</button>
            </>
          ),
        }}
      />
    </div>
  );
};

export default TimeInput;
