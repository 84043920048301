import { Box, Stack, Typography } from "@mui/material";
import Headline2 from "../subComponents/pageUI/Headline2";
import NoRowsSvg from "./NoDataSvg";

const NoDataFound = ({ name = "add in prop 'name'" }) => {
    return (
      <Box
        sx={{
          display: "grid",
          placeContent: "center",
          height: "70vh",
        }}
      >
        <Stack spacing={0} alignItems={"center"}>
          <img src={NoRowsSvg} alt=""/>
          <Headline2>Create your first {name} now</Headline2>
          <Typography>
            Showcase your products or services to the customers by creating
            beautiful categories.
          </Typography>
        </Stack>
      </Box>
    );
  };
  
  export default NoDataFound