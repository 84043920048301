import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isAuthenticated: Boolean(JSON.parse(localStorage.getItem("user"))),
  user: JSON.parse(localStorage.getItem("user")),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authLogin: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      let newState = {
        isAuthenticated: true,
        user: action.payload,
      };
      return Object.assign({}, state, newState);
    },
    logout: (state) => {
      localStorage.removeItem("user");
      localStorage.removeItem('checked')
      return Object.assign({}, state, {
        isAuthenticated: false,
        user: null,
      });
    },
    checkAuth: (state) => {
      const _user = JSON.parse(localStorage.getItem("user"));
      if (_user && _user !== "") {
        return Object.assign({}, state, {
          isAuthenticated: true,
          user: _user,
        });
      } else {
        return Object.assign({}, state, {
          isAuthenticated: !!state.isAuthenticated,
        });
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { authLogin, logout, checkAuth } = authSlice.actions;

export default authSlice.reducer;
