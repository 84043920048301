import { LoadingButton } from "@mui/lab";
import React from "react";

const CustomButton2 = ({
  children,
  sx,
  bgColor,
  borderColor,
  textColor,
  ...otherProps
}) => {
  return (
    <LoadingButton
      variant="contained"
      sx={{
        borderColor: borderColor,
        background: bgColor,
        color: textColor,
        textTransform: "inherit",
        fontSize: "16px",
        borderRadius: "8px",
        padding: "11px 16px",
        height: "44px",
        boxShadow: "none",
        lineHeight: "1",
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </LoadingButton>
  );
};

export default CustomButton2;