import React from "react";
import { useQuery } from "react-query";
import StatusFilter from "./StatusFilter";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const RestoListFilter = ({
  onClick = () => { },
  menuList: _menuList = null,
  setCityId,
  cityId,
  setIsSort,
  height
}) => {



  const { data, isLoading } = useQuery("outlet-list", () =>
    axios(process.env.REACT_APP_BACKEND_BASE_URL + `city/getCities`)
  );
  return data ? (
    <StatusFilter
      defaultValue={``}
      width="200px"
      labelWidth={'12.3rem'}
      setIsSort={setIsSort}
      setCityId={setCityId}
      cityId={cityId}
      height={height}
      label={
        <>
          {" "}
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            Select City
          </span>{" "}
          {isLoading && <CircularProgress size={14} />}{" "}
        </>
      }
      onClick={onClick}
      menuList={
        data?.data?.data?.map((o) => (
          {
            id: o?.cityId,
            label: ` ${o?.city}`,
          }
        ))
          
      }
      menuOnClick={({ label, id }) => {
        onClick(label, id);
      }}
    />
  ) : (<div></div>);
};

export default RestoListFilter;
