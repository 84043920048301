import React, { useEffect, useState, useMemo } from "react";
import classes from "./Users.module.css";
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import { DataGrid } from "@mui/x-data-grid";
import { Divider } from "@mui/material";
import { getOutletAdminList } from "../../../services/user";
import { deepPurple } from '@mui/material/colors';
import { useQuery } from "react-query";
import axios from "axios";
import cssClasses from '../Restaurants/Restaurants.module.css'
import StatusFilter from "../Filters/StatusFilter";
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import debouce from "lodash.debounce";

export default function RestaurantUsers({ searchText }) {
  const value = 1;
  const [userSearchText, setUserSearchText] = useState("");
  const [totalData, setTotalData] = useState(0)
  const [userRowCountState, setUserRowCountState] = React.useState(
    totalData || 0,
  );
  const [superAdminRow, setSuperAdminRow] = useState([])
  const [loading, setLoading] = useState(true)
  const [userPaginationModel, setUserPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const [isSort, setIsSort] = useState('');
  const [tasksPerPage, settasksPerPage] = useState(10);

  React.useEffect(() => {
    setUserRowCountState((prevuserRowCountState) =>
      totalData !== undefined
        ? totalData
        : prevuserRowCountState,
    );
  }, [totalData, setUserRowCountState]);

  const handleRestaurantUserSearch = (e) => {
    let text = e?.target?.value;
    setUserSearchText(e?.target?.value)
    setLoading(true)
    setTimeout(() => {
      getOutletAdminList(userPaginationModel?.page + 1, tasksPerPage + 1, text)?.then((res) => {
        if (res?.data?.success) {
          setLoading(false)
          setTotalData(res?.data?.meta?.totalCount)
          setSuperAdminRow(res?.data?.data)

        }
      }).catch((err) => console.log(err))

    }, 500)
  }
  const debouncedUserResults = useMemo(() => {
    return debouce(handleRestaurantUserSearch, 800)
  }, [])

  useEffect(() => {
    return () => {
      debouncedUserResults.cancel();
    };
  }, [debouncedUserResults]);

  const userUrl = process.env.REACT_APP_BACKEND_BASE_URL + `admin/getOutletAdminList?page=${userPaginationModel?.page + 1}&perPage=${10}&sortBy=${isSort}`;
  const { refetch, isLoading } = useQuery(
    [
      userPaginationModel,
      isSort
    ],
    () => {
      if (value === 1)
        {
          return axios.get(userUrl)
        }
    },
    {
      enabled: value === 1,
      onSuccess: (res) => {
        setTotalData(res?.data?.meta?.totalCount);
        if(value === 1){
          setSuperAdminRow(res?.data?.data)
        }
                  
      },
    }
  );
  const debouncedRefetch = useMemo(() => {
    return debouce(refetch, 800);
  }, [refetch]);
  
  useEffect(() => {
    debouncedRefetch();
  }, [debouncedRefetch]);

  const superAdminColumns = [
    {
      field: "name",
      headerName: "label",
      width: 265,
      renderCell: (p) => {

        return (
          <CardHeader
            avatar={

              p.img ? <Avatar aria-label="recipe">
                <CardMedia
                  component="img"
                  height="14"
                  image={p.img}
                  alt="Restaurants"

                />
              </Avatar> :
                <Avatar aria-label="recipe" sx={{ bgcolor: deepPurple[500] }}>


                  {p?.value?.charAt(0)?.toUpperCase()}
                </Avatar>
            }

            title={<p style={{ fontSize: "14px", color: "black" }}>{p?.value}</p>}
            subheader={<p style={{ fontSize: "14px", color: "#475569" }}>{p?.row?.email}</p>}
            sx={{ padding: "0rem" }}
          />

        )
      }
    },
    {
      field: "mobile",
      headerName: "Mobile Number",
      width: 200,
      renderCell: (p) => (
        <p>{p?.value}</p>

      )
    },
    {
      field: "role",
      headerName: "Role",
      width: 120,
      renderCell: (p) => (
        <p>Owner</p>

      )
    },
    {
      field: "Restaurant name",
      headerName: "Restaurant Name",
      width: 200,
      renderCell: (p) => (
        <>
          <p>{p?.row?.outletname}</p>
        </>
      )
    },
  ]

  return (
    <div className={classes.pageLayout}>
      <div className={classes.pageWrapper}>
        <div className={classes.restaurantsHeaderMain}>
          <div className={classes.restaurantsHeaderLeft}>
            <div className={classes.helloWord}> Restaurant Users</div>
            <div className={classes.welcomeWord}>Welcome to Dashboard</div>
          </div>
        </div>
        <Divider />
        <div className={classes.restaurantsHeaderMid} >
          <div className={cssClasses.SearchIcon}>
            <TextField
              placeholder="Search"
              type="text"
              variant="outlined"
              fullWidth
              size="small"
              onChange={debouncedUserResults}
              defaultValue={userSearchText }
              autoComplete='off'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),

                endAdornment: userSearchText && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      refetch();
                      setUserSearchText(" ")
                      // getRestaurantUserList()
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )
              }}
            />
          </div>
          <div className={classes.sortButton}>
            <StatusFilter
              label="Sort by"
              setIsSort={setIsSort}
            />
          </div>
        </div>
        <div style={{ height: ' 63vh', minHeight: "400px", width: '100%', marginTop: "3px" }}>
          <DataGrid
            getRowId={superAdminRow => superAdminRow?.outletadminid}
            loading={isLoading}
            pagination
            sx={[dataGridStyles]}
            rows={superAdminRow}
            columns={superAdminColumns}
            rowCount={userRowCountState}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableSelectionOnClick={true}
            rowHeight={72}
            pageSize={10}
            paginationModel={userPaginationModel}
            page={userPaginationModel?.page}
            onPageChange={(page) => {
              return page;
            }}
            paginationMode="server"
            onPaginationModelChange={setUserPaginationModel}
            initialState={{
              pageSize: 10
            }}
          />
        </div>
      </div>
    </div>
  )
}

const dataGridStyles = {
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F8FAFC"
  },
  // "&.MuiDataGrid-columnHeadersInner":
  // {
  //     backgroundColor:"blue"

  // }
  "&.MuiDataGrid-iconButtonContainer": {
    visiblity: "hidden"
  },
  '& .MuiDataGrid-menuIcon, .MuiDataGrid-iconButtonContainer': {
    display: 'none'
  },
  '& .MuiDataGrid-cell:focus': {
    // border : 'red 1px solid',
    outline: 'none'

  },
  '& .MuiDataGrid-row.Mui-selected,.MuiDataGrid-row.Mui-selected:hover,.MuiDataGrid-row:hover': {
    backgroundColor: 'transparent'
  },
  "& .MuiDataGrid-main": {
    // paddingBottom: "10rem"
  },
  "& .MuiSvgIcon-root": {
    // display: "none"
  },

  "& .MuiDataGrid-footerContainer": {
    "& .MuiTablePagination-displayedRows ": {
      display: "none !important",
    },
    "& .MuiDataGrid-selectedRowCount": {
      visibility: "hidden ",
    },
    " .MuiTablePagination-selectLabel": {
      display: "none !important",
    },
    " .MuiInputBase-root": {
      display: "none !important",
    }
  },
  "&  [title='Sort']": {
    //visibility:"hidden",
    display: "none",
    cursor: "none",
  },
  "& [title='Go to previous page']": {
    border: "1px solid #EAECF0",
    borderRadius: "0.8rem",
    marginRight: "0.5rem",
  },
  "& [title='Go to next page']": {
    border: "1px solid #EAECF0",
    borderRadius: "0.8rem",
    marginRight: "0.5rem",
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "1rem",
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "1rem",
  },
  "& .MuiDataGrid-columnHeader": {
    //   padding: "0 4rem",

    "&:focus": {
      outline: "none",
    },
  },
  "&  .MuiDataGrid-row ": {
    //   maxHeight: "72px !important",
    //   minHeight: "72px !important",
  },
  "& .MuiDataGrid-cell": {
    //   padding: "0 4rem",
    //   minHeight: "72px !important",
    //   maxHeight: "72px !important",
    "&:focus": {
      outline: "none",
    },
  },
  "& .MuiButtonBase-root": {
    fontSize: "1rem",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
};