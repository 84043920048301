import { useState, useEffect } from "react";

export const usePasswordValidations = ({
  
firstPassword = "",
secondPassword = "",
requiredLength = 8,
maxLength= 15,
  
}) => {
const [validLength, setValidLength] = useState(null);
const [hasNumber, setHasNumber] = useState(null);
const [upperCase, setUpperCase] = useState(null);
const [lowerCase, setLowerCase] = useState(null);
const [specialChar, setSpecialChar] = useState(null);
const [match, setMatch] = useState(false);
  
useEffect(() => {
  
setValidLength((firstPassword.length >= requiredLength && firstPassword.length <= maxLength) ? true : false);
setUpperCase(firstPassword.toLowerCase() !== firstPassword);
setLowerCase(firstPassword.toUpperCase() !== firstPassword);
setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(firstPassword));
setHasNumber(/\d/.test(firstPassword));
setMatch( firstPassword === secondPassword);
  
}, [firstPassword, secondPassword, requiredLength,maxLength]);

  
return [validLength, hasNumber, upperCase, lowerCase, specialChar, match];
};