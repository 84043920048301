import React from "react";

//* import css
import cssClasses from "./SignUp.module.css";

function SignUp() {
  return (
    <div className={cssClasses.pageWrapper}>
      <h1>Sign Up Page</h1>
    </div>
  );
}

export default SignUp;
