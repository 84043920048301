import { Typography } from "@mui/material";
import React from "react";

const Lead1 = ({ children, sx = {},...otherProps }) => {
  return (
    <Typography
      {...otherProps}
      sx={{
        color: "#475569",
        letterSpacing: "-0.01em",
        fontSize : '1rem',
        mt : '-0.2rem',
        ...sx
      }}
    >
      {children}
    </Typography>
  );
};

export default Lead1;
