import { LoadingButton } from "@mui/lab";
import React from "react";

const CustomButton = ({ children, textcontent, sx = {}, ...props }) => {
  return (
    <LoadingButton
      {...props}
      sx={{
        textTransform: "none",
        height: "2.25rem",
        borderRadius: 2,
        boxShadow: "0px 1px 2px rgba(15, 23, 42, 0.06)",
        fontSize : '1rem',
        ...sx,
      }}
    >
      {textcontent || children || "Button Name"}
    </LoadingButton>
  );
};

export default CustomButton;




