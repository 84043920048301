import axios from "axios";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

async function adminLogin(data) {
 
    return await axios.post(BACKEND_BASE_URL+"admin/adminLogin", data)
}

export {
    adminLogin
}