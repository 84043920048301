import React from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const radioButtonStyle = {
  ".MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    color: "#344054",
    lineHeight: "1.25rem",
  },
  "&.MuiFormControlLabel-root": {
    // border: "1px solid",
    borderRadius: "2rem",
    paddingRight: "30px",
    height: "2rem",
    backgroundColor: "#f0f1f3",
  },
};
function CustomRadio({
  value,
  optionList,
  handleCategoryChange,
  helperText = "",
  gap,
  radioContainerStyle = {},
  sx,
}) {
  return (
    <div>
      <FormControl style={{ fontSize: "0.9375rem" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={value}
          name="radio-buttons-group"
          onChange={
            handleCategoryChange
            //   (e) => {
            //   let newValue = e.target.value;
            //   onChangeValue(newValue);
            // }
          }
          sx={[
            {
              display: "flex",
              flexDirection: "row",
              gap: gap,
              ...radioContainerStyle,
            },
          ]}
        >
          {optionList?.map((optionItem, index) => (
            <FormControlLabel
              name={"optionItem.name"}
              value={optionItem.name}
              sx={sx || [radioButtonStyle]}
              control={<Radio sx={{ "&.Mui-checked": {} }} size="medium" />}
              label={optionItem.name}
              key={`${optionItem.name}_${index}`}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <p style={{ color: "red", fontSize: "0.875rem" }}>{helperText}</p>
    </div>
  );
}

export default CustomRadio;
