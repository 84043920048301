import { Typography } from "@mui/material";
import React from "react";

const Headline1 = ({ children,sx = {}, ...otherProps }) => {
  return (
    <Typography
      {...otherProps}
      sx={{
        fontSize: "1.875rem",
        fontWeight: "700",
        letterSpacing: "-0.01em",
        mb: "0.625rem",
        ...sx
      }}
    >
      {children}
    </Typography>
  );
};

export default Headline1;
