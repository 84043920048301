import React, { useEffect, useState } from "react";
import CustomMenu from "../../../subComponents/pageUI/CustomMenu";
import StackRow from "../../../subComponents/formUI/StackRow";
import Text from "../../../subComponents/formUI/Title";
import DownSvg from "../../../svgComponents/DropDownSvg";
import { Button } from "@mui/material";

const StatusFilter = ({
  sx,
  label = "Status",
  onClick = () => { },
  menuList: _menuList = null,
  cityId,
  width,
  height,
  labelWidth,
  defaultValue = null,
  setIsSort,
  setCityId
}) => {

  const [value, setValue] = useState(null);
  const [menuList, setMenuList] = useState(
    _menuList || [
      { id: 1, label: "name" },
      { id: 2, label: "date" },
    ]
  );
  useEffect(() => {
    _menuList && setMenuList(_menuList);

  }, [_menuList]);

  useEffect(() => {
    if (value !== 'name' && value !== 'date') {
      if (cityId === '') {
        setValue(null);
      }
    }
    defaultValue && setValue(defaultValue);
  }, [defaultValue, value, cityId]);

  return (
    <CustomMenu
      width={width || "200px"}
      height={height || 'auto'}
      ButtonComp={(props) => (
        <StackRow
          between={true}
          component={Button}
          alignItems={"center"}
          sx={{
            border: "1px #CBD5E1 solid",
            padding: "5px 12px",
            borderRadius: "6px",
            width: labelWidth,
            ...sx,
          }}
          {...props}
        >
          <Text
            color="#334155"
            fontWeight={500}
            sx={{
              textTransform: "capitalize",
              whiteSpace: "nowrap",
              width: "90%",
              overflow: "hidden",
              ...{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "14px"
              },
            }}
          >
            {label} {value && `: ${value}`}
          </Text>
          <DownSvg />
        </StackRow>
      )}
      menuList={menuList}
      menuOnClick={({ label, id }) => {
        onClick(label, id);
        (label === 'name' || label === 'date') ? setIsSort(label)
          : setCityId(id)
        setValue(label);
      }}
    />
  );
};

export default StatusFilter;