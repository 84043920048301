import React from "react";

const NoRowsSvg = () => {
  return (
    <svg
      width="217"
      height="128"
      viewBox="0 0 217 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1338_64633)">
        <mask
          id="mask0_1338_64633"
          style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="217"
          height="128"
        >
          <path d="M216.5 0H0.5V128H216.5V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1338_64633)">
          <g opacity="0.999174">
            <path
              d="M183.5 43C187.366 43 190.5 46.134 190.5 50C190.5 53.866 187.366 57 183.5 57H205.5C209.366 57 212.5 60.134 212.5 64C212.5 67.866 209.366 71 205.5 71H186.5C182.634 71 179.5 74.134 179.5 78C179.5 81.866 182.634 85 186.5 85H192.5C196.366 85 199.5 88.134 199.5 92C199.5 95.866 196.366 99 192.5 99H140.5C139.985 99 139.483 98.944 139 98.839C138.517 98.944 138.015 99 137.5 99H46.5C42.634 99 39.5 95.866 39.5 92C39.5 88.134 42.634 85 46.5 85H7.5C3.634 85 0.5 81.866 0.5 78C0.5 74.134 3.634 71 7.5 71H47.5C51.366 71 54.5 67.866 54.5 64C54.5 60.134 51.366 57 47.5 57H22.5C18.634 57 15.5 53.866 15.5 50C15.5 46.134 18.634 43 22.5 43H183.5ZM209.5 85C213.366 85 216.5 88.134 216.5 92C216.5 95.866 213.366 99 209.5 99C205.634 99 202.5 95.866 202.5 92C202.5 88.134 205.634 85 209.5 85Z"
              fill="#ECECEC"
            />
          </g>
          <path
            d="M147.024 5.49419V2.5472C147.024 1.6662 147.739 0.950195 148.62 0.950195C149.501 0.950195 150.216 1.6662 150.216 2.5472V5.49419C150.216 6.3772 149.501 7.0902 148.62 7.0902C147.739 7.0902 147.024 6.3772 147.024 5.49419Z"
            fill="#D9D9D9"
          />
          <path
            d="M147.024 15.3164V12.3704C147.024 11.4894 147.739 10.7754 148.62 10.7754C149.501 10.7754 150.216 11.4894 150.216 12.3704V15.3164C150.216 16.1974 149.501 16.9114 148.62 16.9114C147.739 16.9114 147.024 16.1974 147.024 15.3164Z"
            fill="#D9D9D9"
          />
          <path
            d="M140.639 8.93096C140.639 8.04996 141.353 7.33496 142.234 7.33496H145.18C146.061 7.33496 146.775 8.04996 146.775 8.93096C146.775 9.81196 146.061 10.527 145.18 10.527H142.234C141.353 10.527 140.639 9.81196 140.639 8.93096Z"
            fill="#D9D9D9"
          />
          <path
            d="M150.461 8.93096C150.461 8.04996 151.176 7.33496 152.057 7.33496H155.004C155.885 7.33496 156.601 8.04996 156.601 8.93096C156.601 9.81196 155.885 10.527 155.004 10.527H152.057C151.176 10.527 150.461 9.81196 150.461 8.93096Z"
            fill="#D9D9D9"
          />
          <path
            d="M178.306 28.0582V25.1112C178.306 24.2302 179.021 23.5142 179.902 23.5142C180.783 23.5142 181.498 24.2302 181.498 25.1112V28.0582C181.498 28.9392 180.783 29.6542 179.902 29.6542C179.021 29.6542 178.306 28.9392 178.306 28.0582Z"
            fill="#D9D9D9"
          />
          <path
            d="M178.306 37.8794V34.9334C178.306 34.0554 179.021 33.3384 179.902 33.3384C180.783 33.3384 181.498 34.0554 181.498 34.9334V37.8794C181.498 38.7604 180.783 39.4744 179.902 39.4744C179.021 39.4744 178.306 38.7604 178.306 37.8794Z"
            fill="#D9D9D9"
          />
          <path
            d="M171.922 31.4964C171.922 30.6154 172.636 29.9004 173.517 29.9004H176.463C177.344 29.9004 178.058 30.6154 178.058 31.4964C178.058 32.3774 177.344 33.0924 176.463 33.0924H173.517C172.636 33.0924 171.922 32.3774 171.922 31.4964Z"
            fill="#D9D9D9"
          />
          <path
            d="M181.742 31.4964C181.742 30.6154 182.457 29.9004 183.338 29.9004H186.285C187.166 29.9004 187.882 30.6154 187.882 31.4964C187.882 32.3774 187.166 33.0924 186.285 33.0924H183.338C182.457 33.0924 181.742 32.3774 181.742 31.4964Z"
            fill="#D9D9D9"
          />
          <path
            d="M174.011 5.4962V2.5462C174.011 1.6672 174.726 0.950195 175.607 0.950195C176.488 0.950195 177.203 1.6672 177.203 2.5462V5.4962C177.203 6.3772 176.488 7.0922 175.607 7.0922C174.726 7.0922 174.011 6.3772 174.011 5.4962Z"
            fill="#D9D9D9"
          />
          <path
            d="M174.011 15.3154V12.3714C174.011 11.4904 174.726 10.7754 175.607 10.7754C176.488 10.7754 177.203 11.4904 177.203 12.3714V15.3154C177.203 16.1984 176.488 16.9114 175.607 16.9114C174.726 16.9114 174.011 16.1984 174.011 15.3154Z"
            fill="#D9D9D9"
          />
          <path
            d="M167.626 8.93096C167.626 8.05196 168.341 7.33496 169.222 7.33496H172.169C173.05 7.33496 173.766 8.05196 173.766 8.93096C173.766 9.81396 173.05 10.527 172.169 10.527H169.222C168.341 10.527 167.626 9.81396 167.626 8.93096Z"
            fill="#D9D9D9"
          />
          <path
            d="M177.448 8.93096C177.448 8.05196 178.163 7.33496 179.044 7.33496H181.991C182.872 7.33496 183.588 8.05196 183.588 8.93096C183.588 9.81396 182.872 10.527 181.991 10.527H179.044C178.163 10.527 177.448 9.81396 177.448 8.93096Z"
            fill="#D9D9D9"
          />
          <mask
            id="mask1_1338_64633"
            style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="0"
            y="-1"
            width="217"
            height="130"
          >
            <path
              d="M0.5 0.000976562L216.5 -0.0002773L216.501 128L0.500743 128.001L0.5 0.000976562Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_1338_64633)">
            <path
              d="M57.1193 70.6466L159.263 70.646L159.263 36.598L57.1191 36.5986L57.1193 70.6466Z"
              fill="#F7F7F7"
            />
            <path
              d="M44.3508 117.465L172.029 117.464L172.029 83.4182L44.3506 83.4189L44.3508 117.465Z"
              fill="#F7F7F7"
            />
            <path
              d="M161.391 83.4134L44.3506 83.4141L44.3507 106.824L159.262 106.823C160.436 106.823 161.391 105.872 161.391 104.695L161.391 83.4134Z"
              fill="white"
            />
            <path
              d="M40.0968 83.4189L176.287 83.4181L176.287 70.6501L40.0967 70.6509L40.0968 83.4189Z"
              fill="#F7F7F7"
            />
            <path
              d="M99.92 83.4135L116.944 83.4134L116.944 70.6474L99.9199 70.6475L99.92 83.4135Z"
              fill="#F7F7F7"
            />
            <path
              d="M148.62 36.5981L57.1182 36.5986L57.1183 60.0046L146.491 60.0041C147.667 60.0041 148.62 59.0521 148.62 57.8761L148.62 36.5981Z"
              fill="white"
            />
            <path
              d="M52.8643 36.5991L163.516 36.5984L163.516 23.8304L52.8643 23.8311L52.8643 36.5991Z"
              fill="#F7F7F7"
            />
            <path
              d="M99.92 36.5946L116.944 36.5945L116.944 23.8285L99.9199 23.8286L99.92 36.5946Z"
              fill="#F7F7F7"
            />
            <path
              d="M115.348 34.9985L101.516 34.9986L101.516 25.4256L115.348 25.4255L115.348 34.9985ZM118.539 25.4255L118.539 35.0015L159.263 35.0012L159.271 35.0012L161.922 35.0012L161.922 25.4252L118.539 25.4255ZM98.3241 35.0016L98.324 25.4256L54.462 25.4258L54.4621 35.0018L57.1111 35.0018L57.1201 35.0018L98.3241 35.0016ZM117.027 22.2335C116.999 22.2325 116.971 22.2315 116.943 22.2315L99.92 22.2316C99.892 22.2316 99.864 22.2326 99.837 22.2336L52.866 22.2339C51.983 22.2339 51.27 22.9499 51.27 23.8309L51.2701 36.5979C51.2701 37.4789 51.9831 38.1949 52.8661 38.1949L55.5241 38.1948L55.5243 69.0548L40.0963 69.0549C39.2133 69.0549 38.5003 69.7689 38.5003 70.6499L38.5004 83.4179C38.5004 84.2989 39.2134 85.0139 40.0964 85.0139L42.7564 85.0139L42.7566 117.465C42.7566 118.346 43.4696 119.062 44.3526 119.062L172.034 119.061C172.914 119.061 173.629 118.345 173.629 117.464L173.628 85.0132L176.286 85.0131C177.167 85.0131 177.882 84.2981 177.882 83.4171L177.882 70.6491C177.882 69.7681 177.167 69.0541 176.286 69.0541L160.859 69.0542L160.859 38.1942L163.518 38.1942C164.399 38.1942 165.114 37.4782 165.114 36.5972L165.114 23.8302C165.114 22.9492 164.399 22.2332 163.518 22.2332L117.027 22.2335ZM58.7161 38.1948L157.667 38.1942L157.667 69.0502L58.7163 69.0508L58.7161 38.1948ZM115.348 72.2465L101.516 72.2466L101.516 81.8206L115.348 81.8205L115.348 72.2465ZM118.539 72.2465L174.69 72.2461L174.69 81.8221L118.539 81.8225L118.539 72.2465ZM98.3243 81.8226L41.6923 81.8229L41.6923 72.2469L98.3243 72.2466L98.3243 81.8226ZM170.438 115.869L45.9485 115.87L45.9484 85.0149L170.437 85.0142L170.438 115.869Z"
              fill="#D9D9D9"
            />
            <path
              d="M98.4272 25.0334L98.4272 35.2406L53.7852 35.2409L53.7852 25.0337L98.4272 25.0334Z"
              fill="#F7F7F7"
            />
            <path
              d="M163.052 25.0329L163.052 35.2401L118.41 35.2404L118.41 25.0332L163.052 25.0329Z"
              fill="#F7F7F7"
            />
            <path
              d="M115.677 72.0341L115.678 82.2413L101.285 82.2414L101.285 72.0342L115.677 72.0341Z"
              fill="#F7F7F7"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1338_64633">
          <rect
            width="216"
            height="128"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoRowsSvg;
