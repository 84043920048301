import { Box, Divider, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Headline2 from '../../../subComponents/pageUI/Headline2'
import CustomTextField from '../../../subComponents/formUI/CustomTextField'
import CustomCheckBox from '../../../subComponents/formUI/CustomCheckBox'
import classes from "./Users.module.css";
import CustomButton from '../../../subComponents/formUI/CustomButton'
import { usePasswordValidations } from '../../../CustomHooks/usePasswordValidations'
import CloseIcon from '@mui/icons-material/Close';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	maxHeight: "80%",
	overflow: "auto",
	transform: 'translate(-50%, -50%)',
	width: 650,

	border: "none",
	borderRadius: "1rem",
	bgcolor: 'background.paper',

	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3,
};
const closeIconStyle ={
	float: "right",
	fill: "#a19d9d",
	cursor: 'pointer'
}

const UserModal = ({ open: _open, onSubmit, onDiscard, children, defaultData = null }) => {
	// States and variables
	const [userAccessList] = useState(["Dashboard", "Restaurant", "Payment", "User", "Configuration","Finance","Info"])

	const [open, setOpen] = useState(_open || false)
	const [initialState] = useState({
		id: "",
		email: "",
		name: "",
		mobile: "",
		role: "",
		tabAccess: [],
		password: '',
		cPassword: '',

	})
	const [formData, setFormData] = useState(initialState);
	useEffect(
		() => {
			defaultData && setFormData(defaultData)
		}, []
	)
	const [isNameTouched, setIsNameTouched] = useState(false);
	const [isEmailTouch, setIsEmailTouched] = useState(false);
	const [isConfirmPasswordTouch, setIsConfirmPasswordTouch] = useState(false)
	const [isMobileNoTouch, setMobileNoTouched] = useState(false)

	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })
	const {
		email,
		name,
		mobile,
		role,
		tabAccess,
		password,
		cPassword,
	} = formData


	// handlers and functions
	const onCopy = (e) => {
    e.preventDefault();
  };

	const handleClose = () => {
		setOpen(!open)
		setFormData(initialState)
	}
	const handleSubmit = () => {
		onSubmit?.(formData)
		handleClose()
		setFormData(initialState)
	}

	const handleEdit = () => {
		onSubmit?.(formData)
		handleClose()
	}



	const [
		validLength,
		hasNumber,
		upperCase,
		lowerCase,
		specialChar,
	] = usePasswordValidations({
		firstPassword: password,
		secondPassword: cPassword,
	});

	const isFormValid = () => {
		if (defaultData) {
			if(mobile.length < 10 || mobile.length > 10)
				return  name && role && tabAccess.length>0 && mobile.length === 10
			else 
				return  name && role && tabAccess.length>0 && mobile
		}
		else {
			return name.length >= 3 && (password.length > 7 && password.length <= 15) && cPassword && mobile.length === 10 && role.length > 0 && tabAccess.length > 0  && email.length > 0 && (email.trim() === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) && isEmailTouch && (password === cPassword)
		}
	}

	return (
		<>
			<div onClick={() => setOpen(!open)} >
				{children}
			</div>
			<Modal

				open={open}
				onClose={handleClose}
				aria-labelledby="child-modal-title"
				aria-describedby="child-modal-description"
			>
				<Box sx={{ ...style, width: 650 }}>
					<div>
					<CloseIcon style={closeIconStyle} onClick={handleClose}/>
						<Headline2
							children={defaultData ? "Update User" : "Add User "}
							sx={{
								fontSize: "1.25rem",
								fontWeight: "100",
								color: 'black',

							}} />
						<Headline2
							children={defaultData ? "Update User For Restaurant Management" : "Add User For Restaurant Management"}
							sx={{
								fontSize: "0.9rem",
								fontWeight: "0",
								color: '#94A3B8',
							}}
						/>
					</div>
					<Divider />
					<div style={{ marginTop: "20px", marginBottom: "20px" }}>

						<div className={classes.formTextFieldsModal}>
							<div style={{ marginBottom: "10px" }} className={classes.formTextFields}>
								<CustomTextField
									hasError={!(name?.trim() !== "" && name?.length >= 3) && isNameTouched && name.length > 0}
									name="name"
									value={name}
									onChange={onChange}
									onInput={(e) => {
										if (e?.target?.value) {
										  e.target.value = e?.target?.value.slice(0, 30);;
										}
									  }}
									errorMassage={"This value must be between 3 to 30 characters"}
									label=" Name"
									required
									placeholder="Enter your  name"
									onBlur={(e) => setIsNameTouched(true)}
								/>
							</div>
							<CustomTextField
								required
								name="email"
								disabled={defaultData && true}
								value={email}
								onChange={onChange}
								hasError={!(email.trim() !== "" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) && isEmailTouch}
								errorMassage={"Please enter a valid email"}
								label="Email"
								placeholder="Enter your email"
								onBlur={(e) => setIsEmailTouched(true)}
							/>
							<CustomTextField
								required
								name="mobile"
								value={mobile}
								onChange={onChange}
								onBlur={(e) => setMobileNoTouched(true)}
								hasError={!(/^[0-9]{10}$/.test(mobile)) && isMobileNoTouch && mobile.length > 0}


								errorMassage={"Please enter a valid mobile number"}
								label="Owner's Mobile Number"
								placeholder="Enter your mobile number"
							// onBlur={(e) => setMobileNoTouched(true)}
							// onChange={(e) => setMobileNo(e.target.value)}
							/>
							<CustomTextField
								required
								// hasError={isMobileNoHasError}
								name="role"
								value={role}
								onChange={onChange}
								errorMassage={"Please enter a role"}
								label="Role"
								placeholder="Enter your role"
							// onBlur={(e) => setR(true)}
							// onChange={(e) => setRole(e.target.value)}
							/>
						</div>
					</div>
					<Divider />
					<div>
						<div className={classes.accesstab}>
							<Headline2
								children={"Access"} sx={{
									fontSize: "1.25rem",
									fontWeight: "100",
									marginTop: "20px",
									color: 'black',

								}}
							/>
							<span style={{ color: 'red' }} >*</span>
						</div>
						<div>
							<CustomCheckBox
								checkBoxContainerStyle={{
									flexDirection: "row !important",
									marginBottom: "10px"
								}}
								value={tabAccess || []}
								onChangeValue={(_Value) => {


									// setSelectedAccess(
									//     [...selectedAccess, _Value]
									// )
									let data = [];
									if (tabAccess.includes(_Value))
										data = tabAccess.filter(v => v !== _Value)
									else
										data = [...tabAccess, _Value]
									data = [...new Set(data)]
									let e = { target: { name: 'tabAccess', value: data } }

									onChange(e)

								}}
								// optionList={[]}
								optionList={userAccessList}
							/>
						</div>
					</div>
					<Divider />
					{!defaultData && <div style={{ marginTop: "20px" }} className={classes.formTextFieldsModal}>
						<CustomTextField
							required
							label="Enter Password"
							name="password"
							value={password}
							onCopy={onCopy}
							onPaste={onCopy}
							inputProps={{
								autoComplete: 'off',
							}}
							onChange={onChange}
							hasError={validLength || upperCase || lowerCase || specialChar || hasNumber}
							errorMassage={!validLength ? "Please include at least 8 characters" : !upperCase ? "Please include at least 1 upper case" : !lowerCase ? "Please include at least 1 lower case" : !specialChar ? "Please include at least 1 special character" : !hasNumber ? "Please include at least 1 number" : ""}
							placeholder="Enter your Password"
						/>
						<CustomTextField
							required
							inputProps={{
								autoComplete: 'off',
							}}
							hasError={(password !== cPassword) && isConfirmPasswordTouch && cPassword.length > 0}
							errorMassage={"Password Doesnt'match"}
							name="cPassword"
							value={cPassword}
							onChange={onChange}
							onPaste={onCopy}
							onpaste="return false;"
							label="Re-Type Enter Password"
							placeholder="Re-Type Password"
							onBlur={(e) => setIsConfirmPasswordTouch(true)}

						/>
					</div>}
					<div>
						{/* <div className={classes.customSwitch}>
                            {defaultData &&
                                <div>
                                    <Headline2
                                        children={"User Active"} sx={{
                                            fontSize: "1.25rem",
                                            fontWeight: "100",
                                            marginTop: "20px",
                                            color: 'black',

                                        }}
                                    />
                                    <CustomSwitch
                                        onChange={(e)=> setIsChecked(e.target.checked)}
                                        checked={isChecked}
                                    />
                                </div>
                            }
                        </div> */}
						<div className={classes.cancelSaveBtn} >
							<CustomButton
								onClick={() => handleClose()}
								sx={{
									fontSize: { xs: '0.8rem', sm: '1rem', margin: '0.6rem' },
									width: "180px",
									backgroundColor: "white",
									border: "1px solid #CBD5E1"
								}}

								textcontent="Cancel"
							/>
							<CustomButton
								onClick={defaultData ? handleEdit : () => handleSubmit()}
								disabled={!isFormValid()}
								variant="contained"
								sx={{
									fontSize: { xs: '0.8rem', sm: '1rem', margin: '0.6rem' },
									width: "180px"
								}}
								textcontent={defaultData ? "Update" : "Save"}
							/>
						</div>
					</div>
				</Box>
			</Modal>
		</>

	)
}

export default UserModal