import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const toggleButtonStyle = {
  fontWeight: "400",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  background: "#FFFFFF",
  border: "1px solid #EAECF0",
  color:"#344054",
  borderRadius: "8px",
  "&.MuiToggleButton-root:first-of-type": {
    borderRadius: "8px 0px 0px 8px",
  },
  "&.MuiToggleButton-root:last-of-type": {
    borderRadius: "0px 7px 7px 0px",
  },
  "&.Mui-selected": {
    background: "#4F46E5",
    boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    color: "#FFFFFF"
  }
}
export default function CustomToggleButtons({tab=[], selectedTab, onChangeTab}) {

  const handleChange = (event, newAlignment) => {
    onChangeTab(newAlignment);
  };

  return (
    <ToggleButtonGroup
      value={selectedTab}
      exclusive
      size="large"
      onChange={handleChange}
    >
      {
        tab.map((element, index) => (
          <ToggleButton sx={[toggleButtonStyle]} key={`${ToggleButton}_${element.value ? element.value : element}_${index}`} value={element.value ? element.value : element}>
            {element.label ? element.label : element}
          </ToggleButton>
        ))
      }
    </ToggleButtonGroup>
  );
}