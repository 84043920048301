import React from 'react'

const DropDownSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 9L11.2929 14.2929C11.6834 14.6834 12.3166 14.6834 12.7071 14.2929L18 9" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  )
}

export default DropDownSvg