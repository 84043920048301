import React, { useEffect, useState } from "react";
import classes from "./AddRestaurants.module.css";
import Headline2 from "../../../../subComponents/pageUI/Headline2";
import CustomButton from "../../../../subComponents/formUI/CustomButton";
import { Checkbox, Divider, FormGroup, Typography } from "@mui/material";

const EstablishmentDetails = ({
  data,
  onSubmit,
  onSet,
  previousPage,
  sx,
}) => {
  const [establishmentDetailsInitialState] = useState({
    isDineIn:  false ,
    isPickUp: false,
    isVeg: false,
    isDelivery: false,
    isNonVeg: false,
  });

  const [establishmentData, setEstablishmentData] = useState({...establishmentDetailsInitialState});
 
  const onChange = (e) =>
  { 
    setEstablishmentData({
      ...establishmentData,
      [e.target.name]: e.target.checked,
    });
  }

  useEffect(()=>{
  },[establishmentDetailsInitialState])
  useEffect(()=>{
    setEstablishmentData({
      isDineIn: data?.isDineIn ,
      isPickUp: data?.isPickUp ,
      isDelivery: data?.isDelivery,
      isVeg: data?.isVeg,
      isNonVeg: data?.isNonVeg ,
    })
  },[data])

  useEffect(()=>{
  },[establishmentData])
  const handleSubmit = () => {
    onSet(establishmentData);
    onSubmit?.(establishmentData);
  };

  // const { isDineIn, isPickUp, isVeg, isNonVeg } = establishmentData;
  const handleBack = () => {
    previousPage();
  };

  return (
    <div className={classes.formFieldWrapper} style={sx}>
      <div className={classes.formFieldLayout}>
        <div className={classes.establishmentDetails}>
          <div className={classes.establishmentDetailsBox}>
            <div className={classes.establishmentDetailsHeaderMain}>
              <div className={classes.establishmentDetailsHeader}>
                <Headline2
                  children={"Establishment Details"}
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "0",
                    color: "#94A3B8",
                    paddingBottom: "8px",
                  }}
                />
                {/* <CustomSwitch
                  checked={true}
                  onChange={(e) => {
                    const _value = e?.target?.checked;
                    console.log("CustomSwitch => ", _value);
                  }}
                /> */}
              </div>
              <Divider />
              <div className={classes.restaurantType}>
                <FormGroup>
                  <div className={classes.checkboxConatiner}>
                    <Checkbox onChange={onChange} name="isDineIn" checked={establishmentData?.isDineIn} />
                    <Typography style={{ lineHeight: '2.5' }}>Dine in {data?.isDineIn} </Typography>
                  </div>

                  <div className={classes.checkboxConatiner}>
                    <Checkbox onChange={onChange} name="isPickUp" checked={establishmentData?.isPickUp}  />
                    <Typography style={{ lineHeight: '2.5' }}>Pick up</Typography>
                  </div>

                  <div className={classes.checkboxConatiner}>
                    <Checkbox onChange={onChange} name="isDelivery" checked={establishmentData?.isDelivery} />
                    <Typography style={{ lineHeight: '2.5' }}>Delivery</Typography>
                  </div>
                  {/* <FormControlLabel control={<Checkbox onChange={onChange} name="isPickUp" />} label="Both" /> */}
                </FormGroup>
              </div>
            </div>
          </div>
          <div className={classes.establishmentDetailsBox}>
            <div className={classes.establishmentDetailsHeaderMain}>
              <div className={classes.establishmentDetailsHeader}>
                <Headline2
                  children={"Type Of Restaurant"}
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "0",
                    color: "#94A3B8",
                    paddingBottom: "8px",
                  }}
                />

                {/* <CustomSwitch
                  checked={true}
                  onChange={(e) => {
                    const _value = e?.target?.checked;
                    console.log("CustomSwitch => ", _value);
                  }}
                /> */}
              </div>
              <Divider />

              <div className={classes.restaurantType}>
                <FormGroup>
                  <div className={classes.checkboxConatiner}>
                    <Checkbox onChange={onChange} name="isVeg"  checked={establishmentData?.isVeg}/>
                    <Typography style={{ lineHeight: '2.5' }}>Veg</Typography>
                  </div>
                  <div className={classes.checkboxConatiner}>
                    <Checkbox onChange={onChange} name="isNonVeg" checked={establishmentData?.isNonVeg} />
                    <Typography style={{ lineHeight: '2.5' }}>Non Veg</Typography>
                  </div>

                  {/* <FormControlLabel control={<Checkbox onChange={onChange} name="isNonVeg" />} label="Both" /> */}
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.cancelSaveBtnOwner}>
          <CustomButton
            type="submit"
            onClick={() => handleBack()}
            // variant="contained"
            sx={{
              fontSize: { xs: "0.8rem", sm: "0.9rem" },
              backgroundColor: "white",
              borderRadius: "0.7rem",
              width: "112px",
              border: "1px solid #CBD5E1",
              color: "#334155",
              height: "40px",
              marginTop: "0.6rem",
            }}
            // sx={{ fontSize: { xs: '0.8rem', sm: '1rem', margin: '0.6rem' } }}
            textcontent="Back"
          />
          <CustomButton
            type="submit"
            onClick={() => handleSubmit(establishmentData)}
            variant="contained"
            sx={{ fontSize: { xs: "0.8rem", sm: "1rem", margin: "0.6rem" } }}
            textcontent="Save & Next"
          />
        </div>
      </div>
    </div>
  );
};

export default EstablishmentDetails;
