import { Stack } from "@mui/material";
import React from "react";

const StackRow = ({ children, between, center, ...otherProps }) => {
  return (
    <Stack
      flexDirection={"row"}
      alignItems={center ? "center" : "flex-start"}
      justifyContent={between ? "space-between" : "flex-start"}
      gap={"4px"}
      {...otherProps}
    >
      {children}
    </Stack>
  );
};

export default StackRow;
