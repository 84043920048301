import * as React from 'react';
import Chip from '@mui/material/Chip';

const ChipIcon = ({ backgroundColor = "green", text, textColor = "green", ...otherProps }) => {
  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  const chipStyle = {
    backgroundColor: backgroundColor,
     color: textColor,
    "&.MuiButtonBase-root": {
      margin: "0.2rem",
      width:"83px",
      fontSize:"11px !important",
      height:"22px"
    }
  }

  return (

    <Chip
      {...otherProps}
      label={text}
      sx={
      
        [chipStyle]
      }
      onClick={handleClick} />

  );
}

export default ChipIcon;