import { Button, Stack } from '@mui/material'
import React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { headerLink } from "../../commonValues/routingLinks";

const HeaderNavbar = () => {
    const userdata = useSelector(state => state.auth.user)
    const navigate = useNavigate();

    return (
        <Stack direction='row' alignItems={'center'} spacing={0.5} sx={{
            background: '#F8FAFC',
            borderRadius: 2,
            border: '1px solid #F1F5F9',
            p: 0.5,
            height: '2.125'
        }} >
            {headerLink.filter(f => userdata?.tabAccess?.includes(f.name)).map((elt, index) =>
                <NavBarButton key={index} id={index} link={elt.link} onClick={() => { navigate(elt.link); }} >
                    {elt.name}</NavBarButton>
            )}
            {headerLink.filter(f => userdata[0]?.tabAccess?.includes(f.name)).map((elt, index) =>
                <NavBarButton key={index} id={index} link={elt.link} onClick={() => { navigate(elt.link); }} >
                    {elt.name}</NavBarButton>
            )}
        </Stack>
    )
    // return (
    //     <Stack direction='row' alignItems={'center'} spacing={0.5} sx={{
    //         background: '#F8FAFC',
    //         borderRadius: 2,
    //         border: '1px solid #F1F5F9',
    //         p: 0.5,
    //         height: '2.125'
    //     }} >
    //         {navList.map((elt, index) =>
    //             <NavBarButton key={index} id={index} state={state} onClick={() => { navigate(elt.link); setState(index) }} >
    //                 {elt.name}</NavBarButton>
    //         )}
    //     </Stack>
    // )
}


const NavBarButton = ({ children, onClick, link }) => {
    const location = useLocation();

    let sxHoverObj = {
        borderColor: '#E2E8F0',
        background: 'white',
        color: '#0F172A',
        boxShadow: '0px 1px 3px rgba(15, 23, 42, 0.08), 0px 1px 2px -1px rgba(15, 23, 42, 0.1)'
    }
    let sxActiveObj = {};
    if (location.pathname === link) sxActiveObj = sxHoverObj
    let sxObj = {

        height: '100%',
        fontSize: '0.875rem',
        color: '#64748B',
        textAlign: 'center',
        // minWidth : '112px',
        transition: '0.3s',
        cursor: 'pointer',
        display: 'grid',
        placeContent: 'center',
        borderRadius: 2,
        border: '1px solid transparent',
        textTransform: 'inherit',
        '&:hover': sxHoverObj
    }
    return <Button sx={{
        ...sxObj,
        ...sxActiveObj
    }}
        onClick={onClick}
    >{children}</Button>
}

export default HeaderNavbar