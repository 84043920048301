import React from "react";
import Headline2 from "../../../../subComponents/pageUI/Headline2";
import { Divider } from "@mui/material";
import CustomButton from "../../../../subComponents/formUI/CustomButton";
import classes from "./AddRestaurants.module.css"
import { Form, Formik } from "formik";
import CustomTextFieldFormik from "../../../../subComponents/formUI/CustomTextFieldFormik";
import * as Yup from 'yup';
const OwnerInfo = ({ onSubmit, onSet, previousPage, sx, data }) => {

  const handleSubmit = (values) => {
    onSet({ outletAdminId: values })
    onSubmit?.(values)
  }

  const handleBack = () => {
    previousPage()
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "This value must be minimum of 3 characters.")
      .max(30, "Maximum 30 characters allowed")
      .required('Name is required'),
    // mobile:  Yup.string()
    // .matches(/^\d{10}$/, 'Invalid mobile number')
    // .test('is-numeric', 'Mobile number must contain only digits', function (mobile) {
    //   return /^[0-9]+$/.test(mobile);
    // }),
    // email: Yup.string().email('Invalid email address'),
    address: Yup.string(),
    pancard: Yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Invalid Pancard number')
  });
  return <Formik
    enableReinitialize={true}
    initialValues={{
      name: data?.outletAdminId?.name || "",
      // mobile: "",
      // email: "",
      address: data?.outletAdminId?.address || "",
      pancard: data?.outletAdminId?.pancard || ""
    }}
    isInitialValid={false || data?.outletAdminId?.name}
    validationSchema={validationSchema}
    onSubmit={(values) => {
      handleSubmit(values)
    }}
  >
    {({ isValid }) => (
      <Form style={{ overflow: "auto" }}>
        <div className={classes.formFieldWrapper} style={sx}>
          <div className={classes.formFieldLayout}>
            <div >
              <Headline2
                children={"Owner Details"}
                sx={{
                  fontSize: "1.125rem",
                  fontWeight: "0",
                  color: '#94A3B8',
                  paddingBottom: "8px",
                }}
              />
              <Divider />
            </div>
            <div className={classes.restaurantformFields}>
              <div className={classes.formFields}>
                <div className={classes.formTextFields}>
                  <CustomTextFieldFormik
                    label="Owner Name"
                    required
                    name="name"
                    placeholder="Enter your owner name"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                  />
                </div>
              </div>
              {/* <div className={classes.formFields}>
                            <div className={classes.formTextFields2}>
                                <CustomTextFieldFormik
                                    name="mobile"
                                    placeholder="Enter your mobile number"
                                    label="Owner Mobile Number"
                                    // value={mobile}
                                    // hasError={mobile.length > 0 && !(mobile.match('[0-9]{10}') && mobile?.length === 10)}
                                    // errorMassage={"Please Enter a valid mobile number"}
                                    // onChange={onChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                      }}
                                />
                            </div>
                            <div className={classes.formTextFields2}>
                                <CustomTextFieldFormik
                                    name="email"
                                    errorMassage={"Please Enter a valid Email"}
                                    label="Owner Email"
                                    // onChange={onChange}
                                    // value={email}
                                    placeholder="Enter your Email"
                                // onBlur={(e) => setIsEmailTouched(true)}
                                // onChange={(e) => setOwnerEmail(e.target.value)}
                                />
                            </div>

                        </div> */}
              <div className={classes.formFields}>
                <div className={classes.formTextFields2}>
                  <CustomTextFieldFormik
                    name="address"
                    label="Owner Address"
                    placeholder="Enter your address"
                  // value={address}
                  // errorMassage={"Please enter a valid address"}
                  // onChange={onChange}
                  />
                </div>
                <div className={classes.formTextFields1}>
                  <CustomTextFieldFormik
                    name="pancard"
                    label="Owner Pancard"
                    errorMassage={"Please Enter a valid pan number"}
                    placeholder="Enter your PAN Card Number"
                  // value={pancard}
                  // hasError={! (pancard?.trim() !== ""&&(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/).test(pancard))}
                  // onChange={onChange}
                  />
                </div>

              </div>
            </div>
            <div className={classes.cancelSaveBtnOwner}>

              <CustomButton
                onClick={() => handleBack()}
                // variant="outlined"
                // sx={{ fontSize: { xs: '0.8rem', sm: '1rem', margin: '0.6rem' } }}
                sx={{
                  fontSize: { xs: '0.8rem', sm: '0.9rem', },
                  backgroundColor: "white",
                  borderRadius: "0.7rem",
                  width: "112px",
                  border: "1px solid #CBD5E1",
                  color: "#334155",
                  height: "40px", marginTop: '0.6rem'

                }}
                textcontent="Back"
              />
              <CustomButton
                type='submit'
                disabled={!(isValid)}
                variant="contained"
                sx={{
                  fontSize: {
                    xs: '0.8rem', sm: '0.8rem',
                    margin: '0.6rem'
                  },
                  width: "112px", height: "40px"
                }}
                textcontent="Save & Next"
              />
            </div>
          </div>
        </div>
      </Form>
    )}
  </Formik>

}

export default OwnerInfo