import axios from "axios";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

async function getFinanceDetails(params) {
  return await axios.post(BACKEND_BASE_URL + "settlement/adminSettlement/getAdminFinance", params)
}

async function getFinanceDashBoard(params) {
  return await axios.post(BACKEND_BASE_URL + "settlement/adminSettlement/getAdminFinanceDashboard", params)
}

async function getAllRestaurant() {
  return await axios.get(BACKEND_BASE_URL + "outlet/getOutletList")
}

async function getFinanceOrderReport(params) {
  return await axios.post(BACKEND_BASE_URL + "settlement/adminSettlement/orderLevelExcelSheet", params)
}

export {getFinanceDetails,getFinanceDashBoard, getAllRestaurant,getFinanceOrderReport}