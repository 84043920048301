import { Link } from "@mui/material";
import React from "react";

const CustomLink = ({ children = 'Link Name', sx = {}, ...otherProps }) => {
  return (
    <Link
      {...otherProps}
      sx={{
        fontSize: "0.875rem",
        textDecoration: "none",
        ...sx,
      }}
    >
      {children}
    </Link>
  );
};

export default CustomLink;
