import { Checkbox, FormControlLabel, IconButton, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaperBox from "../../../subComponents/pageUI/PaperBox";
import Headline1 from "../../../subComponents/pageUI/Headline1";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import LogoPng from "../../../assets/Images/Orange Mealpe Logo (1).png"
import CustomTextFieldFormik from "../../../subComponents/formUI/CustomTextFieldFormik";
import { dismissLoader, presentLoader } from "../../../services/loaderService";
import { adminLogin } from "../../../services/authService";
import { authLogin } from "../../../Redux Store/Slices/auth";
import { errorToast, successToast } from "../../../services/toast";
import CustomButton2 from "../../../subComponents/formUI/CustomButton2";
import { useState } from "react";
import CustomLink from "../../../subComponents/formUI/CustomLink";
import { resetAdminPassword } from "../../../services/user";
import style from './Login.module.css'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { headerLink } from "../../../commonValues/routingLinks";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState(false)
  const [isEmail, setIsEmail] = useState('')
  const [emailError, setEmailError] = useState("")
  const [showPassword, setShowPassword] = useState(false);

  function goTologin(values) {
    presentLoader()
    adminLogin(values).then(res => {
      if (res?.data?.success) {
        let _userData = null;
        if (res?.data?.data?.[0]) {
          _userData = res?.data?.data?.[0];
        } else {
          _userData = res?.data?.data;
        }

        if (_userData?.tabAccess?.length > 0) {
          if (headerLink.find(f => f.name === _userData?.tabAccess[0])) {
            dispatch(authLogin(_userData))
            navigate(headerLink.find(f => _userData?.tabAccess.some(s => s === f.name))?.link);
            successToast("Login Successfully")
          } else {
            //! error tost some wring in routing path
            errorToast("Something wrong in routing path")
          }
        } else {
          //todo show error tost dont asscess 
          errorToast("You don't have access")
        }
      }
      dismissLoader();
    }
    ).catch(
      error => { dismissLoader(); console.log(error.response.data.message); errorToast(error.response.data.message) })
  }
  const rememberMe = (e)=>{
     localStorage.setItem('checked',!state)
    setState(e.target.checked)

  }
  const handleResetPassword = (e) => {
    e.preventDefault();
    if (isEmail) {
      resetAdminPassword(isEmail).then((res) => {
        if (res.data.success) {
          dismissLoader();
          successToast("Email Sent Successfully...")
        }
      }).catch((err) => console.log(err))
    }
  }
  return (

    <Stack
      className="login-wrapper"
      sx={{
        minHeight: "100vh",
        display: "grid",
        placeContent: "center",
      }}
    >
      <PaperBox
        sx={{
          minWidth: 444,
          boxShadow:
            "0px 20px 25px -5px rgba(15, 23, 42, 0.10), 0px 10px 10px 0px rgba(15, 23, 42, 0.05)",
          borderRadius: "14px",
        }}
      >
        <Stack spacing={4} alignItems={"center"} mb={4}>
          <img
            src={LogoPng}
            alt="logo"
            style={{
              height: 100,
              width: 100,
              objectFit: "contain",
            }}
          />
          <Stack spacing={"10px"} className={style.logInContainer}>
            <Headline1 textAlign="center">Log in</Headline1>
            <Typography color={"#475569"} fontSize={16} >
              Hey there! Welcome back 👋
            </Typography>
            {/* <Lead textAlign="center"> Hey there! Welcome back 👋</Lead> */}
          </Stack>
        </Stack>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Invalid email")
              .required("Email is required"),
            password: Yup.string().required("Password is required")
          })}
          onSubmit={(values) => {
            goTologin(values)
            handleResetPassword(values.email)
          }}
        >
          <Form>
            <Stack gap={"0px"}>
              <CustomTextFieldFormik
                inputProps={{
                  autoComplete: 'off',
                }}
                onBlur={(e) => setIsEmail(e.target.value)}
                label="Email"
                name="email"
                placeholder="enter your email"
              />
              <span style={{
                fontSize: '12px',
                color: '#d32f2f'
              }} >{isEmail.length <= 0 && emailError}</span>

              <Stack gap={'5px'} position={'relative'}>
                <CustomLink
                  textAlign="right"
                  sx={{
                    position: "relative",
                    top: "20px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setEmailError(isEmail.length <= 0 ? 'Email is Required' : '')
                    handleResetPassword(e)
                  }}
                >
                  Forgot Password?
                </CustomLink>
          
                <CustomTextFieldFormik
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  label="Password"
                  placeholder="enter your password"
                  InputProps={{
                    endAdornment:(
                      <InputAdornment position="end" style={{height: "auto"}}>
                        {showPassword ? (<IconButton onClick={()=>{console.log("true"); setShowPassword(false)}} ><Visibility/></IconButton> ) : (<IconButton onClick={()=>{console.log("false"); setShowPassword(true)}} ><VisibilityOff/></IconButton>)}
                      </InputAdornment>
                   
                    )
                  }}
                />
                
              </Stack>
              <Stack gap={'10px'}>
                <FormControlLabel
                  control={<Checkbox value={state} onChange={e => rememberMe(e) } color="primary" />}
                  label="Remember me"
                />
                <p style={{
                  fontSize: '12px',
                  color: '#d32f2f'
                }} >{!state }</p>
              </Stack>
              <CustomButton2 type="submit"
              //  onClick={() => setStateMessage(!state ? 'Remember is Required' : '')}
              >
                Log in
              </CustomButton2>
            </Stack>
          </Form>
        </Formik>
      </PaperBox>
    </Stack>

  );
};

export default Login;

// const routerLinks = {
//   "Dashboard": "",
//   "Restaurant": "/home/restaurants",
//   "Payment": "",
//   "User": "",
//   "Configuration": "",
// }

