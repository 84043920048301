import React, { useState, useEffect } from 'react';
import cssClasses from "./addOutlet.module.css";
import { Box } from '@mui/material';
import CustomTextField from '../../../../subComponents/formUI/CustomTextField';
import CustomButton from '../../../../subComponents/formUI/CustomButton';

function Verification({ outletData }) {

    const [email, setEmail] = useState(null);
    const [mobileNumber, setMobileNumber] = useState(null);

    const [helperText, setHelperText] = useState({ email: "", mobile: "" });

    const [isEmailOtpLoading, setIsEmailOtpLoading] = useState(false);

    useEffect(() => {
        if (outletData?.email) {
            setEmail(outletData?.email);
        }
        if (outletData.mobile) {
            setMobileNumber(outletData.mobile);
        }
    }, [outletData])

    async function emailSendOTP() {
        try {
            
        } catch (error) {
            
        }
    }

    return (
        <Box>
            <div className={cssClasses.subFormHeaderWithDivider}>
                <p>Restaurant Basic Details:</p>
            </div>

            <Box display={"flex"} gap={"1.5rem"} mt={"1rem"}>
                <div>
                    <CustomTextField
                        required
                        // hasError={!(email.trim() !== "" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))}
                        hasError={Boolean(helperText.email)}
                        errorMassage={"Enter a valid Email"}
                        label="Email"
                        name="email"
                        value={email || ''}
                        disabled={outletData?.email}
                        placeholder="Enter your outlet email."
                        // onBlur={(e) => setIsEmailTouched(true)}
                        onChange={(_value) => {
                            setEmail(_value.target.value);
                        }}
                        onKeyPress={(e) => {
                            // e.key === "Enter" && sendEmailOtp();
                        }}
                        sx={{ width: "25rem" }}
                        autoComplete="nope"
                    />

                    <CustomButton
                        loading={isEmailOtpLoading}
                        textContent="Send OTP"
                        variant="contained"
                        disabled={Boolean(helperText.email)}
                        onClick={() => {
                          emailSendOTP();
                        }}
                        sx={{
                          borderRadius: "8px",
                          py: "10px",
                          px: "16px",
                          cursor: "pointer",
                        }}
                      />
                </div>
            </Box>
        </Box>
    )
}

export default Verification