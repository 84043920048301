import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import CustomTextField from '../../subComponents/formUI/CustomTextField'
import CustomSelect from '../../subComponents/formUI/CustomSelect'
import CustomLink from '../../subComponents/formUI/CustomLink'
import CustomButton from '../../subComponents/formUI/CustomButton'
import CustomChip from '../../subComponents/pageUI/CustomChip'
import CustomMenu from '../../subComponents/pageUI/CustomMenu';
import CustomToggleButtons from '../../subComponents/formUI/CustomToggleButtons';
import CustomRadio from '../../subComponents/formUI/CustomRadio';
import CustomCheckBox from '../../subComponents/formUI/CustomCheckBox';
import CustomSwitch from '../../subComponents/formUI/CustomSwitch';
import Headline1 from '../../subComponents/pageUI/Headline1'
import Lead1 from '../../subComponents/pageUI/Lead1'
import SearchIcon from '@mui/icons-material/Search';
import { CurrencyBitcoin } from '@mui/icons-material'

const Components = () => {
  return (
    <Box width={800} mx='auto' my={3} sx={{
      '& .MuiStack-root': {

      }
    }} >
      <Stack spacing={2} sx={{ width: '100%', mb: 4 }} >
        <Typography variant='h5' >/formUI</Typography>
        <CustomTextField label='CustomTextField' startAdornment={<SearchIcon />}
          onChange={(e) => console.log(e)}
        />
        <CustomSelect label='CustomSelect' />
        <CustomLink>CustomLink</CustomLink>
        <CustomButton variant='contained' >CustomButton</CustomButton>
      </Stack>
      <Stack spacing={2} sx={{ width: '100%', mb: 4 }} >
        <Typography variant='h5' >/pageUI</Typography>
        <Headline1>Headline1</Headline1>
        <Lead1>Lead1</Lead1>

        <CustomMenu />
      </Stack>
      <CustomChip label='Payments' />
      <CustomChip label='Users' theme='blue' />
      <CustomChip label='Active' theme='active' dotted />
      <CustomChip label='Pending' theme='pending' dotted />
      <CustomTextField required label='CustomTextField' startAdornment={<CustomSelect noLabel label={<CurrencyBitcoin />} />} />

      <Headline1>Toggle Buttons</Headline1>
      <CustomToggleButtons
        tab={[{ label: "One", value: "One" }, { label: "Two", value: "Two" }, { label: "Three", value: "Three" }, { label: "Four", value: "Four" }]}
        selectedTab={"One"}
        onChangeTab={(_tabValue) => {
          console.log("_tabValue", _tabValue);
        }}
      />

      <Headline1>Radio Box</Headline1>
      <CustomRadio
        optionList={["One", "Two", "Three"]}
        value={"Two"}
        radioContainerStyle={{ flexDirection: "column" }}
        onChangeValue={(_Value) => {
          console.log("_Value", _Value);
        }}
      />

      <Headline1>Check Box</Headline1>
      <CustomCheckBox
        optionList={["One", "Two", "Three"]}
        value={["One", "Two"]}
        radioContainerStyle={{ flexDirection: "column" }}
        onChangeValue={(_Value) => {
          console.log("_Value", _Value);
        }}
      />
      
      <Headline1>Switch Buttom</Headline1>
      <CustomSwitch
        checked={true}
        onChange={(e) => {
          const _value = e?.target?.checked;
          console.log("CustomSwitch => ", _value);
        }}
      />

    </Box>
  )
}

export default Components