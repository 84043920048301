import { Box, Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import PaperBox from "./PaperBox";
import CloseIcon from '@mui/icons-material/Close';

const CustomDialog = ({
  open: _open,
  setOpen: _setOpen = () => { },
  buttonComp,
  width,
  children,
  onClose,
}) => {
  const [open, setOpen] = useState(_open || false);
  const closeIconStyle = {
    float: "right",
    fill: "#a19d9d"
  }

  useEffect(() => {
    setOpen(_open);
  }, [_open]);
  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(!open);
          _setOpen(!open);
          onClose?.();
        }}
        sx={{
          "& .MuiDialog-paper": {
            background: "transparent",
            width: width || "auto",
          },
        }}
      >
        <PaperBox padding={"24px"}>
          <CloseIcon style={closeIconStyle} onClick={() => setOpen(!open)} />
          {children}</PaperBox>
      </Dialog>
      {buttonComp({
        onClick: () => {
          setOpen(!open);
          _setOpen(!open);
        },
      })}
    </Box>
  );
};

export default CustomDialog;