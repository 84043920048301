import * as React from "react";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import img1 from "../../../assets/Images/emptyHeader.svg";
import logo1 from "../../../assets/Images/headerLogo.svg";
import classes from "./Restaurants.module.css";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../../subComponents/formUI/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { getAllRestaurant, updateOutlet } from "../../../services/restaurant";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import NoDataFound from "../../../svgComponents/NoDataFound";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Chip,
  Stack,
} from "@mui/material";
import { dismissLoader, presentLoader } from "../../../services/loaderService";
import cssclass from "../Users/Users.module.css";
import RestoListFilter from "../Filters/RestoListFilter";
import StatusFilter from "../Filters/StatusFilter";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { successToast } from "../../../services/toast";

const ITEM_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      marginBottom: "16px",
      border: "1px solid #E2E8F0",
      height: "290px",
    },
  },
}));
const Restaurants = () => {
  const navigate = useNavigate();
  const overrideClasses = useStyles();
  const [pageNo, setPageNo] = useState(1);
  const pageSize = 11;
  const [searchText, setSearchText] = useState("");
  const [restaurantData, setRestaurantData] = useState([]);
  const [isSort, setIsSort] = useState("");
  const [cityId, setCityId] = useState("");
  const [outletInfo, setOutletInfo] = useState();

  const handleClick = () => {
    navigate("/home/restaurants/addRestaurants");
  };
  const handleUpdate = (evt, outletId) => {
    navigate("/home/restaurants/editRestaurants/" + outletId, { state: evt });
  };

  const [hasMore, setHasMore] = useState(true);
  const [totalData, setTotalData] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const open = Boolean(anchorEl);
  const handleClickIcon = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchMoreData();
  }, [searchText, isSort, cityId]);

  const fetchMoreData = () => {
    presentLoader();
    getAllRestaurant(pageNo, pageSize, searchText, isSort, cityId)
      .then((res) => {
        if (res.data.success) {
          dismissLoader();
          setTotalData(res.data.meta.totalCount);
          setTotalPages(res.data.meta.totalPages);
          setCurrentPage(res.data.meta.page);
          setRestaurantData((pre) => [...pre, ...res.data.data]);
          // if (isSort || cityId) {
          //     setRestaurantData(res.data.data)
          // }
          if (totalPages === pageNo) {
            setHasMore(false);
          } else {
            setPageNo(pageNo + 1);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSearch = (e) => {
    let text = e.target.value;
    setSearchText(text);
    setRestaurantData([]);
    setPageNo(1);
    setTimeout(() => {
      getAllRestaurant(pageNo, pageSize, text, isSort, cityId)
        .then((res) => {
          if (res.data.success) {
            setTotalData(res.data.meta.totalCount);
            setRestaurantData(res.data.data);
          }
        })
        .catch((err) => console.log(err));
    }, 500);
  };

  const handleActive = (outletId, data) => {
    console.log(outletId, data);
    presentLoader();
    const params = {
      isActive: data,
    };
    updateOutlet(outletId, params)
      .then((res) => {
        {
          res?.data?.data?.[0]?.isActive === false
            ? successToast("Restaurant Deactivated Successfully")
            : successToast("Restaurant Activated Successfully");
        }
        setOutletInfo(res?.data?.data[0]);
        const _index = restaurantData.findIndex((f) => f.outletId === outletId);

        if (_index > -1 && res?.data?.data?.[0]) {
          restaurantData[_index] = res?.data?.data?.[0];
          setRestaurantData([...restaurantData]);
        }

        dismissLoader();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={classes.restaurantPageWrapper}>
      <div className={classes.restaurantMainLayout}>
        <div className={classes.restaurantsHeaderMain}>
          <div className={classes.restaurantsHeaderLeft}>
            <div className={classes.helloWord}>Hello 👋</div>
            <div className={classes.welcomeWord}>Welcome to Restaurants</div>
          </div>
          <div className={classes.restaurantsHeaderRight}>
            <CustomButton
              sx={{
                marginTop: 12 + "px",
                fontSize: { xs: "0.8rem", sm: "0.9rem" },
                width: "179px",
                height: "44px",
              }}
              variant="contained"
              onClick={handleClick}
              textcontent="Add Restaurants"
              startIcon={<AddIcon />}
            />
          </div>
        </div>
        <hr />
        <div className={classes.restaurantsHeaderMain}>
          <div className={classes.SearchIcon}>
            <TextField
              placeholder="Search"
              type="text"
              variant="outlined"
              fullWidth
              size="small"
              onChange={(e) => handleSearch(e)}
              value={searchText}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),

                endAdornment: searchText && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setSearchText(" ");
                      fetchMoreData();
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />

            {/* <CustomTextField height={'2.2rem'} startAdornment={<SearchIcon />} onChange={handleSearch} placeholder={"Search "} /> */}
          </div>
          <div className={cssclass.sortButton}>
            <StatusFilter
              label="Sort by"
              width={"150px"}
              setIsSort={setIsSort}
              cityId={cityId}
              onClick={(e) => {
                setPageNo(1);
                setRestaurantData([]);
              }}
            />
            <>
              {/* <LocationOnIcon /> */}
              <RestoListFilter
                height={"25rem"}
                label="Select City "
                setCityId={setCityId}
                cityId={cityId}
                onClick={(e) => {
                  setPageNo(1);
                  setRestaurantData([]);
                }}
              />
            </>
            <CustomButton
              sx={{
                fontSize: { xs: "0.8rem", sm: "0.9rem" },
                backgroundColor: "white",
                width: "112px",
                border: "1px solid #CBD5E1",
                color: "#334155",
              }}
              onClick={() => {
                setRestaurantData([]);
                setPageNo(1);
                setCityId("");
                fetchMoreData();
              }}
              textcontent="Reset"
            />
          </div>
        </div>
      </div>

      <Box
        id="scroll"
        style={{
          // height: '70vh',
          flex: 1,
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
        sx={{
          "& > div": {
            display: "flex",
            flexDirection: "column",
            flex: "1",
          },
        }}
      >
        <InfiniteScroll
          // style={{ flex: 1 }}
          height={"70vh"}
          dataLength={restaurantData?.length}
          next={currentPage < totalPages ? fetchMoreData : ""}
          hasMore={hasMore}
          scrollableTarget="scroll"
          loader={
            !(restaurantData.length <= totalData && searchText.length) &&
            restaurantData.length === 0 && (
              <div style={{ justifyContent: "center", display: "flex" }}>
                <CircularProgress />
              </div>
            )
          }
        >
          {restaurantData?.length === 0 && (
            <p className={classes.noData}>No Restaurant Found</p>
          )}
          {!restaurantData && restaurantData?.length === 0 ? (
            <div>
              <NoDataFound name={"Restaurant"} />
            </div>
          ) : (
            <div className={classes.restaurantsCardsMain}>
              {restaurantData?.map((e) => {
                return (
                  <React.Fragment key={e?.outletId}>
                    <div className={overrideClasses.root}>
                      <Card
                        sx={{ padding: 1, border: 0.1, position: "relative" }}
                      >
                        {!e.isActive && (
                          <Stack direction="row" spacing={1}>
                            <Chip
                              label="Deactive"
                              sx={{
                                color: "#B91C1C",
                                background: "#FEF2F2",
                                // pb: 1,
                                padding: "0.125rem 0.5rem",
                                position: "absolute",
                              }}
                              variant="outlined"
                            />
                          </Stack>
                        )}
                        <Link to={`outlet/orders/${e?.outletId}`}>
                          <CardMedia
                            component="img"
                            height="194"
                            image={e.headerImage ? e.headerImage : logo1}
                            alt="Restaurants"
                          />
                        </Link>

                        <div
                          className={classes.cardContent}
                          style={{ display: "flex" }}
                        >
                          <CardHeader
                            sx={customCardMediaStyle}
                            avatar={
                              <Avatar variant="square" aria-label="recipe">
                                <CardMedia
                                  component="img"
                                  height="14"
                                  image={e.logo ? e.logo : img1}
                                  alt="Restaurants"
                                  sx={customCardMediaStyle}
                                />
                              </Avatar>
                            }
                            title={
                              <p
                                style={{
                                  fontSize: "18px",
                                  color: "#000000",
                                  fontWeight: 500,
                                }}
                              >
                                {e.outletName}{" "}
                              </p>
                            }
                            subheader={
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#64748B",
                                  fontWeight: 400,
                                  width: "200px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {e.address}{" "}
                              </p>
                            }
                          />

                          <div>
                            <span style={{ display: "none" }}>
                              {e.outletId}
                            </span>
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(evt) => {
                                setOutletInfo(e);
                                handleClickIcon(evt);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              // id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              style={{
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "20ch",
                              }}
                              sx={{
                                ".MuiPaper-root": {
                                  borderRadius: "12px",
                                  border: "1px solid var(--gray-200, #E2E8F0)",
                                  background: "var(--generic-white, #FFF)",
                                  boxShadow: "0px 1px 1px 0px #E2E8F0",
                                },
                              }}
                              // classes={classes.editMenu}
                            >
                              {/* <MenuItem key={'Password'} className={classes.cardMenu} >
                                                            Reset Password
                                                        </MenuItem> */}
                              <MenuItem
                                key={"edit"}
                                className={classes.cardMenu}
                                onClick={(event) => {
                                  setOutletInfo(e?.outletId);
                                  handleUpdate(
                                    outletInfo,
                                    outletInfo?.outletId
                                  );
                                }}
                              >
                                Edit
                              </MenuItem>

                              <MenuItem
                                key={"active"}
                                className={classes.cardMenu}
                                onClick={() =>
                                  handleActive(
                                    outletInfo?.outletId,
                                    !outletInfo?.isActive
                                  )
                                }
                              >
                                {outletInfo?.isActive ? "Deactive" : "Active"}
                              </MenuItem>
                              {/* {options.map((option) => (
                                                    <MenuItem key={option} className={classes.cardMenu} onClick={(event)=> option === 'Edit' && handleUpdate(event,e,e.outletId)}>
                                                        {option}
                                                    </MenuItem>
                                                ))} */}
                            </Menu>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </InfiniteScroll>
      </Box>
    </div>
  );
};
export default Restaurants;

const customCardMediaStyle = {
  "& .MuiAvatar-root ": {
    borderRadius: "1rem",
    width: "44px",
    height: "44px",
  },
  margin: "0",
  "& .MuiCardMedia-root ": {
    height: "100%",
  },
};
