import React, { useEffect, useState } from "react";
import ChipIcon from "../../../svgComponents/ChipIcon";
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from "@mui/x-data-grid";
import CustomButton from "../../../subComponents/formUI/CustomButton";
import classes from "./Campus.module.css";
import {  Stack, Typography } from "@mui/material";
import { createCampus, getAllCampus, getAllCities, updateCampus } from "../../../services/campus";
import { dismissLoader, presentLoader } from "../../../services/loaderService";
import { successToast } from "../../../services/toast";
import CampusModal from "./CampusModal";

const Campus = () => {

    const [open, setOpen] = React.useState(false);

    // const [campusName, setCampusName] = useState("")
    // const [isCampusNameTouched, setCammpusNameTouched] = useState(false);

    // const [campusCity, setCampusCity] = useState("")
    // const [isCampusCityTouch, setIsCampusCityTouched] = useState(false)

    // const [campusAddress, setCampusAddress] = useState("");
    // const [isCampusAddressTouch, setCampusAddressTouched] = useState(false)


    // const isCampusNameValid = campusName?.trim() !== "" && campusName?.length >= 3;
    // const isCampusNameHasError = !isCampusNameValid && isCampusNameTouched;

    // const isCampusCityValid = campusCity?.trim() !== "" && campusCity?.length >= 3;
    // const isCampusCityHasError = !isCampusCityValid && isCampusCityTouch;

    // const isCampusAddressValid = campusAddress?.trim() !== "" && campusAddress?.length >= 5;
    // const isCampusAddressHasError = !isCampusAddressValid && isCampusAddressTouch;


    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [cityList, setCityList] = useState([])

    const [totalData, setTotalData] = useState(0)
    const [row, setRow] = useState([])
    const [pageForServer, setPageForServer] = useState(0);
    const [pageForDataGrid, setPageForDataGrid] = useState(0);
    const [loading, setLoading] = useState(true)
    const tasksPerPage = 10;
    const handleChangePage = (page, details) => {
    
        if (page.page > pageForDataGrid) {
            if (totalData === row?.length) {
                setPageForDataGrid(pageForDataGrid + 1)
            } else {
                setPageForServer(page.page);

            }
        } else {
            setPageForDataGrid(pageForDataGrid - 1)
        }
    };

    const getCampus = () =>{
        setLoading(true);
        getAllCampus(pageForServer + 1, tasksPerPage + 1).then((res) => {
            if (res.data.success) {
                if (pageForServer > 0) {
                    setPageForDataGrid(pageForDataGrid + 1)
                }
                setTotalData(res.data.meta.totalCount)
                setRow([...row, ...res.data.data])
                setLoading(false)
            }
        }).catch((err) => console.log(err))
    }

    useEffect(() => {
        setLoading(true);
        getAllCities().then((res) => {
            let tempArr = [];
            res.data.data.map((e) => tempArr.push({
                name: e.city,
                value: e.cityId
            }))
            setCityList(tempArr)

        }
        ).catch((err) => console.log(err))

        getAllCampus(pageForServer + 1, tasksPerPage + 1).then((res) => {
            if (res.data.success) {
                if (pageForServer > 0) {
                    setPageForDataGrid(pageForDataGrid + 1)
                }
                setTotalData(res.data.meta.totalCount)
                setRow([...row, ...res.data.data])
                setLoading(false)
            }
        }).catch((err) => console.log(err))
    }, [pageForServer, tasksPerPage])

    useEffect(() => {
        getCampus()
    }, [pageForServer, tasksPerPage])


    const handleSubmit = (data) => {

        presentLoader()
       
        createCampus(data?.campusName, data?.cityId, data?.address,data?.tag,data?.latitude,data?.longitude).then((res) => {
       
            if (res?.data?.success) {
                dismissLoader()
                handleClose()
                successToast("Campus Added Successfully")
                setRow([...row,res.data.data])
                window.location.reload()
            }
        }
        ).catch((err) => console.log(err))

        console.log("handle submit data ",data)
    }

    const handleEdit = (campusId,data) => {
        presentLoader()
        updateCampus(campusId, data).then((res) => {
            if (res.data.success) {
                successToast("Campus Updated  Successfully")
                dismissLoader()
                handleClose()
                setRow(res.data.data)
                setLoading(true);
                getAllCampus(pageForServer + 1, tasksPerPage + 1).then((res) => {
                    if (res.data.success) {
                        if (pageForServer > 0) {
                            setPageForDataGrid(pageForDataGrid + 1)
                        }
                        setTotalData(res.data.meta.totalCount)
                        setRow([...row, ...res.data.data])
                        setLoading(false)
                    }
                }).catch((err) => console.log(err))
            }
            else {
                dismissLoader()
                handleClose()
            }


        
        }
        ).catch((err) => console.log("errror"))

    }


    const columns = [
        {
            field: "campusName",
            headerName: "College Name",
            width: 200,
            renderCell: (p) => (
                <p style={{ fontSize: "14px", color: "#0F172A", fontWeight: 500 }}>{p.value}</p>

            )
        },
        {
            field: "city",
            headerName: "City",
            width: 200,
            renderCell: (p) => (
                <p style={{ fontSize: "14px", color: "#475569", fontWeight: 400 }}>{p.row.cityId.city}</p>

            )
        },
        {
            field: "address",
            headerName: "Address",
            width: 200,
            renderCell: (p) => (
                <p style={{ fontSize: "14px", color: "#475569", fontWeight: 400 }}>{p.value}</p>

            )
        },

        {
            field: "isDelete",
            headerName: "Status",
            width: 150,
            renderCell: (p) => (
                !p.value ? <ChipIcon text={"active"} backgroundColor="#F0FDF4" textColor="#15803D" /> : <ChipIcon text={"Closed"} backgroundColor="#FFFBEB" textColor="#B45309" />)


        },
        {
            field: "tag",
            headerName: "tag",
            width: 200,
            renderCell: (p) => (
                <p style={{ fontSize: "14px", color: "#475569", fontWeight: 400 }}>{p?.row?.tag || "-"}</p>

            )
        },
        {
            field: "action",
            headerName: "Action",
            width: 80,
            renderCell: (p) => {
                return (
                    <CampusModal defaultData={p.row} onSubmit={data => { handleEdit(p?.row?.campusId,data) }} cityList={cityList} >
                        <Stack direction={'row'} spacing={1} style={{
                            cursor: 'pointer'
                        }} >
                            <EditIcon />
                            <Typography sx={{
                                textDecoration: 'underline'
                            }} >Edit</Typography>
                        </Stack>
                    </CampusModal>

                )
            }


        },
    ];


    // const isFormValid = () => {

    //     return isCampusNameValid && isCampusCityValid && isCampusAddressValid
    // }


    return <div>
        <div className={classes.restaurantsHeaderMain}>
            <div className={classes.restaurantsHeaderLeft}>
                <div className={classes.helloWord}>Campus</div>
                <div className={classes.welcomeWord}>View and update your details</div>
            </div>
            <div className={classes.restaurantsHeaderRight}>
                <div className={classes.sortFilterSelect}>
                    {/* <CustomSelect sx={{ width: "7.12rem" }} noLabel />
                    <CustomSelect sx={{ width: "7.12rem" }} placeholder="Hii" noLabel /> */}
                    <CampusModal
                        cityList={cityList}
                        open={open}
                        onDiscard={() => { setOpen(!open) }}
                        onSubmit={data => {
                            handleSubmit(data)
                        }}
                    >

                        <CustomButton
                            sx={{ fontSize: { xs: '0.8rem', sm: '1rem' }, width: "133px", height: "44px" }}
                            variant="contained"
                            onClick={handleOpen}
                            textcontent="Add Campus"

                        />
                    </CampusModal>

                </div>
            </div>
        </div>
        <div style={{ height: ' 80vh', minHeight: "500px", width: 'calc(100% + 32px)', padding:"32px 0 0 32px" }}>
            <DataGrid
                getRowId={row => row.campusId}
                onPaginationModelChange={handleChangePage}
                pageSizeOptions={[tasksPerPage]}
                loading={loading}
                pagination
                sx={[dataGridStyles]}
                rows={row}
                columns={columns}
                paginationModel={{ page: pageForDataGrid, pageSize: tasksPerPage }}
                disableColumnFilter={true}
                disableColumnMenu={true}
                disableColumnSelector={true}
                disableDensitySelector={true}
                disableSelectionOnClick={true}
                rowHeight={72}
            />
        </div>

    </div>

}

export default Campus;

const dataGridStyles = {
    // height: height || "600px",
    // border: "none",
    // ...sx,
    // "&  .MuiDataGrid-menuIcon,.MuiDataGrid-columnSeparator": {
    //     display: "none",
    //     cursor: "none",
    // },
    // "& .MuiDataGrid-columnHeaders": {
    //     borderRadius: 1,
    //     border: "1px #E2E8F0 solid",
    //     borderRadius: "9px",
    //     background: "#F8FAFC",
    //     color: "#64748B",
    //     fontSize: "14px",
    // },
    // "& .MuiDataGrid-virtualScrollerRenderZone": {
    //     "& .MuiDataGrid-row": {
    //         borderBottom: "1px solid #EAECF0",
    //         background: "#FFFFFF",
    //         borderRadius: "1rem",
    //     },
    //     "& .MuiDataGrid-row.Mui-selected:hover": {
    //         borderBottom: "1px solid #EAECF0",
    //         backgroundColor: "#FFFFFF",
    //     },
    // },
    "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#E2E8F0"
    },
    // "&.MuiDataGrid-columnHeadersInner":
    // {
    //     backgroundColor:"blue"

    // }
    "&.MuiDataGrid-iconButtonContainer": {
        visiblity: "hidden"
    },
    '& .MuiDataGrid-menuIcon, .MuiDataGrid-iconButtonContainer': {
        display: 'none'
    },
    '& .MuiDataGrid-cell:focus': {
        // border : 'red 1px solid',
        outline: 'none'

    },
    '& .MuiDataGrid-row.Mui-selected,.MuiDataGrid-row.Mui-selected:hover,.MuiDataGrid-row:hover': {
        backgroundColor: 'transparent'
    },
    "& .MuiDataGrid-main": {
        // paddingBottom: "10rem"
    },


    "& .MuiDataGrid-footerContainer": {
        "& .MuiTablePagination-displayedRows ": {
            display: "none !important",
        },
        "& .MuiDataGrid-selectedRowCount": {
            visibility: "hidden ",
        },
        " .MuiTablePagination-selectLabel": {
            display: "none !important",
        },
        " .MuiInputBase-root": {
            display: "none !important",
        }
    },
    "&  [title='Sort']": {
        //visibility:"hidden",
        display: "none",
        cursor: "none",
    },
    "& [title='Go to previous page']": {
        border: "1px solid #EAECF0",
        borderRadius: "0.8rem",
        marginRight: "0.5rem",
    },
    "& [title='Go to next page']": {
        border: "1px solid #EAECF0",
        borderRadius: "0.8rem",
        marginRight: "0.5rem",
    },
    "& [title='Go to previous page']:after": {
        content: "'Previous'",
        marginLeft: "1rem",
    },
    "& [title='Go to next page']:before": {
        content: "'Next'",
        marginRight: "1rem",
    },
    "& .MuiDataGrid-columnHeader": {
        //   padding: "0 4rem",

        "&:focus": {
            outline: "none",
        },
    },
    "&  .MuiDataGrid-row ": {
        //   maxHeight: "72px !important",
        //   minHeight: "72px !important",
    },
    "& .MuiDataGrid-cell": {
        //   padding: "0 4rem",
        //   minHeight: "72px !important",
        //   maxHeight: "72px !important",
        "&:focus": {
            outline: "none",
        },
    },
    "& .MuiButtonBase-root": {
        fontSize: "1rem",
    },
    "& .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
    },
};