import React, { useEffect, useState } from "react";
import Headline2 from "../../../../subComponents/pageUI/Headline2";
import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import CustomButton from "../../../../subComponents/formUI/CustomButton";
import classes from "./AddRestaurants.module.css";
import { getAllDays } from "../../../../services/days";
import { getAllCategories } from "../../../../services/categories";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  createRestaurant,
  updateRestaurant
} from "../../../../services/restaurant";
import {
  dismissLoader,
  presentLoader,
} from "../../../../services/loaderService";
import { errorToast, successToast } from "../../../../services/toast";
import axios from "axios";
import dayjs from 'dayjs';
import style from './moreInfo.module.css'
import moment from "moment/moment";
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Link } from "react-router-dom";

const MoreInformation = ({
  data,
  ownerInfoData,
  restaurantInfoData,
  contactInfo,
  eData,
  sx,
  previousPage,
  setActiveStep,
  documents,
  setSuccessMessage
}) => {

  const [openTimeForInputValue, setOpenTimeForInputValue] = useState(null);
  const [closeTimeForInputValue, setCloseTimeForInputValue] = useState(null);
  const [openTime, setOpenTime] = useState(null);
  const [closeTime, setCloseTime] = useState(null);
  const [allDaysCheck, setAllDaysCheck] = useState(false);
  const [termsConditions, setTermsConditions] = useState(false);

  const handleCategoryFromTimeChange = (e) => {
    setOpenTime(`${e.$H.toString().length === 1 ? `0${e.$H}` : e.$H}:${e.$m.toString().length === 1 ? `0${e.$m}` : e.$m}`);
  };
  const handleCategoryToTimeChange = (e) => {
    setCloseTime(`${e.$H.toString().length === 1 ? `0${e.$H}` : e.$H}:${e.$m.toString().length === 1 ? `0${e.$m}` : e.$m}`);
  };

  const [formData, setFormData] = useState([]);
  const [getCategories, setGetCategories] = useState([]);

  // const [data, setData] = useState([]); // State to store API data
  const [checkedDays, setCheckedDays] = useState(false); // State to store checked items

  useEffect(() => {
    if (data?.openTime) {
      setOpenTime(data?.openTime);
      setOpenTimeForInputValue(dayjs(new Date(moment(data?.openTime, "hh:mm")?.format())));
    }
    if (data?.closeTime) {
      setCloseTime(data?.closeTime);
      setCloseTimeForInputValue(dayjs(new Date(moment(data?.closeTime, "hh:mm")?.format())));
    }
  }, [data])

  useEffect(() => {
    if (data?.Timing?.length === 7) {
      setAllDaysCheck(true)
    }
    if (data?.Timing) {
      const _data = formData?.map(m => {
        let flag = false;
        const timeObj = data?.Timing?.find(f => f.dayId === m.dayId)

        let returnObj = { ...m };
        if (timeObj && timeObj?.openTime && timeObj?.closeTime) {
          timeObj?.timeId && setCheckedDays(true)
          flag = true;
          returnObj.openTime = timeObj?.openTime;
          returnObj.closeTime = timeObj?.closeTime;
        } else {
          returnObj.openTime = null;
          returnObj.closeTime = null;
        }
        returnObj.isChecked = flag;

        if (timeObj?.timeId) {
          returnObj.timeId = timeObj?.timeId;
        }
        return returnObj;
      });
      setFormData([..._data]);

    }
  }, [data, openTime, closeTime])

  useEffect(() => {

    getAllDays().then((res) => {
      if (res?.data?.data) {
        setFormData(res.data?.data?.map(m => ({ ...m, isChecked: false })));
      }
    }).catch((err) => console.log(err));

    getAllCategories().then((res) => {
      let tempArr = [];
      res.data.data.map((e) =>
        tempArr.push({
          label: e.category,
          id: e.categoryId,
        })
      );
      setGetCategories(tempArr);
    })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (data?.Restaurant_category) {
      data?.Restaurant_category.map((e) => {
       return setSelectedChips((prevSelectedChips) =>
          prevSelectedChips?.includes(e.categoryId)
            ? prevSelectedChips?.filter((id) => id !== e.categoryId)
            : [...prevSelectedChips, e.categoryId]
        );
      })
    }
  }, [data])

  const handleCheckboxChange = (dayId) => {
    let selectedTime = formData.find(f => f.dayId === dayId);

    if (selectedTime) {
      if (selectedTime.isChecked) {
        selectedTime.isChecked = false;
        selectedTime.closeTime = null;
        selectedTime.openTime = null;
      } else {
        selectedTime.isChecked = true;
        selectedTime.closeTime = closeTime;
        selectedTime.openTime = openTime;
      }
    }
    setFormData([...formData])
    // if (formData.find(f => f.dayId === dayId)) {
    //   formData.find(f => f.dayId === dayId).isChecked = !formData.find(f => f.dayId === dayId).isChecked;
    // }
    // setFormData((prevState) => ({
    //   ...prevState,
    //   days: {
    //     ...prevState.days,
    //     [dayId]: {
    //       ...prevState.days[dayId],
    //       isChecked: !prevState.days[dayId].isChecked,
    //     },
    //   },
    // }));
  };
  const handleBack = () => {
    previousPage();
  };
  useEffect(() => {
  }, [formData])
  
  const handleTimeChange = (e, dayId, field) => {
    const { value } = e.target;
    // setFormData((prevState) => ({
    //   ...prevState,
    //   days: {
    //     ...prevState.days,
    //     [dayId]: {
    //       ...prevState.days[dayId],
    //       [field]: value,
    //     },
    //   },
    // }));
    if (formData.find(f => (f.dayId === dayId))) {
      formData.find(f => f.dayId === dayId)[field] = value;
      setFormData([...formData])
    }
  };
    // const [categoriesInitalState] = useState({});
  const onAllDayChange = (e) => {
    const updatedFormData = JSON?.parse(JSON?.stringify(formData));

    updatedFormData.forEach((key) => {
      key.isChecked = e?.target?.checked;
      key.openTime = openTime;
      key.closeTime = closeTime;
    });

    setFormData(updatedFormData);
  };
  
  // const [selectedCategory, setSelectedCategory] = useState(
  //   categoriesInitalState
  // );

  // const { categories } = selectedCategory;

  const [selectedChips, setSelectedChips] = useState([]);

  const handleChipClick = (chipId) => {
    setSelectedChips((prevSelectedChips) =>
      prevSelectedChips.includes(chipId)
        ? prevSelectedChips.filter((id) => id !== chipId)
        : [...prevSelectedChips, chipId]
    );
  };

  const moreInfoData = {
    openTime: openTime,
    closeTime: closeTime,
    Restaurant_category: selectedChips,
    Timing: formData
  };

  const combineData = {
    ...restaurantInfoData,
    ...ownerInfoData,
    ...eData,
    ...contactInfo,
    ...moreInfoData,
    isPrimaryOutlet: true,
  };

  function convertObjectToFormData(data) {
    const _formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];
      _formData.append(key, value);
    });
    return _formData;
  }

  const handleSubmit = (combineData) => {
    console.log("data ",combineData)
    presentLoader();
    if (selectedChips.length > 0 && openTime && closeTime && (allDaysCheck || checkedDays) && termsConditions) {

      data ?
        updateRestaurant(combineData, data?.outletId).then((res) => {
          if (res.data.success) {
            documents.length === 0 &&
              successToast("Restaurant Updated Successfully");

            // Create an array of promises for the last three POST requests
            const postRequests = [];

            if (documents[2]) {
              postRequests.push(
                axios.post(
                  process.env.REACT_APP_BACKEND_BASE_URL + 'outlet/upsertLogoImage',
                  convertObjectToFormData({
                    file: documents[2],
                    outletId: data?.outletId,
                  }),
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
              );
            }

            if (documents[1]) {
              postRequests.push(
                axios.post(
                  process.env.REACT_APP_BACKEND_BASE_URL + 'outlet/upsertHeaderImage',
                  convertObjectToFormData({
                    file: documents[1],
                    outletId: data?.outletId,
                  }),
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
              );
            }

            if (documents[0]) {
              postRequests.push(
                axios.post(
                  process.env.REACT_APP_BACKEND_BASE_URL + 'outlet/upsertFssaiLicensePhoto',
                  convertObjectToFormData({
                    file: documents[0],
                    outletId: data?.outletId,
                  }),
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
              );
            }

            // Execute all requests in parallel using axios.all
            axios.all(postRequests)
              .then((responses) => {
                responses.forEach((res) => {
                  console.log(res);
                });
                dismissLoader();
                setActiveStep(5)
                setSuccessMessage('Restaurant Updated Successfully')
                successToast("Restaurant Updated Successfully");

              })
              .catch((error) => {
                console.log(error);
                dismissLoader();
              });
          }
        }).catch((error) => {
          dismissLoader();
          console.log(error);
          errorToast(error.response.data.error);
        })
        :
        createRestaurant(combineData).then((res) => {
          if (res.data.success) {
            documents.length === 0 &&
            successToast("Restaurant Added Successfully");
            // Create an array of promises for the last three POST requests
            const postRequests = [];

            if (documents[2]) {
              postRequests.push(
                axios.post(
                  process.env.REACT_APP_BACKEND_BASE_URL + 'outlet/upsertLogoImage',
                  convertObjectToFormData({
                    file: documents[2],
                    outletId: res.data.data?.outletId,
                  }),
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
              );
            }

            if (documents[1]) {
              postRequests.push(
                axios.post(
                  process.env.REACT_APP_BACKEND_BASE_URL + 'outlet/upsertHeaderImage',
                  convertObjectToFormData({
                    file: documents[1],
                    outletId: res.data.data?.outletId,
                  }),
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
              );
            }

            if (documents[0]) {
              postRequests.push(
                axios.post(
                  process.env.REACT_APP_BACKEND_BASE_URL + 'outlet/upsertFssaiLicensePhoto',
                  convertObjectToFormData({
                    file: documents[0],
                    outletId: res.data.data?.outletId,
                  }),
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
              );
            }

            // Execute all requests in parallel using axios.all
            axios.all(postRequests)
              .then((responses) => {
                responses.forEach((res) => {
                  console.log(res);
                });
                dismissLoader();
                setActiveStep(5)
                successToast("Restaurant Added Successfully");

              })
              .catch((error) => {
                console.log(error);
                dismissLoader();
              });
          }
        }).catch((error) => {
          dismissLoader();
          console.log(error);
          errorToast(error.response.data.error);
        });
    } else {
      dismissLoader();
      errorToast('Please fill mandatory fields')
    }
  };



  return (
    <>
      <div className={classes.formFieldWrapper} style={sx}>
        <div className={classes.formFieldLayout}>
          <div style={{ paddingBottom: "0.8rem" }}>
            <div style={{ paddingBottom: "0.8rem" }}>
              <Headline2
                // children={""}
                sx={{
                  fontSize: "1.125rem",
                  fontWeight: "0",
                  color: "#94A3B8",
                  paddingBottom: "8px",
                }}
              >
                Type of Categories Select<span style={{ color: 'red' }}> *</span>
              </Headline2>
              <Divider />
            </div>
            <div className={classes.restaurantType}>
              <div className={classes.categoryType}>
                {getCategories?.map((chip) => {
                  return (
                    <Chip
                      key={chip?.id}
                      label={chip.id === '46ca4dcf-ccbc-4827-8b1e-d76655da591e' ? 'Drink' : chip.id === "9b0aeeae-ac8a-4235-b600-e964435444a0" ? 'Meals' : chip.id === "9b0aeeae-ac8a-4235-b600-e964435444a0" ? 'Quick bites' : chip?.label}
                      onClick={() => handleChipClick(chip.id)}
                      color={
                        selectedChips?.includes(chip.id) ? "primary" : "default"
                      }
                      clickable
                    />)
                })}
              </div>
            </div>
          </div>
          <div style={{ padding: "10px 0 0.8rem 0" }}>
            <Headline2
              // children={"Times: "}
              sx={{
                fontSize: "1.125rem",
                fontWeight: "0",
                paddingBottom: "8px",
                color: "#94A3B8",
              }}
            >
              Times <span style={{ color: 'red' }}> *</span>
            </Headline2>
            <Divider />
            <div className={classes.fromToTimes}>
              <div className={classes.timeFieldMainS}>
                <div>
                  <p style={{ fontSize: "16px", padding: "0 10px 6px 0" }}>
                    From
                  </p>
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <TimePicker
                      className={style.timepicker}
                      views={['hours', 'minutes']}
                      format="hh:mm A"
                      value={openTimeForInputValue}
                      onChange={(e) => {
                        handleCategoryFromTimeChange(e)
                        // Object.keys(formData?.days).forEach(key => {
                        //   // if(e.$H && e.$m) {
                        //     formData.days[key].openTime = `${e.$H.toString().length == 1 ? `0${e.$H}` : e.$H}:${e.$m.toString().length == 1 ? `0${e.$m}` : e.$m}`;
                        //   // }
                        // })
                        // setFormData({...formData});
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <p style={{ fontSize: "16px", padding: "0 10px 6px 0" }}>
                    To
                  </p>
                  <LocalizationProvider dateAdapter={AdapterDayjs} >

                    <TimePicker
                      type="time"
                      className={style.timepicker}
                      views={['hours', 'minutes']}
                      format="hh:mm A"
                      value={closeTimeForInputValue}
                      onChange={(e) => {
                        handleCategoryToTimeChange(e)
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "10px", flexDirection: 'row' }}>
            <Headline2
              // children={"Day: "}
              sx={{
                fontSize: "1.125rem",
                fontWeight: "0",
                paddingBottom: "8px",
                color: "#94A3B8",
                display: 'inline-block'
              }}
            >
              Days <span style={{ color: 'red' }}> *</span>
            </Headline2>
            <Divider />
            <div style={{ padding: "0.7rem 0 0.7rem 0" }}>
              <FormGroup>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allDaysCheck}
                      onChange={(e) => {
                        setAllDaysCheck(e?.target?.checked)
                        onAllDayChange(e)
                      }} name="All Days" />
                  }
                  label="All Days"
                />

                {/* { !Object.keys(formData?.days)?.map(k => formData?.days[k])?.some(s => !s?.isChecked) &&

                  <div className={classes.timeFieldMainS}>
                    <div>
                      <p style={{ fontSize: "16px", padding: "0 10px 6px 0" }}>
                        From
                      </p>
                      <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <TimePicker
                          className={style.timepicker}
                          views={['hours', 'minutes']}
                          format="hh:mm A"
                          onChange={(e) =>{
                            handleCategoryFromTimeChange(e)
                            Object.keys(formData?.days).forEach(key => {
                              // if(e.$H && e.$m) {
                                formData.days[key].openTime = `${e.$H.toString().length == 1 ? `0${e.$H}` : e.$H}:${e.$m.toString().length == 1 ? `0${e.$m}` : e.$m}`;
                              // }
                            })
                            setFormData({...formData});
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div>
                      <p style={{ fontSize: "16px", padding: "0 10px 6px 0" }}>
                        To
                      </p>
                      <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <TimePicker className={style.timepicker} views={['hours', 'minutes']}
                          format="hh:mm A"
                          onChange={(e) =>{
                            handleCategoryToTimeChange(e)
                            Object.keys(formData?.days).forEach(key => {
                              // if(e.$H && e.$m) {
                                formData.days[key].closeTime = `${e.$H.toString().length == 1 ? `0${e.$H}` : e.$H}:${e.$m.toString().length == 1 ? `0${e.$m}` : e.$m}`;
                              // }
                            })
                            console.log("close", formData);
                            setFormData({...formData});
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                } */}




              </FormGroup>
            </div>

            <div className={classes.dayTimeListMain} style={{ columnGap: 'unset' }}>
              <div className={classes.dayTimeFieldBoxWrapper}>
                {formData.map((day,i) => (
                  <div key={day.dayId} className={classes.dayTimeFieldBox} >
                    <div className={classes.timeFieldMain}>
                      <input
                        required
                        className={classes.checkboxField}
                        type="checkbox"
                        style={everydayCheckboxStyle}
                        name={day.day}
                        checked={day.isChecked}
                        onChange={() => {
                          setCheckedDays(true)
                          handleCheckboxChange(day.dayId)
                        }}
                      />
                      <label>{day.day}</label>

                      {day.isChecked && (
                        <div className={classes.openCloseTimeWrapper}>
                          {/* <label> */}
                          <input
                            style={{ ...timeFieldStyle, flex: 1 }}
                            type="time"
                            value={formData[i]?.openTime}
                            onChange={(e) => {
                              handleTimeChange(e, day?.dayId, "openTime")
                            }}
                          />
                          {/* </label> */}
                          <label>to</label>
                          {/* to */}
                          {/* <label> */}
                          <input
                            style={{ ...timeFieldStyle, flex: 1 }}
                            type="time"
                            value={formData[i]?.closeTime}
                            onChange={(e) => {
                              handleTimeChange(e, day.dayId, "closeTime")
                            }}
                          />
                          {/* </label> */}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <FormControl sx={{ mt: 3, display: 'flex', flexDirection: 'row', alignItems: 'center' }} component="fieldset" variant="standard">
              <FormLabel component="legend" style={{ fontSize: '12px' }}>T&C and Privacy Policy</FormLabel>
              <Checkbox checked={termsConditions}
                onChange={(e) => setTermsConditions(e.target.checked)}
                name="gilad"
                size="small"
              />
               <>
                <FormHelperText>I agree to the <Link target="_blank" to={'https://zyxwtaeuvipslarwyrbe.supabase.co/storage/v1/object/public/documents/policy-files/MealPe_Restaurant_Onboarding%20Terms_AGC_Sept%2005,%202023.pdf'}>Terms & Conditions</Link>  and
                  <Link target="_blank" to={"https://zyxwtaeuvipslarwyrbe.supabase.co/storage/v1/object/public/documents/policy-files/MealPe_Terms%20of%20Use_AGC_Sept%2005,%202023.pdf"}> Privacy Policy</Link></FormHelperText>
              </>

            </FormControl>
          </Box>

          {/* <div className={classes.cancelSaveBtnOwner}></div> */}
          {/* <div
            className={classes.cancelSaveBtnOwner}
            // style={{
            //   marginTop: 0,
            // }}
          >
            <CustomButton
              onClick={() => handleBack()}
              // variant="outlined"
              // sx={{ fontSize: { xs: '0.8rem', sm: '1rem', margin: '0.6rem' } }}
              sx={{
                fontSize: { xs: "0.8rem", sm: "0.9rem" },
                backgroundColor: "white",
                borderRadius: "0.7rem",
                width: "02px",
                border: "1px solid #CBD5E1",
                color: "#334155",
                height: "40px",
                marginTop: "0.6rem",
              }}
              textcontent="Back"
            />
            <CustomButton
              type="submit"
              onClick={() => handleSubmit(combineData)}
              // disabled={isFormValid()}
              variant="contained"
              sx={{ fontSize: { xs: "0.8rem", sm: "1rem" }, height: "40px" }}
              textcontent="Submit"
            />
          </div> */}
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            mt={"50px"}
          >
            <Grid item>
              <CustomButton
                onClick={() => handleBack()}
                // variant="outlined"
                // sx={{ fontSize: { xs: '0.8rem', sm: '1rem', margin: '0.6rem' } }}
                sx={{
                  fontSize: { xs: "0.8rem", sm: "0.9rem" },
                  backgroundColor: "white",
                  borderRadius: "0.7rem",
                  width: "112px",
                  border: "1px solid #CBD5E1",
                  color: "#334155",
                  height: "40px",
                  marginTop: "0.6rem",
                  marginRight: "20px",
                }}
                textcontent="Back"
              />
            </Grid>
            <Grid item>
              <CustomButton
                type="submit"
                onClick={() => handleSubmit(combineData)}
                // disabled={isFormValid()}
                variant="contained"
                sx={{ fontSize: { xs: "0.8rem", sm: "1rem" }, height: "40px" }}
                textcontent="Submit"
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default MoreInformation;

const everydayCheckboxStyle = {
  display: "inline-block",
  verticalAlign: "middle",
  width: "18px",
  height: "21px",
};

const timeFieldStyle = {
  width: "68px",
  height: "34px",
  borderRadius: "7px",
  paddingLeft: "5px",
  margin: "0 2px 0 2px",
  border: "1px solid #CBD5E1",
};