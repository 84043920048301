import { Typography } from "@mui/material";
import React from "react";

const LargeText = ({ children, sx, ...otherProps }) => {
  return (
    <Typography fontSize={16} color={"#475569"} sx={sx} {...otherProps}>
      {children}
    </Typography>
  );
};

export default LargeText;
