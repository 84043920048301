import * as React from "react";
import Chip from "@mui/material/Chip";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

let chipSxObj = {
  active: {
    color: "#15803D",
    iconColor: "#22C55E",
    bgColor: "#F0FDF4",
  },
  pending: {
    color: "#B45309",
    iconColor: "#F59E0B",
    bgColor: "#FFFBEB",
  },
  gray : {
    color : '#334155',
    bgColor : '#F1F5F9'
  },
  blue : {
    color : '#1347CC',
    bgColor : '#EEF2FF'
  }
};

const getChipThemeSx = (themeName = "gray") => {
  const theme = chipSxObj[themeName];
  const { color = 'black', iconColor = 'red', bgColor = 'red' } = theme;
  return {
    mr : 1,
    minWidth: "4.5rem",
    backgroundColor: bgColor,
    color: color,
    fontWeight: "500",
    "& .MuiChip-icon": {
      color: iconColor,
      ml : '10px',
    },
  };
};

export default function CustomChip({ sx = {}, dotted = false,theme = 'gray', ...otherProps }) {
  return (
    <Chip
      sx={{
        ...getChipThemeSx(theme),
        height :'28px',
        py : '8px',
        ...sx,
      }}
      icon={
        dotted && <FiberManualRecordIcon sx={{ fontSize: "0.5rem"}} />
      }
      {...otherProps}
    />
  );
}
