import React from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const checkBoxStyle = {
  ".MuiFormControlLabel-label": {
    fontSize: "0.875rem !important",
    // textTransform: 'capitalize',
  },
 "& .MuiFormGroup-root ":{
  display:"flex",
  flexDirection: "row", gap: "20px"
 }
};
function CustomCheckBox({ checkBoxContainerStyle={},value, optionList, onChangeValue, helperText="" }) {

  
  return (
    <FormGroup
      sx={[{...checkBoxContainerStyle}]}
    >
      {
        optionList.map((option,index) => (
          <FormControlLabel 
            control={<Checkbox checked={value?.some(s=>s===option)} onChange={(e)=>{onChangeValue(option)}}/>} 
            label={option} 
            key={index}
            sx={[{...checkBoxStyle}]}
          />
        ))
      }
      <p style={{color: "red", fontSize: "0.875rem"}}>{helperText}</p>
    </FormGroup>
  );
}

export default CustomCheckBox;
