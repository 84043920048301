import React, { useEffect, useState } from "react";
import cssClasses from "./Dashboard.module.css";
import Headline1 from "../../../subComponents/pageUI/Headline1";
import Lead1 from "../../../subComponents/pageUI/Lead1";
import HiSvg from "../../../svgComponents/HiSvg";
import { Box, Grid, Card, Button, Typography, Divider, CardMedia, CardHeader, Avatar } from "@mui/material";
import Headline2 from "../../../subComponents/pageUI/Headline2";
import Lead2 from "../../../subComponents/pageUI/Lead2";
import NewBatchSvg from "../../../svgComponents/NewBatchSvg";
import LineChart from "../../../subComponents/chartUI/LineChart";
import LineChart2 from "../../../subComponents/chartUI/LineChart2";
import PieChart2 from "../../../subComponents/chartUI/PieChart2";
import PaperBox from "../../../subComponents/pageUI/PaperBox";
import { getDashboardCards, getTop5Customers, getTopMostOrders, getMonthlyChurn } from '../../../services/dashboard';
import customerImage from "../../../assets/Images/2.jpg";
import MostOrderedImg from '../../../assets/Images/50x50.svg'
import moment from "moment/moment";
import axios from "axios";
import { useQuery } from "react-query";
import Tooltip from '@mui/material/Tooltip';
import CustomMenu from "../../../subComponents/pageUI/CustomMenu";
import StackRow from "../../../subComponents/formUI/StackRow";
import Text from "../../../subComponents/formUI/Title";
import DownSvg from "../../../svgComponents/DropDownSvg";
import Skeleton from '@mui/material/Skeleton';
import { DashboardCards } from "./DashboardCards";

function Dashboard() {
  const [dashboardCard, setDashboardCard] = useState(null)
  const [topCustomers, setTopCustomers] = useState(null)
  const [topOrders, setTopOrders] = useState(null)
  const [active, setActive] = useState(false)
  const [payload, setPayload] = useState(null)
  const [revenue, setRevenue] = useState([]);
  const [orderValue, setOrderValue] = useState(null)
  const [gmv, setGmv] = useState(null)
  const [monthlyChurnChartData, setMonthlyChurnChartData] = useState([])
  const [chartFilter, setChartFilter] = useState('year')

  var today = new Date()
  const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const lastYearDate = today.getFullYear() - 1 + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const formattedDate = `${year}-${month}-${day}`;

  const cards = [
    {
      title: 'GMV', value: gmv,
      tooltip: 'Gross Merchandise Value (GMV) is a metric that measures your total value of sales over a certain period of time. Sales Price of Goods x Number of Goods Sold.'
    },
    { title: 'Average Order Value', value: orderValue, tooltip: 'the average amount spent each time a customer places an order through an app..' },
    { title: 'Total Order', value: dashboardCard?.total_orders, tooltip: 'Number of orders received over a certain period of time.' },
    { title: 'Total Refund', value: dashboardCard?.total_refund, tooltip: 'Total amount refunded to the customer.' },
  ];

  const buttonData = [
    { type: 'year', label: 'Yearly', width: '103px' },
    { type: 'month', label: 'Monthly', width: '103px' },
    { type: 'day', label: 'Daily', width: '65px' },
    { type: 'lastYear', label: 'Last Year', width: '110px' },
  ];

  const userSections = [
    { title: 'Daily', value: dashboardCard?.year_active_users },
    { title: 'Weekly', value: dashboardCard?.week_active_users },
    { title: 'Monthly', value: dashboardCard?.month_active_users },
  ];

  const data = [
    { key: 'total_downloads', label: 'Total Downloads' },
    { key: 'total_outlets', label: 'Total Outlets' },
    { key: 'ongoing_order', label: 'Ongoing Orders' },
  ];


  useEffect(() => {

    dashboardCards('day')
    getTop5Customers().then((res) => {
      if (res.data.success) {
        setTopCustomers(res.data.data)
      }
    }).catch((err) => console.log(err))

    getTopMostOrders().then((res) => {
      if (res.data.success) {
        setTopOrders(res.data.data)
      }
    }).catch((err) => console.log(err))
    getMonthlyChurn().then((res) => {
      if (res.data.success) {
        setMonthlyChurnChartData(updateDateDataWithMonthlyChurn(new Date(), res.data.data))
      }
    }).catch((err) => console.log(err))
    // eslint-disable-next-line
  }, [])
  function updateDateDataWithMonthlyChurn(currentDate, revenueData) {
    const dateData = [];
    const twelveMonthsAgo = new Date(currentDate);
    twelveMonthsAgo.setMonth(currentDate.getMonth() - 12);

    for (let day = 1; day <= 12; day++) {
      const date = new Date(twelveMonthsAgo);
      date.setMonth(twelveMonthsAgo.getMonth() + day);
      const matchingRevenue = revenueData.find(
        (revenue) => moment(revenue.month)?._i?.trim() === moment(date).format("MMMM")
      );
      let value = 0;
      if (matchingRevenue) {
        value = matchingRevenue.monthlyChurn;
      }

      dateData.push({
        x: moment(date).format("MMM"),
        y: value?.toFixed(2),
      });
    }
    return dateData;
  }
  // eslint-disable-next-line
  const { isLoading: isLoading1 } = useQuery(
    ["revenue-chart", chartFilter],
    () =>
      axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL + "order/order/adminSalesThroughApp",
        {
          target_date: formattedDate,
          analyticalType: chartFilter,
        }
      ),
    {
      onSuccess: (res) => {

        function updateDateDataWithRevenueWeekly(currentDate, revenueData) {
          const dateData = [];
          const sevenDaysAgo = new Date(currentDate);
          sevenDaysAgo.setDate(currentDate.getDate() - 7);
          for (let i = 1; i <= 7; i++) {
            const date = new Date(sevenDaysAgo);
            date.setDate(sevenDaysAgo.getDate() + i);

            const matchingRevenue = revenueData.find(
              (revenue) => revenue.label === moment(date).format("YYYY-MM-DD")
            );
            let value = 0;
            if (matchingRevenue) {
              value = matchingRevenue.total_price;
            }
            dateData.push({
              x: moment(date).format("ddd"),
              y: value.toFixed(2),
            });
          }

          return dateData;
        }
        function updateDateDataWithRevenueMonthly(currentDate, revenueData) {
          const dateData = [];
          const twelveMonthsAgo = new Date(currentDate);
          twelveMonthsAgo.setMonth(currentDate.getMonth() - 12);

          for (let day = 1; day <= 12; day++) {
            const date = new Date(twelveMonthsAgo);
            date.setMonth(twelveMonthsAgo.getMonth() + day);
            const matchingRevenue = revenueData.find(
              (revenue) => moment(revenue.label).format('MMM') === moment(date).format("MMM")
            );
            let value = 0;
            if (matchingRevenue) {
              value = matchingRevenue.total_price;
            }

            dateData.push({
              x: moment(date).format("MMM"),
              y: value.toFixed(2),
            });
          }
          return dateData;
        }
        function updateDateDataWithRevenueUpcomingYears(
          currentDate,
          revenueData,
          numberOfYears
        ) {
          const dateData = [];
          const currentYear = currentDate.getFullYear();

          for (
            let year = currentYear;
            year <= currentYear + numberOfYears;
            year++
          ) {
            const date = new Date(year, 0, 1); // January 1st of each year

            const matchingRevenue = revenueData.find(
              (revenue) => revenue.label === moment(date).format("YYYY-MM-DD")
            );

            let value = 0;
            if (matchingRevenue) {
              value = matchingRevenue.total_price;
            }

            dateData.push({
              x: year.toString(),
              y: value.toFixed(2),
            });
          }
          return dateData;
        }

        setRevenue(
          chartFilter === "month"
            ? updateDateDataWithRevenueMonthly(new Date(), res.data.data)
            : chartFilter === "week"
              ? updateDateDataWithRevenueWeekly(new Date(), res.data.data)
              : updateDateDataWithRevenueUpcomingYears(
                new Date(),
                res.data.data,
                5
              )
        );
      },
    }
  );
  const dashboardCards = (data) => {
    localStorage.setItem('active', data)
    setDashboardCard('')
    setGmv('')
    setOrderValue('')
    let payload 
    if(data !== 'lastYear')
    {
      payload = {
        target_date: date ,
        analyticalType: data
      }
    }
    else {
      payload = {
        target_date: lastYearDate ,
        analyticalType: 'year'
      }
    }
    setActive(data)
    setPayload(payload)
    getDashboardCards(payload).then((res) => {
      if (res.data.success) {
        setDashboardCard(res?.data?.data)
        abbrNum('avrage_order_value', res.data.data?.avrage_order_value)
        abbrNum('gmv', res.data.data?.gmv)
      }
    }).catch((err) => console.log(err))
  }

  function abbrNum(key, number) {
    const abbrev = ["k", "m", "b", "t"];

    // for (let i = abbrev.length - 1; i >= 0; i--) {
    //   const size = Math.pow(10, (i + 1) * 3);

    //   if (size <= number) {
    //     number = Math.round(number / size);

    //     if (number === 1000 && i < abbrev.length - 1) {
    //       number = 1;
    //       i++;
    //     }
    //     number += abbrev[i];
    //     break;
    //   }
    // }

    // if (number < 1000) {
    //   number = Math.floor(number);
    // }
    
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
    })

    number = formatter.format(number);
    number = number.toLocaleString("en-IN");
    
    if (key === 'avrage_order_value') {
      setOrderValue(number) 
    }
    if (key === 'gmv') {
      setGmv(number)
    }
    return number;
  }

  return (
    <div className={cssClasses.pageWrapper}>
      <Box mb="2rem">
        <Headline1 sx={{ mb: 0 }}>
          Hello&nbsp; <HiSvg />
        </Headline1>
        <Lead1> Welcome to Dashboard</Lead1>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} className={cssClasses.topBox}>
          <PaperBox>
            <Grid item className={cssClasses.buttonContainer} xs={12}>
              {buttonData.map((button, index) => (
                <Button
                  key={index}
                  className={active === button.type ? cssClasses.customButton : cssClasses.button}
                  variant={"contained"}
                  sx={{ width: button.width, height: '40px', borderRadius: '8px', fontSize: '14px' }}
                  onClick={() => dashboardCards(button.type)}
                >
                  {button.label}
                </Button>
              ))}
            </Grid>

            <Grid container spacing={3} gap={3} mt={1} padding={'0 0px 0 24px'}>
              {cards?.map((card, index) => (
                <DashboardCards
                  key={index}
                  title={card.title}
                  value={card.value}
                  tooltip={card.tooltip}
                  cssClasses={cssClasses}
                />
              ))}
            </Grid>
          </PaperBox>
        </Grid>

        <Grid container pt={'25px'} pr={'29px'} justifyContent={'space-between'} flexWrap={'wrap'}>
          <Grid item xs={7.6} pl={'32px'} >
            <PaperBox padding={"16px"}>
              <Box height={"18.5rem"}>
                <Box sx={{ flexGrow: 0 }} display={'flex'} justifyContent={'space-between'}>
                  {isLoading1 === false ?
                    <Headline2>
                      Sales Through App <NewBatchSvg />
                    </Headline2>
                    : <Skeleton width={210} height={50} animation="wave" />}
                  <CustomMenu
                    ButtonComp={(props) => (
                      <StackRow
                        between={true}
                        component={Button}
                        alignItems={"center"}
                        sx={{
                          border: "1px #CBD5E1 solid",
                          padding: "5px 12px",
                          borderRadius: "6px",
                          width: "auto",
                          // ...sx,
                        }}
                        {...props}
                      >
                        <Text
                          color="#334155"
                          fontWeight={500}
                          sx={{
                            textTransform: "capitalize",
                            whiteSpace: "nowrap",
                            width: "90%",
                            overflow: "hidden",
                            ...{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              fontSize: "14px"
                            },
                          }}
                        >
                          Filter : {chartFilter}
                        </Text>
                        <DownSvg />
                      </StackRow>
                    )}
                    menuList={[
                      { id: 1, label: "week" },
                      { id: 2, label: "month" },
                      { id: 3, label: "year" },
                    ]}
                    menuOnClick={({ label, id }) => {
                      setChartFilter(label)
                    }}
                  ></CustomMenu>
                </Box>
                {isLoading1 === false ? <Lead2 sx={{ lineHeight: '0.5' }}>View and update your store details</Lead2>
                  : <Skeleton width={270} height={50} animation="wave" />}
                {isLoading1 === false ?
                  <Box height={"16.5rem"}>
                    <LineChart data={revenue} />
                  </Box>
                  : <Skeleton width={'100%'} height={'80%'} sx={{ mb: '5px' }} animation="wave" />}
              </Box>
            </PaperBox>
          </Grid>
          <Grid item xs={1.7}>
            <PaperBox padding={"16px"}>
              <Box height={"18.5rem"} >
                <Box display={'flex'} gap={'5px'} padding={'7px 0 11px 5px'}>
                  <Typography className={cssClasses.cardHeading} gutterBottom>
                    Active  Users
                  </Typography>
                  <Tooltip title="It is the number of active interactions from mobile app users." arrow>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ paddingTop: '3px' }} width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <circle cx="9" cy="9" r="6.75" stroke="#64748B" strokeWidth="1.35" />
                      <path d="M9.375 5.625C9.375 5.83211 9.20711 6 9 6C8.79289 6 8.625 5.83211 8.625 5.625C8.625 5.41789 8.79289 5.25 9 5.25C9.20711 5.25 9.375 5.41789 9.375 5.625Z" fill="#64748B" stroke="#64748B" strokeWidth="0.75" />
                      <path d="M9 12.75V7.5" stroke="#64748B" strokeWidth="1.35" />
                    </svg>
                  </Tooltip>
                </Box>
                <Box height={'246px'}>
                  {userSections.map((section, index) => (
                    <Box key={index} className={cssClasses.sectin2Box}>
                      <Typography className={cssClasses.userHeading}>{section.title}</Typography>
                      <Typography className={cssClasses.userHeadingValue}>
                        {section.value >= 0 ? section.value : <Skeleton width={120} height={20} animation="wave" />}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </PaperBox>
          </Grid>
          <Grid item xs={2.2} >
            <PaperBox padding={"20px 0px 10px 33px"} width={'221px'}>
              <Box height={"18.5rem"} >
                {data.map((item, index) => (
                  <Box key={index} className={cssClasses.sectin2Box} pt={index === 0 ? '15px' : 0}>
                    <Typography className={cssClasses.userHeadingValue}>
                      {dashboardCard?.[item.key] >= 0 ? dashboardCard?.[item.key] : <Skeleton width={150} height={20} animation="wave" />}
                    </Typography>
                    <Typography className={cssClasses.typography2}>{item.label}</Typography>
                  </Box>
                ))}
              </Box>
            </PaperBox>
          </Grid>
        </Grid>
        <Grid container p={'16px'} pr={'29px'} justifyContent={'space-between'}>
          <Grid xs={12} item pl={'20px'} >
            <PaperBox padding={'15px'}>
              <Box height={"16.5rem"}>
                <Headline2>
                  Monthly churn <NewBatchSvg />
                </Headline2>
                <Lead2>View and update your store details</Lead2>
                <Box height={"14.5rem"}>
                  <LineChart2 data={monthlyChurnChartData} />
                </Box>
              </Box>
            </PaperBox>
          </Grid>

        </Grid>
        <Grid container p={'5px 0px 10px 36px'} justifyContent={'flex-start'} gap={'25px'}>
          <Grid xs={3.2} item>
            <PaperBox padding={'12px'}>
              <Box height={'auto'}>
                <Box height={'3rem'} display={'flex'} justifyContent={'center'}>
                  <Headline2 style={{ color: '#393C49' }}>Top 3 customer</Headline2>
                </Box>

                <Divider />
                {topCustomers?.slice(0, 3)?.map((e) => {
                  return (<React.Fragment key={e?.customerauthuid}>
                    <Card sx={{ padding: 0.5, boxShadow: 'none' }} >
                      <CardHeader
                        sx={customCardMediaStyle}
                        avatar={
                          <Avatar
                            variant="square"
                            aria-label="recipe"
                          >
                            <CardMedia
                              component="img"
                              height="14"
                              image={e?.photo ? e.photo : customerImage}
                              alt="Restaurants"
                              sx={customCardMediaStyle}
                            />
                          </Avatar>
                        }
                        title={<p style={{ fontSize: "14px", color: "#000000", fontWeight: 500 }}>
                          {e.customername}</p>}
                        subheader={<p style={{ fontSize: "12px", color: "#64748B", fontWeight: 400 }}>{e.total_count} dishes ordered</p>}
                      />
                    </Card>
                  </React.Fragment>)
                })}
              </Box>
            </PaperBox>
          </Grid>
          <Grid xs={3.2} item>
            <PaperBox padding={'12px'}>
              <Box height={"auto"} >
                <Box height={'3rem'} display={'flex'} justifyContent={'center'}>
                  <Headline2 style={{ color: '#393C49' }}>Most Ordered</Headline2>
                </Box>
                <Divider />
                {topOrders?.slice(0, 3)?.map((e) => {
                  return (
                    <Card sx={{ padding: 0.5, boxShadow: 'none' }} key={e?.itemid}>
                      <CardHeader
                        sx={customCardMediaStyle}
                        avatar={
                          <Avatar
                            variant="square"
                            aria-label="recipe"
                          >
                            <CardMedia
                              component="img"
                              height="14"
                              image={e?.item_image_url ? e?.item_image_url : MostOrderedImg}
                              alt="Restaurants"
                              sx={customCardMediaStyle}
                            />
                          </Avatar>
                        }
                        title={<p style={{ fontSize: "14px", color: "#000000", fontWeight: 500 }}>
                          {e.itemname}</p>}
                        subheader={<p style={{ fontSize: "12px", color: "#64748B", fontWeight: 400 }}>{e.total_count} dishes ordered</p>}
                      />
                    </Card>
                  )
                })}
              </Box>
            </PaperBox>
          </Grid>
          <Grid xs={3.2} item>
            <Box height={"16.5rem"} padding={'5px'}>
              <Headline2 className={cssClasses.pieChartHeading}>Order Type </Headline2>
              <PieChart2 payload={payload} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;

const customCardMediaStyle = {
  "& .MuiAvatar-root ": {
    borderRadius: "1rem",
    width: "44px",
    height: "44px"
  },
  margin: "0",
  "& .MuiCardMedia-root ": {
    height: "100%"
  }
}