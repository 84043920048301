import { Typography } from "@mui/material";
import React from "react";

const Headline2 = ({ children,sx = {}, ...otherProps }) => {
  return (
    <Typography
      {...otherProps}
      sx={{
        fontSize: "1.125rem",
        fontWeight: "600",
        color : 'black',
        ...sx
      }}
    >
      {children}
    </Typography>
  );
};

export default Headline2;
