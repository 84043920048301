import { Stack } from "@mui/material";
import React, { useRef, useState } from "react";
import Image from "../../assets/Images/Image.png";
import Title from "../formUI/Title";
import Lead from "../pageUI/Lead1";
import { useEffect } from "react";
const UpliadImageFieldWithoutFormik = ({
  defaultValue,
  onChange: _onChange,
  defaultImg
}) => {
  let fileRef = useRef(null);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(defaultValue || "");
  
  useEffect(()=>{
    setImg(defaultValue)
  },[defaultValue])

  const onChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    _onChange(file);
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      const imageUrl = `data:${file.type};base64,${base64String}`;
      setImg(imageUrl);
    };

    reader.readAsDataURL(file);
  };
  return (
    <Stack
      alignItems={"center"}
      spacing={"10px"}
      sx={{
        maxWidth: "200px",
        position: "relative",
      }}
    >
      <input
        accept="image/*"
        type="file"
        id="img"
        hidden
        ref={(el) => (fileRef = el)}
        onChange={onChange}
      />
      <label htmlFor="img">
        <img
          src={img ||defaultImg || Image}
          alt="Upload"
          style={{
            height: "100px",
            width: "100px",
            cursor: "pointer",
            objectFit: "contain",
          }}
        />
      </label>
      <Title>{file ? file.name : "Upload Image"}</Title>
      <Lead sx={{
        fontSize : 12,
        position : 'relative',
        top : '-10px'

      }}>JPG or PNG. Max size of 2MB</Lead>
    </Stack>
  );
};

export default UpliadImageFieldWithoutFormik;
