import React, { useEffect, useState, useMemo } from "react";
import classes from "./Users.module.css";
import AddIcon from '@mui/icons-material/Add';
import CustomButton from "../../../subComponents/formUI/CustomButton";
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import ChipIcon from "../../../svgComponents/ChipIcon";
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import { Divider, Stack, Typography } from "@mui/material";
import { createAdmin, getAdminList, updateAdmin } from "../../../services/user";
import { dismissLoader, presentLoader } from "../../../services/loaderService";
import { errorToast, successToast } from "../../../services/toast";
import { deepPurple } from '@mui/material/colors';
import UserModal from "./UserModal";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useQuery } from "react-query";
import axios from "axios";
import cssClasses from '../Restaurants/Restaurants.module.css'
import StatusFilter from "../Filters/StatusFilter";
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import debouce from "lodash.debounce";
import RestaurantUsers from "./RestaurantUsers";

const Users = () => {

  const [chipColor] = useState({
    "dashboard": {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA"
    },
    "user": {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC"
    },
    "payment": {
      backgroundColor: "#F1F5F9",
      textColor: "#334155"
    },
    "configuration": {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA"
    },
    "restaurant": {
      backgroundColor: "#F1F5F9",
      textColor: "#334155"
    },
    "finance": {
      backgroundColor: "#F1F5F9",
      textColor: "#334155"
    },
  })

  const [open, setOpen] = React.useState(false);
  const [isSort, setIsSort] = useState('');
  const [searchText, setSearchText] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {

    presentLoader()
    createAdmin(data.email, data.cPassword, data.name, data.mobile, data.role, data.tabAccess).then(res => {

      if (res.data.success) {
        dismissLoader()
        handleClose()
        successToast("User Created  Successfully")
        setRow(res.data.data)
        refetch();
      }
      else {
        dismissLoader()
        handleClose()
        errorToast(res.data?.message)

      }
    }
    ).catch(error => { dismissLoader(); errorToast(error.response.data.message) })
  }


  const [totalData, setTotalData] = useState(0)
  const [row, setRow] = useState([])
  const [loading, setLoading] = useState(true)
  const [tasksPerPage, settasksPerPage] = useState(10);
  const [value, setValue] = React.useState(0);
  const [rowCountState, setRowCountState] = React.useState(
    totalData || 0,
  );

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalData !== undefined
        ? totalData
        : prevRowCountState,
    );
  }, [totalData, setRowCountState]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
 
  const handleSearch = (e) => {
    let text = e.target.value;
    setSearchText(e.target.value)
    setLoading(true)
    setTimeout(() => {
      getAdminList(paginationModel.page + 1, 11, text)?.then((res) => {
        if (res.data.success) {
          setLoading(false)
          setTotalData(res.data.meta.totalCount)
          setRow(res.data.data)
        }
      }).catch((err) => console.log(err))

    }, 500)
  }

  const debouncedResults = useMemo(() => {
    return debouce(handleSearch, 800);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const URL = process.env.REACT_APP_BACKEND_BASE_URL + `admin/getAdminList?page=${paginationModel.page + 1}&perPage=${11}&sortBy=${isSort}`;
  const { refetch, isLoading } = useQuery(
    [
      paginationModel.page,
      isSort
    ],
    () => {
        return axios.get(URL);
    },
    {
      onSuccess: (res) => {
        setTotalData(res?.data?.meta?.totalCount);
        setRow(res?.data?.data);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [paginationModel, refetch]);

  useEffect(() => {
    if (value === 1) {
      window.history.pushState(null, '', window.location.href)
      // close modal on 'back'
      window.onpopstate = () => {
        window.onpopstate = () => { }
        setValue(0)
      }
    }
  }, [value]);

  const handleEdit = (data) => {
    presentLoader()
    updateAdmin(data?.adminId, data).then((res) => {
      if (res.data.success) {

        dismissLoader()
        handleClose()
        successToast("User Updated  Successfully")
        setRow(res?.data?.data)
        setLoading(true);
        getAdminList(paginationModel.page + 1, tasksPerPage + 1, searchText).then((res) => {
          if (res.data.success) {
            setTotalData(res.data.meta.totalCount)
            setRow(res?.data?.data)
            setLoading(false)
          }
        }).catch((err) => console.log(err))
      }
      else {
        dismissLoader()
        handleClose()
      }
    }
    ).catch((err) => console.log("errror"))

  }

  const columns = [
    {
      field: "name",
      headerName: "Label",
      width: 265,
      renderCell: (p) => {
        return (
          <CardHeader
            avatar={

              p.img ? <Avatar aria-label="recipe">
                <CardMedia
                  component="img"
                  height="14"
                  image={p.img}
                  alt="Restaurants"

                />
              </Avatar> :
                <Avatar aria-label="recipe" sx={{ bgcolor: deepPurple[500] }}>


                  {p?.value?.charAt(0)?.toUpperCase()}
                </Avatar>
            }

            title={<p style={{ fontSize: "14px", color: "black" }}>{p?.value}</p>}
            subheader={<p style={{ fontSize: "14px", color: "#475569" }}>{p?.row?.email}</p>}
            sx={{ padding: "0rem" }}
          />

        )
      }
    },
    {
      field: "mobile",
      headerName: "Mobile Number",
      width: 200,
      renderCell: (p) => (
        <p>{p.value}</p>

      )
    },
    {
      field: "role",
      headerName: "Role",
      width: 120,
      renderCell: (p) => (
        <p>{p.value}</p>

      )
    },
    {
      field: "tabAccess",
      headerName: "Access",
      width: 340,
      renderCell: (p) => (

        <>
          {p.value?.slice(0, 3).map(e => {
            return <>
              <ChipIcon
                sx={{ margin: "0.5rem", width: "5rem", height: "22px !important", fontSize: "0.8rem" }}
                text={e}
                textColor={chipColor[e.toLowerCase()]?.textColor}
                backgroundColor={chipColor[e.toLowerCase()]?.backgroundColor}
              />
            </>
          }
          )
          }
          {p.value?.length > 3 &&
            <Tooltip
              placement="right-start"
              title={p.value.slice(3).map((e) => <p>{e}</p>)}
            >
              <Chip sx={{
                width: "2.7rem",
                height: "22px !important"
              }}
                color="primary"
                variant="outlined"
                label={p.value?.length - 3 + "+"}
              />

            </Tooltip>}

        </>
      )
    },
    {
      field: "status",
      headerName: "Status",
      width: 140,
      renderCell: (p) => (
        !p.value ?
          <ChipIcon
            sx={{ margin: "0.5rem", width: "5rem", height: "22px !important", fontSize: "0.8rem" }}
            text={"active"}
            backgroundColor="#F0FDF4"
            textColor="#15803D"
          /> :
          <ChipIcon
            sx={{ margin: "0.5rem", width: "5rem", height: "22px !important", fontSize: "0.8rem" }} text={"pending"}
            backgroundColor="#FFFBEB"
            textColor="#B45309"
          />
      )
    },
    {
      field: "action",
      headerName: "Action",
      width: 80,
      renderCell: (p) => {
        return (
          <UserModal
            defaultData={p.row}
            onSubmit={data => { handleEdit(data) }}
          >
            <Stack direction={'row'} spacing={1} style={{
              cursor: 'pointer'
            }} >
              <EditIcon />
            </Stack>
          </UserModal>
        )
      }
    },
  ];

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return <>
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Super Admin Users" {...a11yProps(0)} />
          <Tab label="Restaurant Users" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <div className={classes.pageLayout}>
          <div className={classes.pageWrapper}>
            <div className={classes.restaurantsHeaderMain}>
              <div className={classes.restaurantsHeaderLeft}>
                <div className={classes.helloWord}>Super Admin Users</div>
                <div className={classes.welcomeWord}>Welcome to Dashboard</div>
              </div>

              <div className={classes.restaurantsHeaderRight}>
                <div>
                  <UserModal
                    open={open}
                    onDiscard={() => { setOpen(!open) }}
                    onSubmit={data => {
                      handleSubmit(data)
                    }} >
                    <CustomButton
                      sx={{ fontSize: { xs: '0.8rem', sm: '1rem' }, width: "133px", height: "44px" }}
                      variant="contained"
                      onClick={() => handleOpen()}
                      textcontent="Add User"
                      startIcon={<AddIcon />}
                    />
                  </UserModal>
                </div>
              </div>
            </div>
            <Divider />
            <div className={classes.restaurantsHeaderMid} >
              <div className={cssClasses.SearchIcon}>
                <TextField
                  placeholder="Search"
                  id="search"
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                  defaultValue={searchText}
                  onChange={debouncedResults}
                  autoComplete='off'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: searchText && (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setSearchText(" ")
                          refetch();
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )
                  }}
                />
              </div>
              <div className={classes.sortButton}>
                <StatusFilter
                  label="Sort by"
                  setIsSort={setIsSort}
                />
              </div>
            </div>

            <div style={{ height: ' 60vh', minHeight: "400px", width: '100%', marginTop: "3px" }}>
              <DataGrid
                getRowId={row => row?.adminId}
                loading={isLoading}
                pagination
                sx={[dataGridStyles]}
                rows={row}
                columns={columns}
                rowCount={rowCountState}
                disableColumnFilter={true}
                disableColumnMenu={true}
                disableColumnSelector={true}
                disableDensitySelector={true}
                disableSelectionOnClick={true}
                rowHeight={72}
                pageSize={10}
                paginationModel={paginationModel}
                page={paginationModel.page}
                onPageChange={(page) => {
                  return page;
                }}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                initialState={{
                  pageSize: 10
                }}
              />
            </div>
          </div>
        </div>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <RestaurantUsers searchText={searchText} />
      </CustomTabPanel>
    </Box>
  </>
}

export default Users;
const dataGridStyles = {
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F8FAFC"
  },
  // "&.MuiDataGrid-columnHeadersInner":
  // {
  //     backgroundColor:"blue"

  // }
  "&.MuiDataGrid-iconButtonContainer": {
    visiblity: "hidden"
  },
  '& .MuiDataGrid-menuIcon, .MuiDataGrid-iconButtonContainer': {
    display: 'none'
  },
  '& .MuiDataGrid-cell:focus': {
    // border : 'red 1px solid',
    outline: 'none'

  },
  '& .MuiDataGrid-row.Mui-selected,.MuiDataGrid-row.Mui-selected:hover,.MuiDataGrid-row:hover': {
    backgroundColor: 'transparent'
  },
  "& .MuiDataGrid-main": {
    // paddingBottom: "10rem"
  },
  "& .MuiSvgIcon-root": {
    // display: "none"
  },

  "& .MuiDataGrid-footerContainer": {
    "& .MuiTablePagination-displayedRows ": {
      display: "none !important",
    },
    "& .MuiDataGrid-selectedRowCount": {
      visibility: "hidden ",
    },
    " .MuiTablePagination-selectLabel": {
      display: "none !important",
    },
    " .MuiInputBase-root": {
      display: "none !important",
    }
  },
  "&  [title='Sort']": {
    //visibility:"hidden",
    display: "none",
    cursor: "none",
  },
  "& [title='Go to previous page']": {
    border: "1px solid #EAECF0",
    borderRadius: "0.8rem",
    marginRight: "0.5rem",
  },
  "& [title='Go to next page']": {
    border: "1px solid #EAECF0",
    borderRadius: "0.8rem",
    marginRight: "0.5rem",
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "1rem",
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "1rem",
  },
  "& .MuiDataGrid-columnHeader": {
    //   padding: "0 4rem",

    "&:focus": {
      outline: "none",
    },
  },
  "&  .MuiDataGrid-row ": {
    //   maxHeight: "72px !important",
    //   minHeight: "72px !important",
  },
  "& .MuiDataGrid-cell": {
    //   padding: "0 4rem",
    //   minHeight: "72px !important",
    //   maxHeight: "72px !important",
    "&:focus": {
      outline: "none",
    },
  },
  "& .MuiButtonBase-root": {
    fontSize: "1rem",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
};

