import {
  AppBar,
  Avatar,
  Box,
  Chip,
  Container,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import BrandPng from "../../assets/Images/Logo with Black MealPe (1).png";
import AvatarPng from "../../assets/Images/Avatar.png";
import HeaderNavbar from "./HeaderNavbar";
import CustomMenu from "../../subComponents/pageUI/CustomMenu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountSvg from "../../svgComponents/AccountSvg";
import LogoutSvg from "../../svgComponents/LogoutSvg";
import Lead2 from "../../subComponents/pageUI/Lead2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux Store/Slices/auth";
import { successToast } from "../../services/toast";
import { headerLink } from "../../commonValues/routingLinks";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const userdata = useSelector(state => state.auth.user)
  const logoLink = headerLink.filter(f => userdata?.tabAccess?.includes(f.name))?.[0]?.link

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: "0px 1px 0.5rem #E2E8F0",
        background: "white",
        color: "#0F172A",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box display={"flex"} flexDirection={"row"}>
            <img
              src={BrandPng}
              style={{
                height: "1.875rem",
                cursor: "pointer"
              }}
              alt=""
              onClick={()=>{navigate(logoLink)}}
            />
          </Box>
          <Box sx={{ py: 1 }}>
            <HeaderNavbar />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <CustomMenu
              ButtonComp={(props) => (
                <Chip
                  deleteIcon={<ExpandMoreIcon />}
                  onDelete={(e) => {
                    props.onClick(e);
                  }}
                  avatar={
                    <Avatar sx={{}} src={AvatarPng}>
                      M
                    </Avatar>
                  }
                  {...props}
                  sx={{
                    "& .MuiAvatar-root": {
                      height: "2rem",
                      width: "2rem",
                    },
                    background: "#F8FAFC",
                    height: "2.5rem",
                  }}
                />
              )}
              menuList={[
                { id: 1, label: "My Account", icon: <AccountSvg /> },
                { id: 5, label: "Logout", icon: <LogoutSvg /> },
              ]}
              menuOnClick={(e) => {
                if (e.label === "My Account") {
                  navigate("/home/my-account");
                } else if (e.label === "Logout") {
                  navigate('/');
                  dispatch(logout(userdata));
                  successToast("Logout successfully");
                }
              }}
            >
              <Stack direction={"row"} spacing={2} mb={"1.25rem"}>
                <Avatar sx={{}} src={AvatarPng}>
                  M
                </Avatar>
                 <Stack>
                   <Typography
                     sx={{
                       color: "#334155",
                       fontWeight: "500",
                     }}
                   >
                     {userdata?.name }{userdata[0]?.name}
                   </Typography>
                   <Lead2>    {userdata?.role} {userdata[0]?.role}</Lead2>
                 </Stack>
              </Stack>
            </CustomMenu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
